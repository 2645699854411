:root {
  /* Set the scrollbar width and color */
  --scrollbar-width: 8px;
  --scrollbar-color: #ccc;
  --primary-color: #0046d5;
  --primary: #0046d5;
  --custom-font-family: "Nunito", "system-ui", sans-serif;
  --focus-ring: 0 0 0 1px #0046d5;
}

*:focus {
  outline: none !important;
  outline-offset: 0 !important;
  box-shadow: none !important;
}

a:focus {
  box-shadow: none !important;
}

html,
body,
#root {
  height: 100%;
  font-family: var(--custom-font-family) !important;
}

/* Style the scrollbar */
::-webkit-scrollbar {
  width: var(--scrollbar-width);
  height: var(--scrollbar-width);
}

/* Style the scrollbar track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color);
  border-radius: var(--scrollbar-width);
}

.remove-x-scroll::-webkit-scrollbar {
  display: none !important;
  -ms-overflow-style: none !important; /* IE and Edge, */
  scrollbar-width: none !important; /* Firefox, */
}
.remove-x-scroll {
  -ms-overflow-style: none !important; /* IE and Edge, */
  scrollbar-width: none !important; /* Firefox, */
}

.p-dialog,
.p-button,
.p-component,
.p-inputtext {
  font-family: var(--custom-font-family) !important;
}

.p-inputtext,
.p-button {
  @apply py-2.5;
}

.p-dialog-header-close {
  @apply !bg-gray-100;
}

.p-dialog-footer button {
  @apply px-4 py-1.5;
}
.p-datepicker-trigger {
  @apply !p-0;
}

.p-chips-multiple-container {
  width: 100% !important;
  padding: 0.325rem 0.5rem !important;
}

.p-selectbutton > .p-highlight {
  border-style: inherit !important;
}

.p-selectbutton > .p-highlight > .p-button-label {
  @apply font-semibold;
}

/* table filter */
.p-column-filter-add-rule > button {
  @apply !bg-transparent !border-none !text-primary;
}

.p-column-filter-buttonbar > button:first-child {
  @apply !bg-transparent !text-primary;
}

.p-column-filter-remove-button {
  @apply !bg-transparent !border-transparent;
}
.p-highlight {
  @apply !border-none;
}
.p-button-icon-only:hover {
  @apply !scale-110;
}

/* button */
.p-button.p-button-text:enabled:hover,
.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover {
  @apply !bg-transparent;
}

/* table */
.p-datatable-header {
  @apply rounded-xl !border-none;
}

.p-column-header-content {
  @apply text-black;
}
.p-column-header-content > .p-column-title {
  @apply font-medium;
}

.p-datatable-thead {
  @apply rounded-xl overflow-hidden border-none;
}

.p-datatable-tbody > tr:hover,
.p-datatable-tbody > tr.p-highlight {
  @apply rounded-lg;
}

.p-datatable-row-expansion > td:hover {
  @apply !bg-white;
}

/* paginator */
.p-paginator {
  @apply !border-none;
}
.p-paginator-bottom {
  @apply rounded-b-xl !border-none;
}

.p-paginator-element {
  @apply !rounded-full !border-none;
}

.p-badge {
  @apply rounded-md;
}

/* stacked image wrap */
.stacked-image-wrap {
  width: 70px;
  height: 70px;
  position: relative;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.stacked-image-wrap > img {
  position: absolute;
  z-index: 1;
}
.stacked-image-wrap > img:nth-of-type(1) {
  left: -40px;
  z-index: 0;
  transform: rotate(-15deg) scale(0.8);
}
.stacked-image-wrap > img:nth-of-type(3) {
  right: -40px;
  z-index: 0;
  transform: rotate(15deg) scale(0.8);
}

.PhoneInput > input {
  @apply p-0 border-none;
}
