@layer tailwind-base, primereact, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

@layer utilities {
  .remove-x-scroll::-webkit-scrollbar {
    display: none !important;
    -ms-overflow-style: none !important; /* IE and Edge, */
    scrollbar-width: none !important; /* Firefox, */
  }
  .remove-x-scroll {
    -ms-overflow-style: none !important; /* IE and Edge, */
    scrollbar-width: none !important; /* Firefox, */
  }
}
