@layer tailwind-base, primereact, tailwind-utilities;

@layer tailwind-base {
  *, :before, :after {
    box-sizing: border-box;
    border: 0 solid #e5e7eb;
  }

  :before, :after {
    --tw-content: "";
  }

  html {
    -webkit-text-size-adjust: 100%;
    tab-size: 4;
    font-feature-settings: normal;
    font-variation-settings: normal;
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    line-height: 1.5;
  }

  body {
    line-height: inherit;
    margin: 0;
  }

  hr {
    color: inherit;
    border-top-width: 1px;
    height: 0;
  }

  abbr:where([title]) {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
  }

  h1, h2, h3, h4, h5, h6 {
    font-size: inherit;
    font-weight: inherit;
  }

  a {
    color: inherit;
    -webkit-text-decoration: inherit;
    text-decoration: inherit;
  }

  b, strong {
    font-weight: bolder;
  }

  code, kbd, samp, pre {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
    font-size: 1em;
  }

  small {
    font-size: 80%;
  }

  sub, sup {
    vertical-align: baseline;
    font-size: 75%;
    line-height: 0;
    position: relative;
  }

  sub {
    bottom: -.25em;
  }

  sup {
    top: -.5em;
  }

  table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse;
  }

  button, input, optgroup, select, textarea {
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
  }

  button, select {
    text-transform: none;
  }

  button {
    -webkit-appearance: button;
    background-color: rgba(0, 0, 0, 0);
    background-image: none;
  }

  [type="button"] {
    -webkit-appearance: button;
    background-color: rgba(0, 0, 0, 0);
    background-image: none;
  }

  [type="reset"] {
    -webkit-appearance: button;
    background-color: rgba(0, 0, 0, 0);
    background-image: none;
  }

  [type="submit"] {
    -webkit-appearance: button;
    background-color: rgba(0, 0, 0, 0);
    background-image: none;
  }

  :-moz-focusring {
    outline: auto;
  }

  :-moz-ui-invalid {
    box-shadow: none;
  }

  progress {
    vertical-align: baseline;
  }

  ::-webkit-inner-spin-button {
    height: auto;
  }

  ::-webkit-outer-spin-button {
    height: auto;
  }

  [type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }

  summary {
    display: list-item;
  }

  blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
    margin: 0;
  }

  fieldset {
    margin: 0;
    padding: 0;
  }

  legend {
    padding: 0;
  }

  ol, ul, menu {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  dialog {
    padding: 0;
  }

  textarea {
    resize: vertical;
  }

  input::-ms-input-placeholder {
    opacity: 1;
    color: #9ca3af;
  }

  input::placeholder {
    opacity: 1;
    color: #9ca3af;
  }

  textarea::-ms-input-placeholder {
    opacity: 1;
    color: #9ca3af;
  }

  textarea::placeholder {
    opacity: 1;
    color: #9ca3af;
  }

  button, [role="button"] {
    cursor: pointer;
  }

  :disabled {
    cursor: default;
  }

  img, svg, video, canvas, audio, iframe, embed, object {
    vertical-align: middle;
    display: block;
  }

  img, video {
    max-width: 100%;
    height: auto;
  }

  [hidden] {
    display: none;
  }

  .tooltip-arrow, .tooltip-arrow:before {
    background: inherit;
    width: 8px;
    height: 8px;
    position: absolute;
  }

  .tooltip-arrow {
    visibility: hidden;
  }

  .tooltip-arrow:before {
    content: "";
    visibility: visible;
    transform: rotate(45deg);
  }

  [data-tooltip-style^="light"] + .tooltip > .tooltip-arrow:before {
    border-style: solid;
    border-color: #e5e7eb;
  }

  [data-tooltip-style^="light"] + .tooltip[data-popper-placement^="top"] > .tooltip-arrow:before {
    border-bottom-width: 1px;
    border-right-width: 1px;
  }

  [data-tooltip-style^="light"] + .tooltip[data-popper-placement^="right"] > .tooltip-arrow:before {
    border-bottom-width: 1px;
    border-left-width: 1px;
  }

  [data-tooltip-style^="light"] + .tooltip[data-popper-placement^="bottom"] > .tooltip-arrow:before {
    border-top-width: 1px;
    border-left-width: 1px;
  }

  [data-tooltip-style^="light"] + .tooltip[data-popper-placement^="left"] > .tooltip-arrow:before {
    border-top-width: 1px;
    border-right-width: 1px;
  }

  .tooltip[data-popper-placement^="top"] > .tooltip-arrow {
    bottom: -4px;
  }

  .tooltip[data-popper-placement^="bottom"] > .tooltip-arrow {
    top: -4px;
  }

  .tooltip[data-popper-placement^="left"] > .tooltip-arrow {
    right: -4px;
  }

  .tooltip[data-popper-placement^="right"] > .tooltip-arrow {
    left: -4px;
  }

  .tooltip.invisible > .tooltip-arrow:before {
    visibility: hidden;
  }

  [data-popper-arrow], [data-popper-arrow]:before {
    background: inherit;
    width: 8px;
    height: 8px;
    position: absolute;
  }

  [data-popper-arrow] {
    visibility: hidden;
  }

  [data-popper-arrow]:before {
    content: "";
    visibility: visible;
    transform: rotate(45deg);
  }

  [data-popper-arrow]:after {
    content: "";
    visibility: visible;
    background: inherit;
    width: 9px;
    height: 9px;
    position: absolute;
    transform: rotate(45deg);
  }

  [role="tooltip"] > [data-popper-arrow]:before {
    border-style: solid;
    border-color: #e5e7eb;
  }

  .dark [role="tooltip"] > [data-popper-arrow]:before {
    border-style: solid;
    border-color: #4b5563;
  }

  [role="tooltip"] > [data-popper-arrow]:after {
    border-style: solid;
    border-color: #e5e7eb;
  }

  .dark [role="tooltip"] > [data-popper-arrow]:after {
    border-style: solid;
    border-color: #4b5563;
  }

  [data-popover][role="tooltip"][data-popper-placement^="top"] > [data-popper-arrow]:before, [data-popover][role="tooltip"][data-popper-placement^="top"] > [data-popper-arrow]:after {
    border-bottom-width: 1px;
    border-right-width: 1px;
  }

  [data-popover][role="tooltip"][data-popper-placement^="right"] > [data-popper-arrow]:before, [data-popover][role="tooltip"][data-popper-placement^="right"] > [data-popper-arrow]:after {
    border-bottom-width: 1px;
    border-left-width: 1px;
  }

  [data-popover][role="tooltip"][data-popper-placement^="bottom"] > [data-popper-arrow]:before, [data-popover][role="tooltip"][data-popper-placement^="bottom"] > [data-popper-arrow]:after {
    border-top-width: 1px;
    border-left-width: 1px;
  }

  [data-popover][role="tooltip"][data-popper-placement^="left"] > [data-popper-arrow]:before, [data-popover][role="tooltip"][data-popper-placement^="left"] > [data-popper-arrow]:after {
    border-top-width: 1px;
    border-right-width: 1px;
  }

  [data-popover][role="tooltip"][data-popper-placement^="top"] > [data-popper-arrow] {
    bottom: -5px;
  }

  [data-popover][role="tooltip"][data-popper-placement^="bottom"] > [data-popper-arrow] {
    top: -5px;
  }

  [data-popover][role="tooltip"][data-popper-placement^="left"] > [data-popper-arrow] {
    right: -5px;
  }

  [data-popover][role="tooltip"][data-popper-placement^="right"] > [data-popper-arrow] {
    left: -5px;
  }

  [role="tooltip"].invisible > [data-popper-arrow]:before, [role="tooltip"].invisible > [data-popper-arrow]:after {
    visibility: hidden;
  }

  [multiple], textarea, select {
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    --tw-shadow: 0 0 rgba(0, 0, 0, 0);
    background-color: #fff;
    border-width: 1px;
    border-color: #6b7280;
    border-radius: 0;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  [type="text"] {
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    --tw-shadow: 0 0 rgba(0, 0, 0, 0);
    background-color: #fff;
    border-width: 1px;
    border-color: #6b7280;
    border-radius: 0;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  [type="email"] {
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    --tw-shadow: 0 0 rgba(0, 0, 0, 0);
    background-color: #fff;
    border-width: 1px;
    border-color: #6b7280;
    border-radius: 0;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  [type="url"] {
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    --tw-shadow: 0 0 rgba(0, 0, 0, 0);
    background-color: #fff;
    border-width: 1px;
    border-color: #6b7280;
    border-radius: 0;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  [type="password"] {
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    --tw-shadow: 0 0 rgba(0, 0, 0, 0);
    background-color: #fff;
    border-width: 1px;
    border-color: #6b7280;
    border-radius: 0;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  [type="number"] {
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    --tw-shadow: 0 0 rgba(0, 0, 0, 0);
    background-color: #fff;
    border-width: 1px;
    border-color: #6b7280;
    border-radius: 0;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  [type="date"] {
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    --tw-shadow: 0 0 rgba(0, 0, 0, 0);
    background-color: #fff;
    border-width: 1px;
    border-color: #6b7280;
    border-radius: 0;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  [type="datetime-local"] {
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    --tw-shadow: 0 0 rgba(0, 0, 0, 0);
    background-color: #fff;
    border-width: 1px;
    border-color: #6b7280;
    border-radius: 0;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  [type="month"] {
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    --tw-shadow: 0 0 rgba(0, 0, 0, 0);
    background-color: #fff;
    border-width: 1px;
    border-color: #6b7280;
    border-radius: 0;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  [type="search"] {
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    --tw-shadow: 0 0 rgba(0, 0, 0, 0);
    background-color: #fff;
    border-width: 1px;
    border-color: #6b7280;
    border-radius: 0;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  [type="tel"] {
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    --tw-shadow: 0 0 rgba(0, 0, 0, 0);
    background-color: #fff;
    border-width: 1px;
    border-color: #6b7280;
    border-radius: 0;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  [type="time"] {
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    --tw-shadow: 0 0 rgba(0, 0, 0, 0);
    background-color: #fff;
    border-width: 1px;
    border-color: #6b7280;
    border-radius: 0;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  [type="week"] {
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    --tw-shadow: 0 0 rgba(0, 0, 0, 0);
    background-color: #fff;
    border-width: 1px;
    border-color: #6b7280;
    border-radius: 0;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  [multiple]:focus, textarea:focus, select:focus {
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty, );
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #1c64f2;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    border-color: #1c64f2;
    outline: 2px solid rgba(0, 0, 0, 0);
  }

  [type="text"]:focus {
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty, );
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #1c64f2;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    border-color: #1c64f2;
    outline: 2px solid rgba(0, 0, 0, 0);
  }

  [type="email"]:focus {
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty, );
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #1c64f2;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    border-color: #1c64f2;
    outline: 2px solid rgba(0, 0, 0, 0);
  }

  [type="url"]:focus {
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty, );
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #1c64f2;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    border-color: #1c64f2;
    outline: 2px solid rgba(0, 0, 0, 0);
  }

  [type="password"]:focus {
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty, );
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #1c64f2;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    border-color: #1c64f2;
    outline: 2px solid rgba(0, 0, 0, 0);
  }

  [type="number"]:focus {
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty, );
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #1c64f2;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    border-color: #1c64f2;
    outline: 2px solid rgba(0, 0, 0, 0);
  }

  [type="date"]:focus {
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty, );
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #1c64f2;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    border-color: #1c64f2;
    outline: 2px solid rgba(0, 0, 0, 0);
  }

  [type="datetime-local"]:focus {
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty, );
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #1c64f2;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    border-color: #1c64f2;
    outline: 2px solid rgba(0, 0, 0, 0);
  }

  [type="month"]:focus {
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty, );
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #1c64f2;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    border-color: #1c64f2;
    outline: 2px solid rgba(0, 0, 0, 0);
  }

  [type="search"]:focus {
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty, );
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #1c64f2;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    border-color: #1c64f2;
    outline: 2px solid rgba(0, 0, 0, 0);
  }

  [type="tel"]:focus {
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty, );
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #1c64f2;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    border-color: #1c64f2;
    outline: 2px solid rgba(0, 0, 0, 0);
  }

  [type="time"]:focus {
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty, );
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #1c64f2;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    border-color: #1c64f2;
    outline: 2px solid rgba(0, 0, 0, 0);
  }

  [type="week"]:focus {
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty, );
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #1c64f2;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    border-color: #1c64f2;
    outline: 2px solid rgba(0, 0, 0, 0);
  }

  input::-ms-input-placeholder {
    color: #6b7280;
    opacity: 1;
  }

  input::placeholder {
    color: #6b7280;
    opacity: 1;
  }

  textarea::-ms-input-placeholder {
    color: #6b7280;
    opacity: 1;
  }

  textarea::placeholder {
    color: #6b7280;
    opacity: 1;
  }

  ::-webkit-datetime-edit-fields-wrapper {
    padding: 0;
  }

  ::-webkit-date-and-time-value {
    min-height: 1.5em;
  }

  select:not([size]) {
    print-color-adjust: exact;
    background-image: url("data:image/svg+xml,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 10 6'%3e %3cpath stroke='%236B7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m1 1 4 4 4-4'/%3e %3c/svg%3e");
    background-position: right .75rem center;
    background-repeat: no-repeat;
    background-size: .75em .75em;
    padding-right: 2.5rem;
  }

  [multiple] {
    background-image: initial;
    background-position: initial;
    background-repeat: unset;
    background-size: initial;
    print-color-adjust: unset;
    padding-right: .75rem;
  }

  [type="checkbox"] {
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    print-color-adjust: exact;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #1c64f2;
    --tw-shadow: 0 0 rgba(0, 0, 0, 0);
    background-color: #fff;
    background-origin: border-box;
    border-width: 1px;
    border-color: #6b7280;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
    padding: 0;
    display: inline-block;
  }

  [type="radio"] {
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    print-color-adjust: exact;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #1c64f2;
    --tw-shadow: 0 0 rgba(0, 0, 0, 0);
    background-color: #fff;
    background-origin: border-box;
    border-width: 1px;
    border-color: #6b7280;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
    padding: 0;
    display: inline-block;
  }

  [type="checkbox"] {
    border-radius: 0;
  }

  [type="radio"] {
    border-radius: 100%;
  }

  [type="checkbox"]:focus {
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty, );
    --tw-ring-offset-width: 2px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #1c64f2;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    outline: 2px solid rgba(0, 0, 0, 0);
  }

  [type="radio"]:focus {
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty, );
    --tw-ring-offset-width: 2px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #1c64f2;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    outline: 2px solid rgba(0, 0, 0, 0);
  }

  [type="checkbox"]:checked {
    background-color: currentColor;
    background-position: center;
    background-repeat: no-repeat;
    background-size: .55em .55em;
    border-color: rgba(0, 0, 0, 0);
  }

  [type="radio"]:checked {
    background-color: currentColor;
    background-position: center;
    background-repeat: no-repeat;
    background-size: .55em .55em;
    border-color: rgba(0, 0, 0, 0);
  }

  .dark [type="checkbox"]:checked {
    background-color: currentColor;
    background-position: center;
    background-repeat: no-repeat;
    background-size: .55em .55em;
    border-color: rgba(0, 0, 0, 0);
  }

  .dark [type="radio"]:checked {
    background-color: currentColor;
    background-position: center;
    background-repeat: no-repeat;
    background-size: .55em .55em;
    border-color: rgba(0, 0, 0, 0);
  }

  [type="checkbox"]:checked {
    print-color-adjust: exact;
    background-image: url("data:image/svg+xml,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 12'%3e %3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M1 5.917 5.724 10.5 15 1.5'/%3e %3c/svg%3e");
    background-repeat: no-repeat;
    background-size: .55em .55em;
  }

  [type="radio"]:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
    background-size: 1em 1em;
  }

  .dark [type="radio"]:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
    background-size: 1em 1em;
  }

  [type="checkbox"]:indeterminate {
    print-color-adjust: exact;
    background-color: currentColor;
    background-image: url("data:image/svg+xml,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 12'%3e %3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M1 5.917 5.724 10.5 15 1.5'/%3e %3c/svg%3e");
    background-position: center;
    background-repeat: no-repeat;
    background-size: .55em .55em;
    border-color: rgba(0, 0, 0, 0);
  }

  [type="checkbox"]:indeterminate:hover {
    background-color: currentColor;
    border-color: rgba(0, 0, 0, 0);
  }

  [type="checkbox"]:indeterminate:focus {
    background-color: currentColor;
    border-color: rgba(0, 0, 0, 0);
  }

  [type="file"] {
    background: unset;
    border-color: inherit;
    font-size: unset;
    line-height: inherit;
    border-width: 0;
    border-radius: 0;
    padding: 0;
  }

  [type="file"]:focus {
    outline: 1px auto inherit;
  }

  input[type="file"]::-ms-browse {
    color: #fff;
    cursor: pointer;
    background: #1f2937;
    border: 0;
    padding: .625rem 1rem .625rem 2rem;
    font-size: .875rem;
    font-weight: 500;
  }

  input[type="file"]::file-selector-button {
    color: #fff;
    cursor: pointer;
    background: #1f2937;
    border: 0;
    padding: .625rem 1rem .625rem 2rem;
    font-size: .875rem;
    font-weight: 500;
  }

  input[type="file"]:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::-ms-browse {
    margin-left: -1rem;
    margin-right: 1rem;
  }

  input[type="file"]:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
    margin-left: -1rem;
    margin-right: 1rem;
  }

  input[type="file"]:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-ms-browse {
    margin-left: 1rem;
    margin-right: -1rem;
  }

  input[type="file"]:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
    margin-left: 1rem;
    margin-right: -1rem;
  }

  input[type="file"]::-ms-browse:hover {
    background: #374151;
  }

  input[type="file"]::file-selector-button:hover {
    background: #374151;
  }

  .dark input[type="file"]::-ms-browse {
    color: #fff;
    background: #4b5563;
  }

  .dark input[type="file"]::file-selector-button {
    color: #fff;
    background: #4b5563;
  }

  .dark input[type="file"]::-ms-browse:hover {
    background: #6b7280;
  }

  .dark input[type="file"]::file-selector-button:hover {
    background: #6b7280;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    cursor: pointer;
    background: #1c64f2;
    border: 0;
    border-radius: 9999px;
    width: 1.25rem;
    height: 1.25rem;
  }

  input[type="range"]:disabled::-webkit-slider-thumb {
    background: #9ca3af;
  }

  .dark input[type="range"]:disabled::-webkit-slider-thumb {
    background: #6b7280;
  }

  input[type="range"]:focus::-webkit-slider-thumb {
    outline-offset: 2px;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
    --tw-ring-opacity: 1px;
    --tw-ring-color: rgba(164, 202, 254, var(--tw-ring-opacity));
    outline: 2px solid rgba(0, 0, 0, 0);
  }

  input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    cursor: pointer;
    background: #1c64f2;
    border: 0;
    border-radius: 9999px;
    width: 1.25rem;
    height: 1.25rem;
  }

  input[type="range"]:disabled::-moz-range-thumb {
    background: #9ca3af;
  }

  .dark input[type="range"]:disabled::-moz-range-thumb {
    background: #6b7280;
  }

  input[type="range"]::-moz-range-progress {
    background: #3f83f8;
  }

  input[type="range"]::-ms-fill-lower {
    background: #3f83f8;
  }

  .toggle-bg:after {
    content: "";
    box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    background: #fff;
    border-width: 1px;
    border-color: #d1d5db;
    border-radius: 9999px;
    width: 1.25rem;
    height: 1.25rem;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-backdrop-filter;
    transition-duration: .15s;
    position: absolute;
    top: .125rem;
    left: .125rem;
  }

  input:checked + .toggle-bg:after {
    border-color: #fff;
    transform: translateX(100%);
  }

  input:checked + .toggle-bg {
    background: #1c64f2;
    border-color: #1c64f2;
  }

  *, :before, :after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position: ;
    --tw-gradient-via-position: ;
    --tw-gradient-to-position: ;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(63, 131, 248, .5);
    --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
    --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
    --tw-shadow: 0 0 rgba(0, 0, 0, 0);
    --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
  }

  ::-ms-backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position: ;
    --tw-gradient-via-position: ;
    --tw-gradient-to-position: ;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(63, 131, 248, .5);
    --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
    --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
    --tw-shadow: 0 0 rgba(0, 0, 0, 0);
    --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
  }

  ::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position: ;
    --tw-gradient-via-position: ;
    --tw-gradient-to-position: ;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(63, 131, 248, .5);
    --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
    --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
    --tw-shadow: 0 0 rgba(0, 0, 0, 0);
    --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
  }
}

@layer tailwind-utilities {
  .container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .container {
      max-width: 1280px;
    }
  }

  @media (min-width: 1536px) {
    .container {
      max-width: 1536px;
    }
  }

  .sr-only {
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .pointer-events-none {
    pointer-events: none;
  }

  .pointer-events-auto {
    pointer-events: auto;
  }

  .\!visible {
    visibility: visible !important;
  }

  .visible {
    visibility: visible;
  }

  .invisible {
    visibility: hidden;
  }

  .collapse {
    visibility: collapse;
  }

  .static {
    position: static;
  }

  .fixed {
    position: fixed;
  }

  .\!absolute {
    position: absolute !important;
  }

  .absolute {
    position: absolute;
  }

  .relative {
    position: relative;
  }

  .\!sticky {
    position: sticky !important;
  }

  .sticky {
    position: sticky;
  }

  .inset-0 {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .\!left-0 {
    left: 0 !important;
  }

  .\!top-0 {
    top: 0 !important;
  }

  .bottom-0 {
    bottom: 0;
  }

  .bottom-20 {
    bottom: 5rem;
  }

  .bottom-\[-0\.75rem\] {
    bottom: -.75rem;
  }

  .bottom-\[60px\] {
    bottom: 60px;
  }

  .end-1:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
    right: .25rem;
  }

  .end-1:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    left: .25rem;
  }

  .left-0 {
    left: 0;
  }

  .left-2 {
    left: .5rem;
  }

  .left-2\/4 {
    left: 50%;
  }

  .left-44 {
    left: 11rem;
  }

  .left-\[50\%\] {
    left: 50%;
  }

  .left-auto {
    left: auto;
  }

  .left-full {
    left: 100%;
  }

  .right-0 {
    right: 0;
  }

  .right-12 {
    right: 3rem;
  }

  .right-20 {
    right: 5rem;
  }

  .right-3 {
    right: .75rem;
  }

  .right-\[-25\] {
    right: -25px;
  }

  .top-0 {
    top: 0;
  }

  .top-1\/2 {
    top: 50%;
  }

  .top-2 {
    top: .5rem;
  }

  .top-\[25\] {
    top: 25px;
  }

  .top-\[50\%\] {
    top: 50%;
  }

  .top-auto {
    top: auto;
  }

  .top-full {
    top: 100%;
  }

  .z-0 {
    z-index: 0;
  }

  .z-10 {
    z-index: 10;
  }

  .z-20 {
    z-index: 20;
  }

  .z-30 {
    z-index: 30;
  }

  .z-40 {
    z-index: 40;
  }

  .z-50 {
    z-index: 50;
  }

  .z-\[1\] {
    z-index: 1;
  }

  .z-\[2\] {
    z-index: 2;
  }

  .order-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .order-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .col-span-1 {
    grid-column: span 1 / span 1;
  }

  .col-span-2 {
    grid-column: span 2 / span 2;
  }

  .col-span-3 {
    grid-column: span 3 / span 3;
  }

  .float-left {
    float: left;
  }

  .\!m-0 {
    margin: 0 !important;
  }

  .m-0 {
    margin: 0;
  }

  .m-2 {
    margin: .5rem;
  }

  .m-3 {
    margin: .75rem;
  }

  .m-5 {
    margin: 1.25rem;
  }

  .m-\[-1rem\] {
    margin: -1rem;
  }

  .m-\[0\.143rem\] {
    margin: .143rem;
  }

  .m-auto {
    margin: auto;
  }

  .\!mx-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .\!mx-1\.5 {
    margin-left: .375rem !important;
    margin-right: .375rem !important;
  }

  .\!my-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .mx-1 {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .mx-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-0\.5 {
    margin-top: .125rem;
    margin-bottom: .125rem;
  }

  .my-1 {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .\!mb-0 {
    margin-bottom: 0 !important;
  }

  .\!mb-2 {
    margin-bottom: .5rem !important;
  }

  .-ml-1 {
    margin-left: -.25rem;
  }

  .-ml-3 {
    margin-left: -.75rem;
  }

  .-ml-4 {
    margin-left: -1rem;
  }

  .-mr-7 {
    margin-right: -1.75rem;
  }

  .-mt-1 {
    margin-top: -.25rem;
  }

  .-mt-2 {
    margin-top: -.5rem;
  }

  .mb-1 {
    margin-bottom: .25rem;
  }

  .mb-1\.5 {
    margin-bottom: .375rem;
  }

  .mb-10 {
    margin-bottom: 2.5rem;
  }

  .mb-12 {
    margin-bottom: 3rem;
  }

  .mb-2 {
    margin-bottom: .5rem;
  }

  .mb-4 {
    margin-bottom: 1rem;
  }

  .mb-6 {
    margin-bottom: 1.5rem;
  }

  .mb-8 {
    margin-bottom: 2rem;
  }

  .mb-\[-0\.5715rem\] {
    margin-bottom: -.5715rem;
  }

  .me-1:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
    margin-right: .25rem;
  }

  .me-1:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    margin-left: .25rem;
  }

  .ml-0 {
    margin-left: 0;
  }

  .ml-1 {
    margin-left: .25rem;
  }

  .ml-2 {
    margin-left: .5rem;
  }

  .ml-3 {
    margin-left: .75rem;
  }

  .ml-4 {
    margin-left: 1rem;
  }

  .ml-\[-0\.4715rem\] {
    margin-left: -.4715rem;
  }

  .ml-\[-0\.5715rem\] {
    margin-left: -.5715rem;
  }

  .ml-\[-0\.75rem\] {
    margin-left: -.75rem;
  }

  .ml-auto {
    margin-left: auto;
  }

  .mr-0 {
    margin-right: 0;
  }

  .mr-0\.5 {
    margin-right: .125rem;
  }

  .mr-1 {
    margin-right: .25rem;
  }

  .mr-2 {
    margin-right: .5rem;
  }

  .mr-3 {
    margin-right: .75rem;
  }

  .mr-4 {
    margin-right: 1rem;
  }

  .mr-6 {
    margin-right: 1.5rem;
  }

  .mt-0 {
    margin-top: 0;
  }

  .mt-1 {
    margin-top: .25rem;
  }

  .mt-1\.5 {
    margin-top: .375rem;
  }

  .mt-10 {
    margin-top: 2.5rem;
  }

  .mt-16 {
    margin-top: 4rem;
  }

  .mt-2 {
    margin-top: .5rem;
  }

  .mt-24 {
    margin-top: 6rem;
  }

  .mt-3 {
    margin-top: .75rem;
  }

  .mt-5 {
    margin-top: 1.25rem;
  }

  .mt-8 {
    margin-top: 2rem;
  }

  .mt-\[-0\.5715rem\] {
    margin-top: -.5715rem;
  }

  .mt-\[-0\.5rem\] {
    margin-top: -.5rem;
  }

  .box-border {
    box-sizing: border-box;
  }

  .block {
    display: block;
  }

  .inline-block {
    display: inline-block;
  }

  .inline {
    display: inline;
  }

  .flex {
    display: -ms-flexbox;
    display: flex;
  }

  .inline-flex {
    display: -ms-inline-flexbox;
    display: inline-flex;
  }

  .table {
    display: table;
  }

  .grid {
    display: grid;
  }

  .contents {
    display: contents;
  }

  .hidden {
    display: none;
  }

  .\!h-10 {
    height: 2.5rem !important;
  }

  .\!h-16 {
    height: 4rem !important;
  }

  .\!h-24 {
    height: 6rem !important;
  }

  .\!h-screen {
    height: 100vh !important;
  }

  .h-0 {
    height: 0;
  }

  .h-1 {
    height: .25rem;
  }

  .h-10 {
    height: 2.5rem;
  }

  .h-12 {
    height: 3rem;
  }

  .h-16 {
    height: 4rem;
  }

  .h-2 {
    height: .5rem;
  }

  .h-24 {
    height: 6rem;
  }

  .h-28 {
    height: 7rem;
  }

  .h-3 {
    height: .75rem;
  }

  .h-4 {
    height: 1rem;
  }

  .h-40 {
    height: 10rem;
  }

  .h-44 {
    height: 11rem;
  }

  .h-48 {
    height: 12rem;
  }

  .h-5 {
    height: 1.25rem;
  }

  .h-56 {
    height: 14rem;
  }

  .h-6 {
    height: 1.5rem;
  }

  .h-7 {
    height: 1.75rem;
  }

  .h-72 {
    height: 18rem;
  }

  .h-8 {
    height: 2rem;
  }

  .h-9 {
    height: 2.25rem;
  }

  .h-\[0\.3rem\] {
    height: .3rem;
  }

  .h-\[1\.143rem\] {
    height: 1.143rem;
  }

  .h-\[1\.5rem\] {
    height: 1.5rem;
  }

  .h-\[2\.25rem\] {
    height: 2.25rem;
  }

  .h-\[20px\] {
    height: 20px;
  }

  .h-\[28rem\] {
    height: 28rem;
  }

  .h-\[2px\] {
    height: 2px;
  }

  .h-\[2rem\] {
    height: 2rem;
  }

  .h-\[3rem\] {
    height: 3rem;
  }

  .h-\[60vh\] {
    height: 60vh;
  }

  .h-\[80\%\] {
    height: 80%;
  }

  .h-\[90\%\] {
    height: 90%;
  }

  .h-\[91\%\] {
    height: 91%;
  }

  .h-\[9px\] {
    height: 9px;
  }

  .h-\[calc\(100\%\+18px\)\] {
    height: calc(100% + 18px);
  }

  .h-full {
    height: 100%;
  }

  .h-screen {
    height: 100vh;
  }

  .\!max-h-0 {
    max-height: 0 !important;
  }

  .\!max-h-40 {
    max-height: 10rem !important;
  }

  .\!max-h-full {
    max-height: 100% !important;
  }

  .max-h-0 {
    max-height: 0;
  }

  .max-h-40 {
    max-height: 10rem;
  }

  .max-h-\[200px\] {
    max-height: 200px;
  }

  .max-h-\[24rem\] {
    max-height: 24rem;
  }

  .max-h-\[90\%\] {
    max-height: 90%;
  }

  .min-h-0 {
    min-height: 0;
  }

  .min-h-\[12rem\] {
    min-height: 12rem;
  }

  .min-h-\[70px\] {
    min-height: 70px;
  }

  .min-h-full {
    min-height: 100%;
  }

  .\!w-10 {
    width: 2.5rem !important;
  }

  .\!w-24 {
    width: 6rem !important;
  }

  .\!w-52 {
    width: 13rem !important;
  }

  .\!w-96 {
    width: 24rem !important;
  }

  .\!w-\[80vw\] {
    width: 80vw !important;
  }

  .\!w-screen {
    width: 100vw !important;
  }

  .w-0 {
    width: 0;
  }

  .w-1 {
    width: .25rem;
  }

  .w-1\/2 {
    width: 50%;
  }

  .w-1\/3 {
    width: 33.3333%;
  }

  .w-10 {
    width: 2.5rem;
  }

  .w-12 {
    width: 3rem;
  }

  .w-16 {
    width: 4rem;
  }

  .w-2 {
    width: .5rem;
  }

  .w-20 {
    width: 5rem;
  }

  .w-24 {
    width: 6rem;
  }

  .w-28 {
    width: 7rem;
  }

  .w-3 {
    width: .75rem;
  }

  .w-36 {
    width: 9rem;
  }

  .w-4 {
    width: 1rem;
  }

  .w-40 {
    width: 10rem;
  }

  .w-48 {
    width: 12rem;
  }

  .w-5 {
    width: 1.25rem;
  }

  .w-52 {
    width: 13rem;
  }

  .w-56 {
    width: 14rem;
  }

  .w-6 {
    width: 1.5rem;
  }

  .w-64 {
    width: 16rem;
  }

  .w-7 {
    width: 1.75rem;
  }

  .w-8 {
    width: 2rem;
  }

  .w-80 {
    width: 20rem;
  }

  .w-9 {
    width: 2.25rem;
  }

  .w-96 {
    width: 24rem;
  }

  .w-\[0\.3rem\] {
    width: .3rem;
  }

  .w-\[1\%\] {
    width: 1%;
  }

  .w-\[1\.143rem\] {
    width: 1.143rem;
  }

  .w-\[12\.5rem\] {
    width: 12.5rem;
  }

  .w-\[2px\] {
    width: 2px;
  }

  .w-\[2rem\] {
    width: 2rem;
  }

  .w-\[30vw\] {
    width: 30vw;
  }

  .w-\[3rem\] {
    width: 3rem;
  }

  .w-\[50vw\] {
    width: 50vw;
  }

  .w-\[80vw\] {
    width: 80vw;
  }

  .w-\[9px\] {
    width: 9px;
  }

  .w-\[calc\(100\%\+18px\)\] {
    width: calc(100% + 18px);
  }

  .w-auto {
    width: auto;
  }

  .w-full {
    width: 100%;
  }

  .w-px {
    width: 1px;
  }

  .min-w-\[1\.143rem\] {
    min-width: 1.143rem;
  }

  .min-w-\[1\.5rem\] {
    min-width: 1.5rem;
  }

  .min-w-\[12\.5rem\] {
    min-width: 12.5rem;
  }

  .min-w-\[2\.25rem\] {
    min-width: 2.25rem;
  }

  .min-w-\[350px\] {
    min-width: 350px;
  }

  .min-w-\[3rem\] {
    min-width: 3rem;
  }

  .min-w-full {
    min-width: 100%;
  }

  .max-w-\[30rem\] {
    max-width: 30rem;
  }

  .max-w-\[3rem\] {
    max-width: 3rem;
  }

  .max-w-full {
    max-width: 100%;
  }

  .max-w-screen-xl {
    max-width: 1280px;
  }

  .flex-1 {
    -ms-flex: 1;
    flex: 1;
  }

  .flex-auto {
    -ms-flex: auto;
    flex: auto;
  }

  .flex-initial {
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .flex-shrink, .shrink {
    -ms-flex-negative: 1;
    flex-shrink: 1;
  }

  .shrink-0 {
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .flex-grow, .grow {
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .grow-0 {
    -ms-flex-positive: 0;
    flex-grow: 0;
  }

  .basis-2\/4 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }

  .table-fixed {
    table-layout: fixed;
  }

  .border-collapse {
    border-collapse: collapse;
  }

  .\!border-separate {
    border-collapse: separate !important;
  }

  .border-separate {
    border-collapse: separate;
  }

  .border-spacing-0 {
    --tw-border-spacing-x: 0px;
    --tw-border-spacing-y: 0px;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .border-spacing-y-\[14px\] {
    --tw-border-spacing-y: 14px;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  }

  .origin-bottom {
    transform-origin: bottom;
  }

  .origin-center {
    transform-origin: center;
  }

  .origin-left {
    transform-origin: 0;
  }

  .origin-right {
    transform-origin: 100%;
  }

  .origin-top {
    transform-origin: top;
  }

  .\!-translate-x-full {
    --tw-translate-x: -100% !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .\!-translate-y-full {
    --tw-translate-y: -100% !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .\!translate-x-0 {
    --tw-translate-x: 0px !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .\!translate-x-full {
    --tw-translate-x: 100% !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .\!translate-y-0 {
    --tw-translate-y: 0px !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .\!translate-y-full {
    --tw-translate-y: 100% !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .-translate-x-full {
    --tw-translate-x: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .-translate-y-full {
    --tw-translate-y: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .translate-x-5 {
    --tw-translate-x: 1.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .translate-x-full {
    --tw-translate-x: 100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .translate-y-2\/4 {
    --tw-translate-y: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .translate-y-full {
    --tw-translate-y: 100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .rotate-180 {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .rotate-45 {
    --tw-rotate: 45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .\!scale-100 {
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .\!scale-75 {
    --tw-scale-x: .75 !important;
    --tw-scale-y: .75 !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .\!transform-none {
    transform: none !important;
  }

  .transform-none {
    transform: none;
  }

  @keyframes pulse {
    50% {
      opacity: .5;
    }
  }

  .animate-pulse {
    animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  .animate-spin {
    animation: 1s linear infinite spin;
  }

  .cursor-auto {
    cursor: auto;
  }

  .cursor-col-resize {
    cursor: col-resize;
  }

  .cursor-default {
    cursor: default;
  }

  .cursor-grab {
    cursor: grab;
  }

  .cursor-move {
    cursor: move;
  }

  .cursor-not-allowed {
    cursor: not-allowed;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .cursor-row-resize {
    cursor: row-resize;
  }

  .cursor-text {
    cursor: text;
  }

  .select-none {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .resize {
    resize: both;
  }

  .list-none {
    list-style-type: none;
  }

  .appearance-none {
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
  }

  .grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .flex-row {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .flex-row-reverse {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .flex-col {
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .flex-col-reverse {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .flex-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .flex-nowrap {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .items-start {
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .items-end {
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .\!items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .items-center {
    -ms-flex-align: center;
    align-items: center;
  }

  .justify-start {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .justify-end {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .\!justify-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-center {
    -ms-flex-pack: center;
    justify-content: center;
  }

  .justify-between {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .justify-around {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .gap-16 {
    gap: 4rem;
  }

  .gap-2 {
    gap: .5rem;
  }

  .gap-3 {
    gap: .75rem;
  }

  .gap-5 {
    gap: 1.25rem;
  }

  .gap-x-20 {
    column-gap: 5rem;
  }

  .gap-x-4 {
    column-gap: 1rem;
  }

  .gap-y-7 {
    row-gap: 1.75rem;
  }

  .space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse));
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
  }

  .space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
  }

  .self-auto {
    -ms-flex-item-align: auto;
    align-self: auto;
  }

  .self-center {
    -ms-flex-item-align: center;
    align-self: center;
  }

  .self-baseline {
    align-self: baseline;
  }

  .overflow-auto {
    overflow: auto;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .overflow-visible {
    overflow: visible;
  }

  .overflow-scroll {
    overflow: scroll;
  }

  .overflow-x-auto {
    overflow-x: auto;
  }

  .overflow-y-auto {
    overflow-y: auto;
  }

  .overflow-x-hidden {
    overflow-x: hidden;
  }

  .overflow-y-hidden {
    overflow-y: hidden;
  }

  .overscroll-auto {
    overscroll-behavior: auto;
  }

  .overscroll-contain {
    overscroll-behavior: contain;
  }

  .scroll-smooth {
    scroll-behavior: smooth;
  }

  .truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .overflow-ellipsis, .text-ellipsis {
    text-overflow: ellipsis;
  }

  .whitespace-nowrap {
    white-space: nowrap;
  }

  .whitespace-pre-line {
    white-space: pre-line;
  }

  .break-words {
    overflow-wrap: break-word;
  }

  .break-all {
    word-break: break-all;
  }

  .\!rounded-full {
    border-radius: 9999px !important;
  }

  .\!rounded-lg {
    border-radius: .5rem !important;
  }

  .\!rounded-md {
    border-radius: .375rem !important;
  }

  .\!rounded-xl {
    border-radius: .75rem !important;
  }

  .rounded {
    border-radius: .25rem;
  }

  .rounded-2xl {
    border-radius: 1rem;
  }

  .rounded-\[16px\] {
    border-radius: 16px;
  }

  .rounded-\[50\%\] {
    border-radius: 50%;
  }

  .rounded-full {
    border-radius: 9999px;
  }

  .rounded-lg {
    border-radius: .5rem;
  }

  .rounded-md {
    border-radius: .375rem;
  }

  .rounded-none {
    border-radius: 0;
  }

  .rounded-xl {
    border-radius: .75rem;
  }

  .rounded-b-lg {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .rounded-b-md {
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .rounded-l-lg {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .rounded-r-lg {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  .rounded-r-md {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem;
  }

  .rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .rounded-t-lg {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
  }

  .rounded-t-md {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
  }

  .rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .rounded-bl-lg {
    border-bottom-left-radius: .5rem;
  }

  .rounded-bl-md {
    border-bottom-left-radius: .375rem;
  }

  .rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .rounded-br-lg {
    border-bottom-right-radius: .5rem;
  }

  .rounded-br-md {
    border-bottom-right-radius: .375rem;
  }

  .rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .rounded-tl-lg {
    border-top-left-radius: .5rem;
  }

  .rounded-tl-md {
    border-top-left-radius: .375rem;
  }

  .rounded-tl-none {
    border-top-left-radius: 0;
  }

  .rounded-tr-lg {
    border-top-right-radius: .5rem;
  }

  .rounded-tr-md {
    border-top-right-radius: .375rem;
  }

  .rounded-tr-none {
    border-top-right-radius: 0;
  }

  .border {
    border-width: 1px;
  }

  .border-0 {
    border-width: 0;
  }

  .border-2 {
    border-width: 2px;
  }

  .border-x {
    border-left-width: 1px;
    border-right-width: 1px;
  }

  .border-x-0 {
    border-left-width: 0;
    border-right-width: 0;
  }

  .border-x-\[0\.25rem\] {
    border-left-width: .25rem;
    border-right-width: .25rem;
  }

  .border-y {
    border-top-width: 1px;
    border-bottom-width: 1px;
  }

  .border-y-\[0\.25rem\] {
    border-top-width: .25rem;
    border-bottom-width: .25rem;
  }

  .border-b {
    border-bottom-width: 1px;
  }

  .border-b-0 {
    border-bottom-width: 0;
  }

  .border-b-2 {
    border-bottom-width: 2px;
  }

  .border-b-\[0\.25rem\] {
    border-bottom-width: .25rem;
  }

  .border-l {
    border-left-width: 1px;
  }

  .border-l-0 {
    border-left-width: 0;
  }

  .border-l-4 {
    border-left-width: 4px;
  }

  .border-l-\[0\.25rem\] {
    border-left-width: .25rem;
  }

  .border-r {
    border-right-width: 1px;
  }

  .border-r-0 {
    border-right-width: 0;
  }

  .border-r-\[0\.25rem\] {
    border-right-width: .25rem;
  }

  .border-t {
    border-top-width: 1px;
  }

  .border-t-0 {
    border-top-width: 0;
  }

  .border-t-\[0\.25rem\] {
    border-top-width: .25rem;
  }

  .border-solid {
    border-style: solid;
  }

  .border-dashed {
    border-style: dashed;
  }

  .border-dotted {
    border-style: dotted;
  }

  .border-none {
    border-style: none;
  }

  .\!border-gray-300 {
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(235, 245, 255, var(--tw-border-opacity));
  }

  .border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(63, 131, 248, var(--tw-border-opacity));
  }

  .border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(28, 100, 242, var(--tw-border-opacity));
  }

  .border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(26, 86, 219, var(--tw-border-opacity));
  }

  .border-blue-900\/40 {
    border-color: rgba(35, 56, 118, .4);
  }

  .border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(14, 159, 110, var(--tw-border-opacity));
  }

  .border-inherit {
    border-color: inherit;
  }

  .border-orange-500 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 90, 31, var(--tw-border-opacity));
  }

  .border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(144, 97, 249, var(--tw-border-opacity));
  }

  .border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(240, 82, 82, var(--tw-border-opacity));
  }

  .border-transparent {
    border-color: rgba(0, 0, 0, 0);
  }

  .border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .border-white\/20 {
    border-color: rgba(255, 255, 255, .2);
  }

  .border-b-gray-600 {
    --tw-border-opacity: 1;
    border-bottom-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .border-l-gray-600 {
    --tw-border-opacity: 1;
    border-left-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .border-r-gray-600 {
    --tw-border-opacity: 1;
    border-right-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .border-t-gray-600 {
    --tw-border-opacity: 1;
    border-top-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .\!bg-blue-500 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(63, 131, 248, var(--tw-bg-opacity)) !important;
  }

  .\!bg-gray-50 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .\!bg-gray-700 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .bg-black\/40 {
    background-color: rgba(0, 0, 0, .4);
  }

  .bg-black\/50 {
    background-color: rgba(0, 0, 0, .5);
  }

  .bg-black\/90 {
    background-color: rgba(0, 0, 0, .9);
  }

  .bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 239, 254, var(--tw-bg-opacity));
  }

  .bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(195, 221, 253, var(--tw-bg-opacity));
  }

  .bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 202, 254, var(--tw-bg-opacity));
  }

  .bg-blue-300\/20 {
    background-color: rgba(164, 202, 254, .2);
  }

  .bg-blue-300\/40 {
    background-color: rgba(164, 202, 254, .4);
  }

  .bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(118, 169, 250, var(--tw-bg-opacity));
  }

  .bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(235, 245, 255, var(--tw-bg-opacity));
  }

  .bg-blue-50\/50 {
    background-color: rgba(235, 245, 255, .5);
  }

  .bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(63, 131, 248, var(--tw-bg-opacity));
  }

  .bg-blue-500\/20 {
    background-color: rgba(63, 131, 248, .2);
  }

  .bg-blue-500\/40 {
    background-color: rgba(63, 131, 248, .4);
  }

  .bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(28, 100, 242, var(--tw-bg-opacity));
  }

  .bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(26, 86, 219, var(--tw-bg-opacity));
  }

  .bg-blue-900\/40 {
    background-color: rgba(35, 56, 118, .4);
  }

  .bg-emerald-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .bg-emerald-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .bg-gray-100\/40 {
    background-color: rgba(243, 244, 246, .4);
  }

  .bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .bg-gray-300\/20 {
    background-color: rgba(209, 213, 219, .2);
  }

  .bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .bg-gray-800\/80 {
    background-color: rgba(31, 41, 55, .8);
  }

  .bg-gray-800\/90 {
    background-color: rgba(31, 41, 55, .9);
  }

  .bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .bg-gray-950 {
    --tw-bg-opacity: 1;
    background-color: rgba(3, 7, 18, var(--tw-bg-opacity));
  }

  .bg-gray-950\/40 {
    background-color: rgba(3, 7, 18, .4);
  }

  .bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(222, 247, 236, var(--tw-bg-opacity));
  }

  .bg-green-300\/20 {
    background-color: rgba(132, 225, 188, .2);
  }

  .bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(14, 159, 110, var(--tw-bg-opacity));
  }

  .bg-inherit {
    background-color: inherit;
  }

  .bg-orange-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 236, 220, var(--tw-bg-opacity));
  }

  .bg-orange-300\/20 {
    background-color: rgba(253, 186, 140, .2);
  }

  .bg-orange-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 90, 31, var(--tw-bg-opacity));
  }

  .bg-purple-300\/20 {
    background-color: rgba(202, 191, 253, .2);
  }

  .bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(144, 97, 249, var(--tw-bg-opacity));
  }

  .bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 232, 232, var(--tw-bg-opacity));
  }

  .bg-red-300\/20 {
    background-color: rgba(248, 180, 180, .2);
  }

  .bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 82, 82, var(--tw-bg-opacity));
  }

  .bg-slate-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 250, 252, var(--tw-bg-opacity));
  }

  .bg-transparent {
    background-color: rgba(0, 0, 0, 0);
  }

  .bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .bg-white\/10 {
    background-color: rgba(255, 255, 255, .1);
  }

  .bg-white\/30 {
    background-color: rgba(255, 255, 255, .3);
  }

  .bg-white\/50 {
    background-color: rgba(255, 255, 255, .5);
  }

  .bg-opacity-40 {
    --tw-bg-opacity: .4;
  }

  .bg-opacity-50 {
    --tw-bg-opacity: .5;
  }

  .bg-opacity-90 {
    --tw-bg-opacity: .9;
  }

  .bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
  }

  .from-orange-100 {
    --tw-gradient-from: #feecdc var(--tw-gradient-from-position);
    --tw-gradient-to: rgba(254, 236, 220, 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .via-orange-300 {
    --tw-gradient-to: rgba(253, 186, 140, 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #fdba8c var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .to-orange-100 {
    --tw-gradient-to: #feecdc var(--tw-gradient-to-position);
  }

  .bg-clip-padding {
    background-clip: padding-box;
  }

  .fill-none {
    fill: none;
  }

  .stroke-blue-500 {
    stroke: #3f83f8;
  }

  .stroke-current {
    stroke: currentColor;
  }

  .stroke-gray-200 {
    stroke: #e5e7eb;
  }

  .\!p-0 {
    padding: 0 !important;
  }

  .\!p-2 {
    padding: .5rem !important;
  }

  .\!p-2\.5 {
    padding: .625rem !important;
  }

  .\!p-3 {
    padding: .75rem !important;
  }

  .\!p-\[0\.55625rem\] {
    padding: .55625rem !important;
  }

  .p-0 {
    padding: 0;
  }

  .p-1 {
    padding: .25rem;
  }

  .p-10 {
    padding: 2.5rem;
  }

  .p-2 {
    padding: .5rem;
  }

  .p-2\.5 {
    padding: .625rem;
  }

  .p-3 {
    padding: .75rem;
  }

  .p-4 {
    padding: 1rem;
  }

  .p-5 {
    padding: 1.25rem;
  }

  .p-6 {
    padding: 1.5rem;
  }

  .p-8 {
    padding: 2rem;
  }

  .\!px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .\!py-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .\!py-1\.5 {
    padding-top: .375rem !important;
    padding-bottom: .375rem !important;
  }

  .\!py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .px-1 {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .py-1\.5 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .py-2\.5 {
    padding-top: .625rem;
    padding-bottom: .625rem;
  }

  .py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .py-3\.5 {
    padding-top: .875rem;
    padding-bottom: .875rem;
  }

  .py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .pb-0 {
    padding-bottom: 0;
  }

  .pb-3 {
    padding-bottom: .75rem;
  }

  .pb-6 {
    padding-bottom: 1.5rem;
  }

  .pb-8 {
    padding-bottom: 2rem;
  }

  .pb-\[18px\] {
    padding-bottom: 18px;
  }

  .pl-0 {
    padding-left: 0;
  }

  .pl-0\.5 {
    padding-left: .125rem;
  }

  .pl-14 {
    padding-left: 3.5rem;
  }

  .pl-2 {
    padding-left: .5rem;
  }

  .pl-2\.5 {
    padding-left: .625rem;
  }

  .pl-4 {
    padding-left: 1rem;
  }

  .pl-5 {
    padding-left: 1.25rem;
  }

  .pl-9 {
    padding-left: 2.25rem;
  }

  .pr-0 {
    padding-right: 0;
  }

  .pr-0\.5 {
    padding-right: .125rem;
  }

  .pr-5 {
    padding-right: 1.25rem;
  }

  .pr-7 {
    padding-right: 1.75rem;
  }

  .pr-\[18px\] {
    padding-right: 18px;
  }

  .pr-\[2\.5rem\] {
    padding-right: 2.5rem;
  }

  .pt-0 {
    padding-top: 0;
  }

  .pt-1 {
    padding-top: .25rem;
  }

  .pt-10 {
    padding-top: 2.5rem;
  }

  .pt-2 {
    padding-top: .5rem;
  }

  .pt-3 {
    padding-top: .75rem;
  }

  .pt-5 {
    padding-top: 1.25rem;
  }

  .text-left {
    text-align: left;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .align-top {
    vertical-align: top;
  }

  .align-middle {
    vertical-align: middle;
  }

  .align-bottom {
    vertical-align: bottom;
  }

  .font-sans {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }

  .\!text-\[0\.70rem\] {
    font-size: .7rem !important;
  }

  .\!text-sm {
    font-size: .875rem !important;
    line-height: 1.25rem !important;
  }

  .\!text-xs {
    font-size: .75rem !important;
    line-height: 1rem !important;
  }

  .text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }

  .font-bold {
    font-weight: 700;
  }

  .font-medium {
    font-weight: 500;
  }

  .font-normal {
    font-weight: 400;
  }

  .font-semibold {
    font-weight: 600;
  }

  .italic {
    font-style: italic;
  }

  .leading-6 {
    line-height: 1.5rem;
  }

  .leading-8 {
    line-height: 2rem;
  }

  .leading-9 {
    line-height: 2.25rem;
  }

  .leading-\[1\.143rem\] {
    line-height: 1.143rem;
  }

  .leading-\[1\.5rem\] {
    line-height: 1.5rem;
  }

  .leading-\[2\.25rem\] {
    line-height: 2.25rem;
  }

  .leading-\[3rem\] {
    line-height: 3rem;
  }

  .leading-none {
    line-height: 1;
  }

  .leading-tight {
    line-height: 1.25;
  }

  .tracking-tight {
    letter-spacing: -.025em;
  }

  .\!text-primary {
    --tw-text-opacity: 1 !important;
    color: rgba(0, 70, 213, var(--tw-text-opacity)) !important;
  }

  .text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(164, 202, 254, var(--tw-text-opacity));
  }

  .text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(63, 131, 248, var(--tw-text-opacity));
  }

  .text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(28, 100, 242, var(--tw-text-opacity));
  }

  .text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(26, 86, 219, var(--tw-text-opacity));
  }

  .text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(14, 159, 110, var(--tw-text-opacity));
  }

  .text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 108, 78, var(--tw-text-opacity));
  }

  .text-inherit {
    color: inherit;
  }

  .text-orange-500 {
    --tw-text-opacity: 1;
    color: rgba(255, 90, 31, var(--tw-text-opacity));
  }

  .text-orange-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 52, 3, var(--tw-text-opacity));
  }

  .text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(144, 97, 249, var(--tw-text-opacity));
  }

  .text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(240, 82, 82, var(--tw-text-opacity));
  }

  .text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(224, 36, 36, var(--tw-text-opacity));
  }

  .text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(200, 30, 30, var(--tw-text-opacity));
  }

  .text-slate-700 {
    --tw-text-opacity: 1;
    color: rgba(51, 65, 85, var(--tw-text-opacity));
  }

  .text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .text-white\/70 {
    color: rgba(255, 255, 255, .7);
  }

  .text-white\/80 {
    color: rgba(255, 255, 255, .8);
  }

  .text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(227, 160, 8, var(--tw-text-opacity));
  }

  .underline {
    text-decoration-line: underline;
  }

  .no-underline {
    text-decoration-line: none;
  }

  .\!opacity-0 {
    opacity: 0 !important;
  }

  .\!opacity-100 {
    opacity: 1 !important;
  }

  .\!opacity-90 {
    opacity: .9 !important;
  }

  .opacity-0 {
    opacity: 0;
  }

  .opacity-100 {
    opacity: 1;
  }

  .opacity-50 {
    opacity: .5;
  }

  .opacity-60 {
    opacity: .6;
  }

  .opacity-90 {
    opacity: .9;
  }

  .\!shadow-none {
    --tw-shadow: 0 0 rgba(0, 0, 0, 0) !important;
    --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow) !important;
  }

  .shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  }

  .shadow-\[0_0_0_0\.2rem_rgba\(191\,219\,254\,1\)\] {
    --tw-shadow: 0 0 0 .2rem #bfdbfe;
    --tw-shadow-colored: 0 0 0 .2rem var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  }

  .shadow-\[0_2px_12px_rgba\(0\,0\,0\,0\.1\)\] {
    --tw-shadow: 0 2px 12px rgba(0, 0, 0, .1);
    --tw-shadow-colored: 0 2px 12px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  }

  .shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  }

  .shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  }

  .shadow-none {
    --tw-shadow: 0 0 rgba(0, 0, 0, 0);
    --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  }

  .outline-none {
    outline-offset: 2px;
    outline: 2px solid rgba(0, 0, 0, 0);
  }

  .outline {
    outline-style: solid;
  }

  .outline-offset-0 {
    outline-offset: 0px;
  }

  .blur {
    --tw-blur: blur(8px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .\!transition-none {
    transition-property: none !important;
  }

  .transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .transition-all {
    transition-property: all;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .transition-colors {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .transition-none {
    transition-property: none;
  }

  .transition-opacity {
    transition-property: opacity;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .transition-shadow {
    transition-property: box-shadow;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .transition-transform {
    transition-property: transform;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .delay-1000 {
    transition-delay: 1s;
  }

  .delay-200 {
    transition-delay: .2s;
  }

  .duration-100 {
    transition-duration: .1s;
  }

  .duration-1000 {
    transition-duration: 1s;
  }

  .duration-150 {
    transition-duration: .15s;
  }

  .duration-200 {
    transition-duration: .2s;
  }

  .duration-300 {
    transition-duration: .3s;
  }

  .duration-500 {
    transition-duration: .5s;
  }

  .duration-\[250ms\] {
    transition-duration: .25s;
  }

  .ease-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1);
  }

  .ease-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .ease-linear {
    transition-timing-function: linear;
  }

  .ease-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1);
  }

  .remove-x-scroll::-webkit-scrollbar {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
    display: none !important;
  }

  .remove-x-scroll {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
  }
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:-top-3:before {
  content: var(--tw-content);
  top: -.75rem;
}

.before\:bottom-0:before {
  content: var(--tw-content);
  bottom: 0;
}

.before\:left-0:before {
  content: var(--tw-content);
  left: 0;
}

.before\:left-1:before {
  content: var(--tw-content);
  left: .25rem;
}

.before\:left-1\/2:before {
  content: var(--tw-content);
  left: 50%;
}

.before\:top-0:before {
  content: var(--tw-content);
  top: 0;
}

.before\:top-1\/2:before {
  content: var(--tw-content);
  top: 50%;
}

.before\:top-1\/4:before {
  content: var(--tw-content);
  top: 25%;
}

.before\:-mt-2:before {
  content: var(--tw-content);
  margin-top: -.5rem;
}

.before\:-mt-2\.5:before {
  content: var(--tw-content);
  margin-top: -.625rem;
}

.before\:ml-6:before {
  content: var(--tw-content);
  margin-left: 1.5rem;
}

.before\:block:before {
  content: var(--tw-content);
  display: block;
}

.before\:h-0:before {
  content: var(--tw-content);
  height: 0;
}

.before\:h-5:before {
  content: var(--tw-content);
  height: 1.25rem;
}

.before\:min-h-full:before {
  content: var(--tw-content);
  min-height: 100%;
}

.before\:w-0:before {
  content: var(--tw-content);
  width: 0;
}

.before\:w-5:before {
  content: var(--tw-content);
  width: 1.25rem;
}

.before\:w-full:before {
  content: var(--tw-content);
  width: 100%;
}

.before\:-translate-x-1\/2:before {
  content: var(--tw-content);
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:-translate-y-1\/2:before {
  content: var(--tw-content);
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:translate-x-5:before {
  content: var(--tw-content);
  --tw-translate-x: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:transform:before {
  content: var(--tw-content);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:rounded-full:before {
  content: var(--tw-content);
  border-radius: 9999px;
}

.before\:border-x-\[0\.75rem\]:before {
  content: var(--tw-content);
  border-left-width: .75rem;
  border-right-width: .75rem;
}

.before\:border-b-\[0\.75rem\]:before {
  content: var(--tw-content);
  border-bottom-width: .75rem;
}

.before\:border-l:before {
  content: var(--tw-content);
  border-left-width: 1px;
}

.before\:border-t:before {
  content: var(--tw-content);
  border-top-width: 1px;
}

.before\:border-t-0:before {
  content: var(--tw-content);
  border-top-width: 0;
}

.before\:border-solid:before {
  content: var(--tw-content);
  border-style: solid;
}

.before\:border-dashed:before {
  content: var(--tw-content);
  border-style: dashed;
}

.before\:border-dotted:before {
  content: var(--tw-content);
  border-style: dotted;
}

.before\:border-gray-300:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}

.before\:border-transparent:before {
  content: var(--tw-content);
  border-color: rgba(0, 0, 0, 0);
}

.before\:border-b-white:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-bottom-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.before\:bg-inherit:before {
  content: var(--tw-content);
  background-color: inherit;
}

.before\:bg-white:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:bottom-0:after {
  content: var(--tw-content);
  bottom: 0;
}

.after\:left-0:after {
  content: var(--tw-content);
  left: 0;
}

.after\:left-full:after {
  content: var(--tw-content);
  left: 100%;
}

.after\:right-0:after {
  content: var(--tw-content);
  right: 0;
}

.after\:top-0:after {
  content: var(--tw-content);
  top: 0;
}

.after\:z-10:after {
  content: var(--tw-content);
  z-index: 10;
}

.after\:h-full:after {
  content: var(--tw-content);
  height: 100%;
}

.after\:w-full:after {
  content: var(--tw-content);
  width: 100%;
}

.after\:translate-x-full:after {
  content: var(--tw-content);
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:transform:after {
  content: var(--tw-content);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:bg-blue-400:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgba(118, 169, 250, var(--tw-bg-opacity));
}

.after\:bg-inherit:after {
  content: var(--tw-content);
  background-color: inherit;
}

.after\:bg-gradient-to-r:after {
  content: var(--tw-content);
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.after\:from-transparent:after {
  content: var(--tw-content);
  --tw-gradient-from: transparent var(--tw-gradient-from-position);
  --tw-gradient-to: rgba(0, 0, 0, 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.after\:via-white:after {
  content: var(--tw-content);
  --tw-gradient-to: rgba(255, 255, 255, 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fff var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.after\:to-transparent:after {
  content: var(--tw-content);
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
}

.after\:delay-1000:after {
  content: var(--tw-content);
  transition-delay: 1s;
}

.first\:mb-2:first-child {
  margin-bottom: .5rem;
}

.first\:ml-2:first-child {
  margin-left: .5rem;
}

.first\:mr-2:first-child {
  margin-right: .5rem;
}

.first\:mt-2:first-child {
  margin-top: .5rem;
}

.first\:rounded-l-md:first-child {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.first\:rounded-br-none:first-child {
  border-bottom-right-radius: 0;
}

.first\:rounded-tr-none:first-child {
  border-top-right-radius: 0;
}

.first\:border-l-0:first-child {
  border-left-width: 0;
}

.first\:pl-0:first-child {
  padding-left: 0;
}

.last\:mb-0:last-child {
  margin-bottom: 0;
}

.last\:mr-0:last-child {
  margin-right: 0;
}

.last\:rounded-r-md:last-child {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.last\:rounded-bl-lg:last-child {
  border-bottom-left-radius: .5rem;
}

.last\:rounded-bl-none:last-child {
  border-bottom-left-radius: 0;
}

.last\:rounded-br-lg:last-child {
  border-bottom-right-radius: .5rem;
}

.last\:rounded-tl-none:last-child {
  border-top-left-radius: 0;
}

.last\:border-r:last-child {
  border-right-width: 1px;
}

.hover\:mx-6:hover {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.hover\:my-6:hover {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.hover\:scale-150:hover {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:border:hover {
  border-width: 1px;
}

.hover\:border-blue-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(63, 131, 248, var(--tw-border-opacity));
}

.hover\:border-blue-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(28, 100, 242, var(--tw-border-opacity));
}

.hover\:border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}

.hover\:border-gray-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}

.hover\:border-gray-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}

.hover\:border-green-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(5, 122, 85, var(--tw-border-opacity));
}

.hover\:border-orange-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(208, 56, 1, var(--tw-border-opacity));
}

.hover\:border-purple-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(126, 58, 242, var(--tw-border-opacity));
}

.hover\:border-red-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(224, 36, 36, var(--tw-border-opacity));
}

.hover\:border-transparent:hover {
  border-color: rgba(0, 0, 0, 0);
}

.hover\:bg-black:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.hover\:bg-blue-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(195, 221, 253, var(--tw-bg-opacity));
}

.hover\:bg-blue-300\/20:hover {
  background-color: rgba(164, 202, 254, .2);
}

.hover\:bg-blue-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 131, 248, var(--tw-bg-opacity));
}

.hover\:bg-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(28, 100, 242, var(--tw-bg-opacity));
}

.hover\:bg-blue-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 66, 159, var(--tw-bg-opacity));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}

.hover\:bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}

.hover\:bg-gray-300\/20:hover {
  background-color: rgba(209, 213, 219, .2);
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.hover\:bg-gray-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}

.hover\:bg-gray-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}

.hover\:bg-green-300\/20:hover {
  background-color: rgba(132, 225, 188, .2);
}

.hover\:bg-green-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 122, 85, var(--tw-bg-opacity));
}

.hover\:bg-orange-300\/20:hover {
  background-color: rgba(253, 186, 140, .2);
}

.hover\:bg-orange-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(208, 56, 1, var(--tw-bg-opacity));
}

.hover\:bg-purple-300\/20:hover {
  background-color: rgba(202, 191, 253, .2);
}

.hover\:bg-purple-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(126, 58, 242, var(--tw-bg-opacity));
}

.hover\:bg-red-300\/20:hover {
  background-color: rgba(248, 180, 180, .2);
}

.hover\:bg-red-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 36, 36, var(--tw-bg-opacity));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.hover\:bg-white\/10:hover {
  background-color: rgba(255, 255, 255, .1);
}

.hover\:bg-white\/30:hover {
  background-color: rgba(255, 255, 255, .3);
}

.hover\:bg-opacity-50:hover {
  --tw-bg-opacity: .5;
}

.hover\:text-blue-400:hover {
  --tw-text-opacity: 1;
  color: rgba(118, 169, 250, var(--tw-text-opacity));
}

.hover\:text-blue-500:hover {
  --tw-text-opacity: 1;
  color: rgba(63, 131, 248, var(--tw-text-opacity));
}

.hover\:text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgba(28, 100, 242, var(--tw-text-opacity));
}

.hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.hover\:text-gray-800:hover {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}

.hover\:text-slate-700:hover {
  --tw-text-opacity: 1;
  color: rgba(51, 65, 85, var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.hover\:\!shadow-none:hover {
  --tw-shadow: 0 0 rgba(0, 0, 0, 0) !important;
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow) !important;
}

.hover\:transition-opacity:hover {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.hover\:duration-300:hover {
  transition-duration: .3s;
}

.focus\:border-blue-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(164, 202, 254, var(--tw-border-opacity));
}

.focus\:focus\:shadow-\[inset_0_0_0_0\.2rem_rgba\(191\,219\,254\,1\)\]:focus:focus {
  --tw-shadow: inset 0 0 0 .2rem #bfdbfe;
  --tw-shadow-colored: inset 0 0 0 .2rem var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.focus\:shadow-\[0_0_0_0\.2rem_rgba\(191\,219\,254\,1\)\]:focus {
  --tw-shadow: 0 0 0 .2rem #bfdbfe;
  --tw-shadow-colored: 0 0 0 .2rem var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.focus\:shadow-\[0_0_0_2px_rgba\(255\,255\,255\,1\)\,0_0_0_4px_rgba\(136\,234\,172\,1\)\,0_1px_2px_0_rgba\(0\,0\,0\,1\)\]:focus {
  --tw-shadow: 0 0 0 2px #fff, 0 0 0 4px #88eaac, 0 1px 2px 0 #000;
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color), 0 0 0 4px var(--tw-shadow-color), 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.focus\:shadow-\[0_0_0_2px_rgba\(255\,255\,255\,1\)\,0_0_0_4px_rgba\(157\,193\,251\,1\)\,0_1px_2px_0_rgba\(0\,0\,0\,1\)\]:focus {
  --tw-shadow: 0 0 0 2px #fff, 0 0 0 4px #9dc1fb, 0 1px 2px 0 #000;
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color), 0 0 0 4px var(--tw-shadow-color), 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.focus\:shadow-\[0_0_0_2px_rgba\(255\,255\,255\,1\)\,0_0_0_4px_rgba\(176\,185\,198\,1\)\,0_1px_2px_0_rgba\(0\,0\,0\,1\)\]:focus {
  --tw-shadow: 0 0 0 2px #fff, 0 0 0 4px #b0b9c6, 0 1px 2px 0 #000;
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color), 0 0 0 4px var(--tw-shadow-color), 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.focus\:shadow-\[0_0_0_2px_rgba\(255\,255\,255\,1\)\,0_0_0_4px_rgba\(212\,170\,251\,1\)\,0_1px_2px_0_rgba\(0\,0\,0\,1\)\]:focus {
  --tw-shadow: 0 0 0 2px #fff, 0 0 0 4px #d4aafb, 0 1px 2px 0 #000;
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color), 0 0 0 4px var(--tw-shadow-color), 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.focus\:shadow-\[0_0_0_2px_rgba\(255\,255\,255\,1\)\,0_0_0_4px_rgba\(247\,162\,162\,1\)\,0_1px_2px_0_rgba\(0\,0\,0\,1\)\]:focus {
  --tw-shadow: 0 0 0 2px #fff, 0 0 0 4px #f7a2a2, 0 1px 2px 0 #000;
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color), 0 0 0 4px var(--tw-shadow-color), 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.focus\:shadow-\[0_0_0_2px_rgba\(255\,255\,255\,1\)\,0_0_0_4px_rgba\(250\,207\,133\,1\)\,0_1px_2px_0_rgba\(0\,0\,0\,1\)\]:focus {
  --tw-shadow: 0 0 0 2px #fff, 0 0 0 4px #facf85, 0 1px 2px 0 #000;
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color), 0 0 0 4px var(--tw-shadow-color), 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.focus\:shadow-\[inset_0_0_0_0\.2rem_rgba\(191\,219\,254\,1\)\]:focus {
  --tw-shadow: inset 0 0 0 .2rem #bfdbfe;
  --tw-shadow-colored: inset 0 0 0 .2rem var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.focus\:shadow-none:focus {
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.focus\:outline:focus {
  outline-style: solid;
}

.focus\:outline-0:focus {
  outline-width: 0;
}

.focus\:outline-\[0\.15rem\]:focus {
  outline-width: .15rem;
}

.focus\:outline-offset-0:focus {
  outline-offset: 0px;
}

.focus\:outline-offset-\[-0\.15rem\]:focus {
  outline-offset: -.15rem;
}

.focus\:outline-blue-200:focus {
  outline-color: #c3ddfd;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.focus\:ring-4:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.focus\:ring-blue-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(164, 202, 254, var(--tw-ring-opacity));
}

.focus\:ring-gray-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
}

.focus\:ring-gray-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
}

:is(.dark .dark\:divide-gray-600) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-divide-opacity));
}

:is(.dark .dark\:border) {
  border-width: 1px;
}

:is(.dark .dark\:border-blue-300) {
  --tw-border-opacity: 1;
  border-color: rgba(164, 202, 254, var(--tw-border-opacity));
}

:is(.dark .dark\:border-blue-400) {
  --tw-border-opacity: 1;
  border-color: rgba(118, 169, 250, var(--tw-border-opacity));
}

:is(.dark .dark\:border-blue-500) {
  --tw-border-opacity: 1;
  border-color: rgba(63, 131, 248, var(--tw-border-opacity));
}

:is(.dark .dark\:border-blue-900\/40) {
  border-color: rgba(35, 56, 118, .4);
}

:is(.dark .dark\:border-gray-400) {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}

:is(.dark .dark\:border-gray-600) {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}

:is(.dark .dark\:border-gray-700) {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}

:is(.dark .dark\:border-gray-900) {
  --tw-border-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity));
}

:is(.dark .dark\:border-green-400) {
  --tw-border-opacity: 1;
  border-color: rgba(49, 196, 141, var(--tw-border-opacity));
}

:is(.dark .dark\:border-orange-400) {
  --tw-border-opacity: 1;
  border-color: rgba(255, 138, 76, var(--tw-border-opacity));
}

:is(.dark .dark\:border-purple-400) {
  --tw-border-opacity: 1;
  border-color: rgba(172, 148, 250, var(--tw-border-opacity));
}

:is(.dark .dark\:border-red-400) {
  --tw-border-opacity: 1;
  border-color: rgba(249, 128, 128, var(--tw-border-opacity));
}

:is(.dark .dark\:border-sky-300) {
  --tw-border-opacity: 1;
  border-color: rgba(125, 211, 252, var(--tw-border-opacity));
}

:is(.dark .dark\:border-transparent) {
  border-color: rgba(0, 0, 0, 0);
}

:is(.dark .dark\:bg-blue-300) {
  --tw-bg-opacity: 1;
  background-color: rgba(164, 202, 254, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-blue-300\/20) {
  background-color: rgba(164, 202, 254, .2);
}

:is(.dark .dark\:bg-blue-400) {
  --tw-bg-opacity: 1;
  background-color: rgba(118, 169, 250, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-blue-500\/40) {
  background-color: rgba(63, 131, 248, .4);
}

:is(.dark .dark\:bg-blue-600) {
  --tw-bg-opacity: 1;
  background-color: rgba(28, 100, 242, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-blue-900\/40) {
  background-color: rgba(35, 56, 118, .4);
}

:is(.dark .dark\:bg-gray-400) {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-600) {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-700) {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-800) {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-800\/50) {
  background-color: rgba(31, 41, 55, .5);
}

:is(.dark .dark\:bg-gray-800\/90) {
  background-color: rgba(31, 41, 55, .9);
}

:is(.dark .dark\:bg-gray-900) {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-950) {
  --tw-bg-opacity: 1;
  background-color: rgba(3, 7, 18, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-950\/40) {
  background-color: rgba(3, 7, 18, .4);
}

:is(.dark .dark\:bg-green-400) {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 196, 141, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-orange-400) {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 138, 76, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-purple-400) {
  --tw-bg-opacity: 1;
  background-color: rgba(172, 148, 250, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-red-400) {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 128, 128, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-sky-300) {
  --tw-bg-opacity: 1;
  background-color: rgba(125, 211, 252, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-transparent) {
  background-color: rgba(0, 0, 0, 0);
}

:is(.dark .dark\:bg-opacity-80) {
  --tw-bg-opacity: .8;
}

:is(.dark .dark\:stroke-gray-700) {
  stroke: #374151;
}

:is(.dark .dark\:text-blue-300) {
  --tw-text-opacity: 1;
  color: rgba(164, 202, 254, var(--tw-text-opacity));
}

:is(.dark .dark\:text-blue-400) {
  --tw-text-opacity: 1;
  color: rgba(118, 169, 250, var(--tw-text-opacity));
}

:is(.dark .dark\:text-blue-500) {
  --tw-text-opacity: 1;
  color: rgba(63, 131, 248, var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-200) {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-300) {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-400) {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-900) {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}

:is(.dark .dark\:text-green-400) {
  --tw-text-opacity: 1;
  color: rgba(49, 196, 141, var(--tw-text-opacity));
}

:is(.dark .dark\:text-orange-400) {
  --tw-text-opacity: 1;
  color: rgba(255, 138, 76, var(--tw-text-opacity));
}

:is(.dark .dark\:text-purple-400) {
  --tw-text-opacity: 1;
  color: rgba(172, 148, 250, var(--tw-text-opacity));
}

:is(.dark .dark\:text-red-400) {
  --tw-text-opacity: 1;
  color: rgba(249, 128, 128, var(--tw-text-opacity));
}

:is(.dark .dark\:text-white) {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

:is(.dark .dark\:text-white\/60) {
  color: rgba(255, 255, 255, .6);
}

:is(.dark .dark\:text-white\/70) {
  color: rgba(255, 255, 255, .7);
}

:is(.dark .dark\:text-white\/80) {
  color: rgba(255, 255, 255, .8);
}

:is(.dark .dark\:placeholder-gray-400)::-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}

:is(.dark .dark\:placeholder-gray-400)::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}

:is(.dark .dark\:shadow-\[0_0_0_0\.2rem_rgba\(147\,197\,253\,0\.5\)\]) {
  --tw-shadow: 0 0 0 .2rem rgba(147, 197, 253, .5);
  --tw-shadow-colored: 0 0 0 .2rem var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

:is(.dark .before\:dark\:border-blue-900\/40):before {
  content: var(--tw-content);
  border-color: rgba(35, 56, 118, .4);
}

:is(.dark .dark\:before\:border-b-gray-900):before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-bottom-color: rgba(17, 24, 39, var(--tw-border-opacity));
}

:is(.dark .before\:dark\:bg-gray-900):before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:border-blue-300:hover) {
  --tw-border-opacity: 1;
  border-color: rgba(164, 202, 254, var(--tw-border-opacity));
}

:is(.dark .dark\:hover\:border-blue-400:hover) {
  --tw-border-opacity: 1;
  border-color: rgba(118, 169, 250, var(--tw-border-opacity));
}

:is(.dark .dark\:hover\:border-blue-500:hover) {
  --tw-border-opacity: 1;
  border-color: rgba(63, 131, 248, var(--tw-border-opacity));
}

:is(.dark .dark\:hover\:border-gray-500:hover) {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}

:is(.dark .dark\:hover\:border-green-500:hover) {
  --tw-border-opacity: 1;
  border-color: rgba(14, 159, 110, var(--tw-border-opacity));
}

:is(.dark .dark\:hover\:border-orange-500:hover) {
  --tw-border-opacity: 1;
  border-color: rgba(255, 90, 31, var(--tw-border-opacity));
}

:is(.dark .dark\:hover\:border-purple-500:hover) {
  --tw-border-opacity: 1;
  border-color: rgba(144, 97, 249, var(--tw-border-opacity));
}

:is(.dark .dark\:hover\:border-red-500:hover) {
  --tw-border-opacity: 1;
  border-color: rgba(240, 82, 82, var(--tw-border-opacity));
}

:is(.dark .dark\:hover\:border-transparent:hover) {
  border-color: rgba(0, 0, 0, 0);
}

:is(.dark .dark\:hover\:bg-blue-500:hover) {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 131, 248, var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-blue-700:hover) {
  --tw-bg-opacity: 1;
  background-color: rgba(26, 86, 219, var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-gray-500:hover) {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-gray-600:hover) {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-gray-700:hover) {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-gray-800:hover) {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-gray-800\/60:hover) {
  background-color: rgba(31, 41, 55, .6);
}

:is(.dark .dark\:hover\:bg-gray-800\/70:hover) {
  background-color: rgba(31, 41, 55, .7);
}

:is(.dark .dark\:hover\:bg-gray-800\/80:hover) {
  background-color: rgba(31, 41, 55, .8);
}

:is(.dark .dark\:hover\:bg-gray-950:hover) {
  --tw-bg-opacity: 1;
  background-color: rgba(3, 7, 18, var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-green-500:hover) {
  --tw-bg-opacity: 1;
  background-color: rgba(14, 159, 110, var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-orange-500:hover) {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 90, 31, var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-purple-500:hover) {
  --tw-bg-opacity: 1;
  background-color: rgba(144, 97, 249, var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-red-500:hover) {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 82, 82, var(--tw-bg-opacity));
}

:is(.dark .hover\:dark\:bg-gray-700):hover {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:text-blue-500:hover) {
  --tw-text-opacity: 1;
  color: rgba(63, 131, 248, var(--tw-text-opacity));
}

:is(.dark .dark\:hover\:text-gray-300:hover) {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}

:is(.dark .dark\:hover\:text-white:hover) {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

:is(.dark .dark\:hover\:text-white\/70:hover) {
  color: rgba(255, 255, 255, .7);
}

:is(.dark .dark\:hover\:text-white\/80:hover) {
  color: rgba(255, 255, 255, .8);
}

:is(.dark .dark\:focus\:border-blue-500:focus) {
  --tw-border-opacity: 1;
  border-color: rgba(63, 131, 248, var(--tw-border-opacity));
}

:is(.dark .dark\:focus\:shadow-\[0_0_0_0\.2rem_rgba\(147\,197\,253\,0\.5\)\]:focus) {
  --tw-shadow: 0 0 0 .2rem rgba(147, 197, 253, .5);
  --tw-shadow-colored: 0 0 0 .2rem var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

:is(.dark .dark\:focus\:shadow-\[0_0_0_2px_rgba\(28\,33\,39\,1\)\,0_0_0_4px_rgba\(134\,239\,172\,0\.7\)\,0_1px_2px_0_rgba\(0\,0\,0\,0\)\]:focus) {
  --tw-shadow: 0 0 0 2px #1c2127, 0 0 0 4px rgba(134, 239, 172, .7), 0 1px 2px 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color), 0 0 0 4px var(--tw-shadow-color), 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

:is(.dark .dark\:focus\:shadow-\[0_0_0_2px_rgba\(28\,33\,39\,1\)\,0_0_0_4px_rgba\(147\,197\,253\,0\.7\)\,0_1px_2px_0_rgba\(0\,0\,0\,0\)\]:focus) {
  --tw-shadow: 0 0 0 2px #1c2127, 0 0 0 4px rgba(147, 197, 253, .7), 0 1px 2px 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color), 0 0 0 4px var(--tw-shadow-color), 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

:is(.dark .dark\:focus\:shadow-\[0_0_0_2px_rgba\(28\,33\,39\,1\)\,0_0_0_4px_rgba\(203\,213\,225\,0\.7\)\,0_1px_2px_0_rgba\(0\,0\,0\,0\)\]:focus) {
  --tw-shadow: 0 0 0 2px #1c2127, 0 0 0 4px rgba(203, 213, 225, .7), 0 1px 2px 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color), 0 0 0 4px var(--tw-shadow-color), 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

:is(.dark .dark\:focus\:shadow-\[0_0_0_2px_rgba\(28\,33\,39\,1\)\,0_0_0_4px_rgba\(216\,180\,254\,0\.7\)\,0_1px_2px_0_rgba\(0\,0\,0\,0\)\]:focus) {
  --tw-shadow: 0 0 0 2px #1c2127, 0 0 0 4px rgba(216, 180, 254, .7), 0 1px 2px 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color), 0 0 0 4px var(--tw-shadow-color), 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

:is(.dark .dark\:focus\:shadow-\[0_0_0_2px_rgba\(28\,33\,39\,1\)\,0_0_0_4px_rgba\(252\,165\,165\,0\.7\)\,0_1px_2px_0_rgba\(0\,0\,0\,0\)\]:focus) {
  --tw-shadow: 0 0 0 2px #1c2127, 0 0 0 4px rgba(252, 165, 165, .7), 0 1px 2px 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color), 0 0 0 4px var(--tw-shadow-color), 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

:is(.dark .dark\:focus\:shadow-\[0_0_0_2px_rgba\(28\,33\,39\,1\)\,0_0_0_4px_rgba\(252\,211\,77\,0\.7\)\,0_1px_2px_0_rgba\(0\,0\,0\,0\)\]:focus) {
  --tw-shadow: 0 0 0 2px #1c2127, 0 0 0 4px rgba(252, 211, 77, .7), 0 1px 2px 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color), 0 0 0 4px var(--tw-shadow-color), 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

:is(.dark .dark\:focus\:shadow-\[inset_0_0_0_0\.2rem_rgba\(147\,197\,253\,0\.5\)\]:focus) {
  --tw-shadow: inset 0 0 0 .2rem rgba(147, 197, 253, .5);
  --tw-shadow-colored: inset 0 0 0 .2rem var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

:is(.dark .dark\:focus\:outline:focus) {
  outline-style: solid;
}

:is(.dark .dark\:focus\:outline-\[0\.15rem\]:focus) {
  outline-width: .15rem;
}

:is(.dark .dark\:focus\:outline-offset-\[-0\.15rem\]:focus) {
  outline-offset: -.15rem;
}

:is(.dark .dark\:focus\:outline-blue-300:focus) {
  outline-color: #a4cafe;
}

:is(.dark .dark\:focus\:ring-blue-500:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(63, 131, 248, var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-gray-600:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
}

@media (min-width: 0) {
  .min-\[0px\]\:flex {
    display: -ms-flexbox;
    display: flex;
  }

  .min-\[0px\]\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

@media (min-width: 640px) {
  .sm\:absolute {
    position: absolute;
  }

  .sm\:relative {
    position: relative;
  }

  .sm\:left-auto {
    left: auto;
  }

  .sm\:left-full {
    left: 100%;
  }

  .sm\:top-0 {
    top: 0;
  }

  .sm\:top-auto {
    top: auto;
  }

  .sm\:ml-2 {
    margin-left: .5rem;
  }

  .sm\:ml-64 {
    margin-left: 16rem;
  }

  .sm\:flex {
    display: -ms-flexbox;
    display: flex;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:h-7 {
    height: 1.75rem;
  }

  .sm\:w-48 {
    width: 12rem;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:max-w-md {
    max-width: 28rem;
  }

  .sm\:translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:flex-row {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .sm\:bg-transparent {
    background-color: rgba(0, 0, 0, 0);
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:p-8 {
    padding: 2rem;
  }

  .sm\:pl-5 {
    padding-left: 1.25rem;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .sm\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  }

  .sm\:shadow-none {
    --tw-shadow: 0 0 rgba(0, 0, 0, 0);
    --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  }
}

@media (min-width: 768px) {
  .md\:order-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .md\:mr-0 {
    margin-right: 0;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: -ms-flexbox;
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-80 {
    height: 20rem;
  }

  .md\:h-screen {
    height: 100vh;
  }

  .md\:\!w-\[30vw\] {
    width: 30vw !important;
  }

  .md\:\!w-\[45vw\] {
    width: 45vw !important;
  }

  .md\:w-56 {
    width: 14rem;
  }

  .md\:w-80 {
    width: 20rem;
  }

  .md\:w-\[25rem\] {
    width: 25rem;
  }

  .md\:w-\[35vw\] {
    width: 35vw;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:flex-row {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .md\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }

  .md\:p-8 {
    padding: 2rem;
  }

  .md\:\!px-7 {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

@media (min-width: 1024px) {
  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (min-width: 1280px) {
  .xl\:mb-2 {
    margin-bottom: .5rem;
  }

  .xl\:flex-row {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .xl\:flex-col {
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .xl\:p-0 {
    padding: 0;
  }
}

.\[\&\:\:-webkit-scrollbar\]\:hidden::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("nunito-cyrillic-ext-600-normal.f8407694.woff2") format("woff2"), url("nunito-cyrillic-ext-600-normal.f5477601.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("nunito-cyrillic-600-normal.88426fec.woff2") format("woff2"), url("nunito-cyrillic-600-normal.ef6a3ed4.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("nunito-vietnamese-600-normal.55aa5af3.woff2") format("woff2"), url("nunito-vietnamese-600-normal.41085c6f.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("nunito-latin-ext-600-normal.c0296ce5.woff2") format("woff2"), url("nunito-latin-ext-600-normal.82318356.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("nunito-latin-600-normal.8187a66e.woff2") format("woff2"), url("nunito-latin-600-normal.61dc9548.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: primeicons;
  font-display: block;
  src: url("primeicons.81e5fde4.eot");
  src: url("primeicons.81e5fde4.eot#iefix") format("embedded-opentype"), url("primeicons.bb5567ec.woff2") format("woff2"), url("primeicons.51e61582.woff") format("woff"), url("primeicons.05540d0d.ttf") format("truetype"), url("primeicons.34fb6b01.svg#primeicons") format("svg");
  font-weight: normal;
  font-style: normal;
}

.pi {
  speak: none;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: primeicons;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

.pi:before {
  --webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.pi-fw {
  text-align: center;
  width: 1.28571em;
}

.pi-spin {
  animation: 2s linear infinite fa-spin;
}

@keyframes fa-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(359deg);
  }
}

.pi-eraser:before {
  content: "";
}

.pi-stopwatch:before {
  content: "";
}

.pi-verified:before {
  content: "";
}

.pi-delete-left:before {
  content: "";
}

.pi-hourglass:before {
  content: "";
}

.pi-truck:before {
  content: "";
}

.pi-wrench:before {
  content: "";
}

.pi-microphone:before {
  content: "";
}

.pi-megaphone:before {
  content: "";
}

.pi-arrow-right-arrow-left:before {
  content: "";
}

.pi-bitcoin:before {
  content: "";
}

.pi-file-edit:before {
  content: "";
}

.pi-language:before {
  content: "";
}

.pi-file-export:before {
  content: "";
}

.pi-file-import:before {
  content: "";
}

.pi-file-word:before {
  content: "";
}

.pi-gift:before {
  content: "";
}

.pi-cart-plus:before {
  content: "";
}

.pi-thumbs-down-fill:before {
  content: "";
}

.pi-thumbs-up-fill:before {
  content: "";
}

.pi-arrows-alt:before {
  content: "";
}

.pi-calculator:before {
  content: "";
}

.pi-sort-alt-slash:before {
  content: "";
}

.pi-arrows-h:before {
  content: "";
}

.pi-arrows-v:before {
  content: "";
}

.pi-pound:before {
  content: "";
}

.pi-prime:before {
  content: "";
}

.pi-chart-pie:before {
  content: "";
}

.pi-reddit:before {
  content: "";
}

.pi-code:before {
  content: "";
}

.pi-sync:before {
  content: "";
}

.pi-shopping-bag:before {
  content: "";
}

.pi-server:before {
  content: "";
}

.pi-database:before {
  content: "";
}

.pi-hashtag:before {
  content: "";
}

.pi-bookmark-fill:before {
  content: "";
}

.pi-filter-fill:before {
  content: "";
}

.pi-heart-fill:before {
  content: "";
}

.pi-flag-fill:before {
  content: "";
}

.pi-circle:before {
  content: "";
}

.pi-circle-fill:before {
  content: "";
}

.pi-bolt:before {
  content: "";
}

.pi-history:before {
  content: "";
}

.pi-box:before {
  content: "";
}

.pi-at:before {
  content: "";
}

.pi-arrow-up-right:before {
  content: "";
}

.pi-arrow-up-left:before {
  content: "";
}

.pi-arrow-down-left:before {
  content: "";
}

.pi-arrow-down-right:before {
  content: "";
}

.pi-telegram:before {
  content: "";
}

.pi-stop-circle:before {
  content: "";
}

.pi-stop:before {
  content: "";
}

.pi-whatsapp:before {
  content: "";
}

.pi-building:before {
  content: "";
}

.pi-qrcode:before {
  content: "";
}

.pi-car:before {
  content: "";
}

.pi-instagram:before {
  content: "";
}

.pi-linkedin:before {
  content: "";
}

.pi-send:before {
  content: "";
}

.pi-slack:before {
  content: "";
}

.pi-sun:before {
  content: "";
}

.pi-moon:before {
  content: "";
}

.pi-vimeo:before {
  content: "";
}

.pi-youtube:before {
  content: "";
}

.pi-flag:before {
  content: "";
}

.pi-wallet:before {
  content: "";
}

.pi-map:before {
  content: "";
}

.pi-link:before {
  content: "";
}

.pi-credit-card:before {
  content: "";
}

.pi-discord:before {
  content: "";
}

.pi-percentage:before {
  content: "";
}

.pi-euro:before {
  content: "";
}

.pi-book:before {
  content: "";
}

.pi-shield:before {
  content: "";
}

.pi-paypal:before {
  content: "";
}

.pi-amazon:before {
  content: "";
}

.pi-phone:before {
  content: "";
}

.pi-filter-slash:before {
  content: "";
}

.pi-facebook:before {
  content: "";
}

.pi-github:before {
  content: "";
}

.pi-twitter:before {
  content: "";
}

.pi-step-backward-alt:before {
  content: "";
}

.pi-step-forward-alt:before {
  content: "";
}

.pi-forward:before {
  content: "";
}

.pi-backward:before {
  content: "";
}

.pi-fast-backward:before {
  content: "";
}

.pi-fast-forward:before {
  content: "";
}

.pi-pause:before {
  content: "";
}

.pi-play:before {
  content: "";
}

.pi-compass:before {
  content: "";
}

.pi-id-card:before {
  content: "";
}

.pi-ticket:before {
  content: "";
}

.pi-file-o:before {
  content: "";
}

.pi-reply:before {
  content: "";
}

.pi-directions-alt:before {
  content: "";
}

.pi-directions:before {
  content: "";
}

.pi-thumbs-up:before {
  content: "";
}

.pi-thumbs-down:before {
  content: "";
}

.pi-sort-numeric-down-alt:before {
  content: "";
}

.pi-sort-numeric-up-alt:before {
  content: "";
}

.pi-sort-alpha-down-alt:before {
  content: "";
}

.pi-sort-alpha-up-alt:before {
  content: "";
}

.pi-sort-numeric-down:before {
  content: "";
}

.pi-sort-numeric-up:before {
  content: "";
}

.pi-sort-alpha-down:before {
  content: "";
}

.pi-sort-alpha-up:before {
  content: "";
}

.pi-sort-alt:before {
  content: "";
}

.pi-sort-amount-up:before {
  content: "";
}

.pi-sort-amount-down:before {
  content: "";
}

.pi-sort-amount-down-alt:before {
  content: "";
}

.pi-sort-amount-up-alt:before {
  content: "";
}

.pi-palette:before {
  content: "";
}

.pi-undo:before {
  content: "";
}

.pi-desktop:before {
  content: "";
}

.pi-sliders-v:before {
  content: "";
}

.pi-sliders-h:before {
  content: "";
}

.pi-search-plus:before {
  content: "";
}

.pi-search-minus:before {
  content: "";
}

.pi-file-excel:before {
  content: "";
}

.pi-file-pdf:before {
  content: "";
}

.pi-check-square:before {
  content: "";
}

.pi-chart-line:before {
  content: "";
}

.pi-user-edit:before {
  content: "";
}

.pi-exclamation-circle:before {
  content: "";
}

.pi-android:before {
  content: "";
}

.pi-google:before {
  content: "";
}

.pi-apple:before {
  content: "";
}

.pi-microsoft:before {
  content: "";
}

.pi-heart:before {
  content: "";
}

.pi-mobile:before {
  content: "";
}

.pi-tablet:before {
  content: "";
}

.pi-key:before {
  content: "";
}

.pi-shopping-cart:before {
  content: "";
}

.pi-comments:before {
  content: "";
}

.pi-comment:before {
  content: "";
}

.pi-briefcase:before {
  content: "";
}

.pi-bell:before {
  content: "";
}

.pi-paperclip:before {
  content: "";
}

.pi-share-alt:before {
  content: "";
}

.pi-envelope:before {
  content: "";
}

.pi-volume-down:before {
  content: "";
}

.pi-volume-up:before {
  content: "";
}

.pi-volume-off:before {
  content: "";
}

.pi-eject:before {
  content: "";
}

.pi-money-bill:before {
  content: "";
}

.pi-images:before {
  content: "";
}

.pi-image:before {
  content: "";
}

.pi-sign-in:before {
  content: "";
}

.pi-sign-out:before {
  content: "";
}

.pi-wifi:before {
  content: "";
}

.pi-sitemap:before {
  content: "";
}

.pi-chart-bar:before {
  content: "";
}

.pi-camera:before {
  content: "";
}

.pi-dollar:before {
  content: "";
}

.pi-lock-open:before {
  content: "";
}

.pi-table:before {
  content: "";
}

.pi-map-marker:before {
  content: "";
}

.pi-list:before {
  content: "";
}

.pi-eye-slash:before {
  content: "";
}

.pi-eye:before {
  content: "";
}

.pi-folder-open:before {
  content: "";
}

.pi-folder:before {
  content: "";
}

.pi-video:before {
  content: "";
}

.pi-inbox:before {
  content: "";
}

.pi-lock:before {
  content: "";
}

.pi-unlock:before {
  content: "";
}

.pi-tags:before {
  content: "";
}

.pi-tag:before {
  content: "";
}

.pi-power-off:before {
  content: "";
}

.pi-save:before {
  content: "";
}

.pi-question-circle:before {
  content: "";
}

.pi-question:before {
  content: "";
}

.pi-copy:before {
  content: "";
}

.pi-file:before {
  content: "";
}

.pi-clone:before {
  content: "";
}

.pi-calendar-times:before {
  content: "";
}

.pi-calendar-minus:before {
  content: "";
}

.pi-calendar-plus:before {
  content: "";
}

.pi-ellipsis-v:before {
  content: "";
}

.pi-ellipsis-h:before {
  content: "";
}

.pi-bookmark:before {
  content: "";
}

.pi-globe:before {
  content: "";
}

.pi-replay:before {
  content: "";
}

.pi-filter:before {
  content: "";
}

.pi-print:before {
  content: "";
}

.pi-align-right:before {
  content: "";
}

.pi-align-left:before {
  content: "";
}

.pi-align-center:before {
  content: "";
}

.pi-align-justify:before {
  content: "";
}

.pi-cog:before {
  content: "";
}

.pi-cloud-download:before {
  content: "";
}

.pi-cloud-upload:before {
  content: "";
}

.pi-cloud:before {
  content: "";
}

.pi-pencil:before {
  content: "";
}

.pi-users:before {
  content: "";
}

.pi-clock:before {
  content: "";
}

.pi-user-minus:before {
  content: "";
}

.pi-user-plus:before {
  content: "";
}

.pi-trash:before {
  content: "";
}

.pi-external-link:before {
  content: "";
}

.pi-window-maximize:before {
  content: "";
}

.pi-window-minimize:before {
  content: "";
}

.pi-refresh:before {
  content: "";
}

.pi-user:before {
  content: "";
}

.pi-exclamation-triangle:before {
  content: "";
}

.pi-calendar:before {
  content: "";
}

.pi-chevron-circle-left:before {
  content: "";
}

.pi-chevron-circle-down:before {
  content: "";
}

.pi-chevron-circle-right:before {
  content: "";
}

.pi-chevron-circle-up:before {
  content: "";
}

.pi-angle-double-down:before {
  content: "";
}

.pi-angle-double-left:before {
  content: "";
}

.pi-angle-double-right:before {
  content: "";
}

.pi-angle-double-up:before {
  content: "";
}

.pi-angle-down:before {
  content: "";
}

.pi-angle-left:before {
  content: "";
}

.pi-angle-right:before {
  content: "";
}

.pi-angle-up:before {
  content: "";
}

.pi-upload:before {
  content: "";
}

.pi-download:before {
  content: "";
}

.pi-ban:before {
  content: "";
}

.pi-star-fill:before {
  content: "";
}

.pi-star:before {
  content: "";
}

.pi-chevron-left:before {
  content: "";
}

.pi-chevron-right:before {
  content: "";
}

.pi-chevron-down:before {
  content: "";
}

.pi-chevron-up:before {
  content: "";
}

.pi-caret-left:before {
  content: "";
}

.pi-caret-right:before {
  content: "";
}

.pi-caret-down:before {
  content: "";
}

.pi-caret-up:before {
  content: "";
}

.pi-search:before {
  content: "";
}

.pi-check:before {
  content: "";
}

.pi-check-circle:before {
  content: "";
}

.pi-times:before {
  content: "";
}

.pi-times-circle:before {
  content: "";
}

.pi-plus:before {
  content: "";
}

.pi-plus-circle:before {
  content: "";
}

.pi-minus:before {
  content: "";
}

.pi-minus-circle:before {
  content: "";
}

.pi-circle-on:before {
  content: "";
}

.pi-circle-off:before {
  content: "";
}

.pi-sort-down:before {
  content: "";
}

.pi-sort-up:before {
  content: "";
}

.pi-sort:before {
  content: "";
}

.pi-step-backward:before {
  content: "";
}

.pi-step-forward:before {
  content: "";
}

.pi-th-large:before {
  content: "";
}

.pi-arrow-down:before {
  content: "";
}

.pi-arrow-left:before {
  content: "";
}

.pi-arrow-right:before {
  content: "";
}

.pi-arrow-up:before {
  content: "";
}

.pi-bars:before {
  content: "";
}

.pi-arrow-circle-down:before {
  content: "";
}

.pi-arrow-circle-left:before {
  content: "";
}

.pi-arrow-circle-right:before {
  content: "";
}

.pi-arrow-circle-up:before {
  content: "";
}

.pi-info:before {
  content: "";
}

.pi-info-circle:before {
  content: "";
}

.pi-home:before {
  content: "";
}

.pi-spinner:before {
  content: "";
}



:root {
  --font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --surface-a: #fff;
  --surface-b: #fafafa;
  --surface-c: #f4f4f5;
  --surface-d: #e5e7eb;
  --surface-e: #fff;
  --surface-f: #fff;
  --text-color: #3f3f46;
  --text-color-secondary: #71717a;
  --primary-color: #4f46e5;
  --primary-color-text: #fff;
  --surface-0: #fff;
  --surface-50: #fafafa;
  --surface-100: #f4f4f5;
  --surface-200: #e4e4e7;
  --surface-300: #d4d4d8;
  --surface-400: #a1a1aa;
  --surface-500: #71717a;
  --surface-600: #52525b;
  --surface-700: #3f3f46;
  --surface-800: #27272a;
  --surface-900: #18181b;
  --gray-50: #fafafa;
  --gray-100: #f4f4f5;
  --gray-200: #e4e4e7;
  --gray-300: #d4d4d8;
  --gray-400: #a1a1aa;
  --gray-500: #71717a;
  --gray-600: #52525b;
  --gray-700: #3f3f46;
  --gray-800: #27272a;
  --gray-900: #18181b;
  --content-padding: 1.25rem;
  --inline-spacing: .5rem;
  --border-radius: .375rem;
  --surface-ground: #fafafa;
  --surface-section: #fff;
  --surface-card: #fff;
  --surface-overlay: #fff;
  --surface-border: #e5e7eb;
  --surface-hover: #f4f4f5;
  --focus-ring: 0 0 0 1px #6366f1;
  --maskbg: rgba(0, 0, 0, .4);
  --highlight-bg: #eef2ff;
  --highlight-text-color: #312e81;
  color-scheme: light;
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  src: local(""), url("Inter-Light.c06f5e9d.woff2") format("woff2"), url("Inter-Light.5feaf686.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("Inter-Regular.449f0e8a.woff2") format("woff2"), url("Inter-Regular.e79139c9.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  src: local(""), url("Inter-Medium.acc3d50f.woff2") format("woff2"), url("Inter-Medium.cfcc1d28.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  src: local(""), url("Inter-SemiBold.bdb1c7ad.woff2") format("woff2"), url("Inter-SemiBold.fa7f9547.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  src: local(""), url("Inter-Bold.53ea85ae.woff2") format("woff2"), url("Inter-Bold.d03e3d36.woff") format("woff");
}

:root {
  --blue-50: #f5f9ff;
  --blue-100: #d0e1fd;
  --blue-200: #abc9fb;
  --blue-300: #85b2f9;
  --blue-400: #609af8;
  --blue-500: #3b82f6;
  --blue-600: #326fd1;
  --blue-700: #295bac;
  --blue-800: #204887;
  --blue-900: #183462;
  --green-50: #f4fcf7;
  --green-100: #caf1d8;
  --green-200: #a0e6ba;
  --green-300: #76db9b;
  --green-400: #4cd07d;
  --green-500: #22c55e;
  --green-600: #1da750;
  --green-700: #188a42;
  --green-800: #136c34;
  --green-900: #0e4f26;
  --yellow-50: #fefbf3;
  --yellow-100: #faedc4;
  --yellow-200: #f6de95;
  --yellow-300: #f2d066;
  --yellow-400: #eec137;
  --yellow-500: #eab308;
  --yellow-600: #c79807;
  --yellow-700: #a47d06;
  --yellow-800: #816204;
  --yellow-900: #5e4803;
  --cyan-50: #f3fbfd;
  --cyan-100: #c3edf5;
  --cyan-200: #94e0ed;
  --cyan-300: #65d2e4;
  --cyan-400: #35c4dc;
  --cyan-500: #06b6d4;
  --cyan-600: #059bb4;
  --cyan-700: #047f94;
  --cyan-800: #036475;
  --cyan-900: #024955;
  --pink-50: #fef6fa;
  --pink-100: #fad3e7;
  --pink-200: #f7b0d3;
  --pink-300: #f38ec0;
  --pink-400: #f06bac;
  --pink-500: #ec4899;
  --pink-600: #c93d82;
  --pink-700: #a5326b;
  --pink-800: #822854;
  --pink-900: #5e1d3d;
  --indigo-50: #f7f7fe;
  --indigo-100: #dadafc;
  --indigo-200: #bcbdf9;
  --indigo-300: #9ea0f6;
  --indigo-400: #8183f4;
  --indigo-500: #6366f1;
  --indigo-600: #5457cd;
  --indigo-700: #4547a9;
  --indigo-800: #363885;
  --indigo-900: #282960;
  --teal-50: #f3fbfb;
  --teal-100: #c7eeea;
  --teal-200: #9ae0d9;
  --teal-300: #6dd3c8;
  --teal-400: #41c5b7;
  --teal-500: #14b8a6;
  --teal-600: #119c8d;
  --teal-700: #0e8174;
  --teal-800: #0b655b;
  --teal-900: #084a42;
  --orange-50: #fff8f3;
  --orange-100: #feddc7;
  --orange-200: #fcc39b;
  --orange-300: #fba86f;
  --orange-400: #fa8e42;
  --orange-500: #f97316;
  --orange-600: #d46213;
  --orange-700: #ae510f;
  --orange-800: #893f0c;
  --orange-900: #642e09;
  --bluegray-50: #f7f8f9;
  --bluegray-100: #dadee3;
  --bluegray-200: #bcc3cd;
  --bluegray-300: #9fa9b7;
  --bluegray-400: #818ea1;
  --bluegray-500: #64748b;
  --bluegray-600: #556376;
  --bluegray-700: #465161;
  --bluegray-800: #37404c;
  --bluegray-900: #282e38;
  --purple-50: #fbf7ff;
  --purple-100: #ead6fd;
  --purple-200: #dab6fc;
  --purple-300: #c996fa;
  --purple-400: #b975f9;
  --purple-500: #a855f7;
  --purple-600: #8f48d2;
  --purple-700: #763cad;
  --purple-800: #5c2f88;
  --purple-900: #432263;
  --red-50: #fef6f6;
  --red-100: #fbd2d2;
  --red-200: #f8afaf;
  --red-300: #f58b8b;
  --red-400: #f26868;
  --red-500: #ef4444;
  --red-600: #cb3a3a;
  --red-700: #a73030;
  --red-800: #832525;
  --red-900: #601b1b;
  --primary-50: #f6f6fe;
  --primary-100: #d5d3f9;
  --primary-200: #b3aff4;
  --primary-300: #928cef;
  --primary-400: #7069ea;
  --primary-500: #4f46e5;
  --primary-600: #433cc3;
  --primary-700: #3731a0;
  --primary-800: #2b277e;
  --primary-900: #201c5c;
}

.p-editor-container .p-editor-toolbar {
  background: #fafafa;
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
}

.p-editor-container .p-editor-toolbar.ql-snow {
  border: 1px solid #e5e7eb;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
  stroke: #71717a;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
  fill: #71717a;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
  color: #71717a;
  border: 0;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
  color: #3f3f46;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
  stroke: #3f3f46;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
  fill: #3f3f46;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #3f3f46;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #3f3f46;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #3f3f46;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: #fff;
  border: 0;
  border-radius: .375rem;
  padding: .25rem 0;
  box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
  color: #3f3f46;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
  color: #18181b;
  background: #f4f4f5;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
  padding: .75rem 1rem;
}

.p-editor-container .p-editor-content {
  border-bottom-right-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.p-editor-container .p-editor-content.ql-snow {
  border: 1px solid #e5e7eb;
}

.p-editor-container .p-editor-content .ql-editor {
  color: #3f3f46;
  background: #fff;
  border-bottom-right-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.p-editor-container .ql-snow.ql-toolbar button:hover, .p-editor-container .ql-snow.ql-toolbar button:focus {
  color: #3f3f46;
}

.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke, .p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
  stroke: #3f3f46;
}

.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill, .p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
  fill: #3f3f46;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active, .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active, .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: #4f46e5;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke, .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
  stroke: #4f46e5;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill, .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill, .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
  fill: #4f46e5;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label, .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label, .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
  color: #4f46e5;
}

@layer primereact {
  * {
    box-sizing: border-box;
  }

  .p-component {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
    font-weight: normal;
  }

  .p-component-overlay {
    background-color: rgba(0, 0, 0, .4);
    transition-duration: .2s;
  }

  .p-disabled, .p-component:disabled {
    opacity: .6;
  }

  .p-error {
    color: #e24c4c;
  }

  .p-text-secondary {
    color: #71717a;
  }

  .pi {
    font-size: 1rem;
  }

  .p-icon {
    width: 1rem;
    height: 1rem;
  }

  .p-link {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    border-radius: .375rem;
    font-size: 1rem;
  }

  .p-link:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-component-overlay-enter {
    animation: .15s forwards p-component-overlay-enter-animation;
  }

  .p-component-overlay-leave {
    animation: .15s forwards p-component-overlay-leave-animation;
  }

  @keyframes p-component-overlay-enter-animation {
    from {
      background-color: rgba(0, 0, 0, 0);
    }

    to {
      background-color: var(--maskbg);
    }
  }

  @keyframes p-component-overlay-leave-animation {
    from {
      background-color: var(--maskbg);
    }

    to {
      background-color: rgba(0, 0, 0, 0);
    }
  }

  .p-autocomplete .p-autocomplete-loader {
    right: .75rem;
  }

  .p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
    right: 3.75rem;
  }

  .p-autocomplete .p-autocomplete-multiple-container {
    gap: .5rem;
    padding: .375rem .75rem;
  }

  .p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
    border-color: #d4d4d8;
  }

  .p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
    outline-offset: 0;
    border-color: #4f46e5;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
    padding: .375rem 0;
  }

  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    color: #3f3f46;
    margin: 0;
    padding: 0;
    font-size: 1rem;
  }

  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
    color: #312e81;
    background: #eef2ff;
    border-radius: .375rem;
    margin-right: .5rem;
    padding: .375rem .75rem;
  }

  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
    margin-left: .5rem;
  }

  .p-autocomplete.p-invalid.p-component > .p-inputtext {
    border-color: #f0a9a7;
  }

  .p-autocomplete-panel {
    color: #3f3f46;
    background: #fff;
    border: 0;
    border-radius: .375rem;
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
  }

  .p-autocomplete-panel .p-autocomplete-items {
    padding: .25rem 0;
  }

  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
    color: #3f3f46;
    background: none;
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: .75rem 1rem;
    transition: none;
  }

  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
    color: #18181b;
    background: #f4f4f5;
  }

  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
    color: #312e81;
    background: #eef2ff;
  }

  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
    color: #3f3f46;
    background: #fff;
    margin: 0;
    padding: .75rem 1rem;
    font-weight: 600;
  }

  .p-calendar.p-invalid.p-component > .p-inputtext {
    border-color: #f0a9a7;
  }

  .p-datepicker {
    color: #3f3f46;
    background: #fff;
    border: 1px solid #d4d4d8;
    border-radius: .375rem;
    padding: .5rem;
  }

  .p-datepicker:not(.p-datepicker-inline) {
    background: #fff;
    border: 0;
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
  }

  .p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
    background: #fff;
  }

  .p-datepicker .p-datepicker-header {
    color: #3f3f46;
    background: #fff;
    border-bottom: 1px solid #e5e7eb;
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
    margin: 0;
    padding: .5rem;
    font-weight: 600;
  }

  .p-datepicker .p-datepicker-header .p-datepicker-prev, .p-datepicker .p-datepicker-header .p-datepicker-next {
    color: #71717a;
    background: none;
    border: 0;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    transition: none;
  }

  .p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover, .p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
    color: #18181b;
    background: #f4f4f5;
    border-color: rgba(0, 0, 0, 0);
  }

  .p-datepicker .p-datepicker-header .p-datepicker-prev:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-datepicker .p-datepicker-header .p-datepicker-next:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-datepicker .p-datepicker-header .p-datepicker-title {
    line-height: 2rem;
  }

  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year, .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    color: #3f3f46;
    padding: .5rem;
    font-weight: 600;
    transition: none;
  }

  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover, .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
    color: #4f46e5;
  }

  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    margin-right: .5rem;
  }

  .p-datepicker table {
    margin: .5rem 0;
    font-size: 1rem;
  }

  .p-datepicker table th {
    padding: .5rem;
  }

  .p-datepicker table th > span {
    width: 2.5rem;
    height: 2.5rem;
  }

  .p-datepicker table td {
    padding: .5rem;
  }

  .p-datepicker table td > span {
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    transition: none;
  }

  .p-datepicker table td > span.p-highlight {
    color: #312e81;
    background: #eef2ff;
  }

  .p-datepicker table td > span:focus {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-datepicker table td.p-datepicker-today > span {
    color: #3f3f46;
    background: #d4d4d8;
    border-color: rgba(0, 0, 0, 0);
  }

  .p-datepicker table td.p-datepicker-today > span.p-highlight {
    color: #312e81;
    background: #eef2ff;
  }

  .p-datepicker .p-datepicker-buttonbar {
    border-top: 1px solid #f3f4f6;
    padding: 1rem 0;
  }

  .p-datepicker .p-datepicker-buttonbar .p-button {
    width: auto;
  }

  .p-datepicker .p-timepicker {
    border-top: 1px solid #f3f4f6;
    padding: .5rem;
  }

  .p-datepicker .p-timepicker button {
    color: #71717a;
    background: none;
    border: 0;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    transition: none;
  }

  .p-datepicker .p-timepicker button:enabled:hover {
    color: #18181b;
    background: #f4f4f5;
    border-color: rgba(0, 0, 0, 0);
  }

  .p-datepicker .p-timepicker button:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-datepicker .p-timepicker button:last-child {
    margin-top: .2em;
  }

  .p-datepicker .p-timepicker span {
    font-size: 1.25rem;
  }

  .p-datepicker .p-timepicker > div {
    padding: 0 .5rem;
  }

  .p-datepicker.p-datepicker-timeonly .p-timepicker {
    border-top: 0;
  }

  .p-datepicker .p-monthpicker {
    margin: .5rem 0;
  }

  .p-datepicker .p-monthpicker .p-monthpicker-month {
    border-radius: .375rem;
    padding: .5rem;
    transition: none;
  }

  .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
    color: #312e81;
    background: #eef2ff;
  }

  .p-datepicker .p-yearpicker {
    margin: .5rem 0;
  }

  .p-datepicker .p-yearpicker .p-yearpicker-year {
    border-radius: .375rem;
    padding: .5rem;
    transition: none;
  }

  .p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
    color: #312e81;
    background: #eef2ff;
  }

  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
    border-left: 1px solid #f3f4f6;
    padding: 0 .5rem;
  }

  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
    padding-left: 0;
  }

  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
    border-left: 0;
    padding-right: 0;
  }

  .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
    background: #f4f4f5;
  }

  .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
    background: #f4f4f5;
  }

  .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
    background: #f4f4f5;
  }

  .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  @media screen and (max-width: 769px) {
    .p-datepicker table th, .p-datepicker table td {
      padding: 0;
    }
  }

  .p-cascadeselect {
    background: #fff;
    border: 1px solid #d4d4d8;
    border-radius: .375rem;
    transition: none;
  }

  .p-cascadeselect:not(.p-disabled):hover {
    border-color: #d4d4d8;
  }

  .p-cascadeselect:not(.p-disabled).p-focus {
    outline-offset: 0;
    border-color: #4f46e5;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-cascadeselect .p-cascadeselect-label {
    background: none;
    border: 0;
    padding: .75rem;
  }

  .p-cascadeselect .p-cascadeselect-label.p-placeholder {
    color: #71717a;
  }

  .p-cascadeselect .p-cascadeselect-label:enabled:focus {
    box-shadow: none;
    outline: 0;
  }

  .p-cascadeselect .p-cascadeselect-trigger {
    color: #71717a;
    background: none;
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem;
    width: 3rem;
  }

  .p-cascadeselect.p-invalid.p-component {
    border-color: #f0a9a7;
  }

  .p-cascadeselect-panel {
    color: #3f3f46;
    background: #fff;
    border: 0;
    border-radius: .375rem;
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
  }

  .p-cascadeselect-panel .p-cascadeselect-items {
    padding: .25rem 0;
  }

  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
    color: #3f3f46;
    background: none;
    border: 0;
    border-radius: 0;
    margin: 0;
    transition: none;
  }

  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {
    padding: .75rem 1rem;
  }

  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content:focus {
    outline-offset: 0;
    outline: 0;
    box-shadow: inset 0 0 0 .15rem #6366f1;
  }

  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
    color: #312e81;
    background: #eef2ff;
  }

  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: #18181b;
    background: #f4f4f5;
  }

  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {
    font-size: .875rem;
  }

  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon.p-icon {
    width: .875rem;
    height: .875rem;
  }

  .p-input-filled .p-cascadeselect {
    background: #fafafa;
  }

  .p-input-filled .p-cascadeselect:not(.p-disabled):hover {
    background-color: #fafafa;
  }

  .p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
    background-color: #fff;
  }

  .p-checkbox {
    width: 16px;
    height: 16px;
  }

  .p-checkbox .p-checkbox-box {
    color: #3f3f46;
    background: #fff;
    border: 2px solid #d4d4d8;
    border-radius: .375rem;
    width: 16px;
    height: 16px;
    transition: none;
  }

  .p-checkbox .p-checkbox-box .p-checkbox-icon {
    color: #fff;
    font-size: 10px;
    transition-duration: .2s;
  }

  .p-checkbox .p-checkbox-box .p-checkbox-icon.p-icon {
    width: 10px;
    height: 10px;
  }

  .p-checkbox .p-checkbox-box.p-highlight {
    background: #4f46e5;
    border-color: #4f46e5;
  }

  .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
    color: #fff;
    background: #4f46e5;
    border-color: #4f46e5;
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #d4d4d8;
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline-offset: 0;
    border-color: #4f46e5;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    color: #fff;
    background: #4f46e5;
    border-color: #4f46e5;
  }

  .p-checkbox.p-invalid > .p-checkbox-box {
    border-color: #f0a9a7;
  }

  .p-input-filled .p-checkbox .p-checkbox-box {
    background-color: #fafafa;
  }

  .p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
    background: #4f46e5;
  }

  .p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    background-color: #fafafa;
  }

  .p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background: #4f46e5;
  }

  .p-chips .p-chips-multiple-container {
    gap: .5rem;
    padding: .375rem .75rem;
  }

  .p-chips .p-chips-multiple-container:not(.p-disabled):hover {
    border-color: #d4d4d8;
  }

  .p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
    outline-offset: 0;
    border-color: #4f46e5;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-chips .p-chips-multiple-container .p-chips-token {
    color: #3f3f46;
    background: #eef2ff;
    border-radius: 16px;
    margin-right: .5rem;
    padding: .375rem .75rem;
  }

  .p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
    margin-left: .5rem;
  }

  .p-chips .p-chips-multiple-container .p-chips-input-token {
    padding: .375rem 0;
  }

  .p-chips .p-chips-multiple-container .p-chips-input-token input {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    color: #3f3f46;
    margin: 0;
    padding: 0;
    font-size: 1rem;
  }

  .p-chips.p-invalid.p-component > .p-inputtext {
    border-color: #f0a9a7;
  }

  .p-colorpicker-preview {
    width: 2rem;
    height: 2rem;
  }

  .p-colorpicker-panel {
    background: #27272a;
    border: 1px solid #18181b;
  }

  .p-colorpicker-panel .p-colorpicker-color-handle, .p-colorpicker-panel .p-colorpicker-hue-handle {
    border-color: #fff;
  }

  .p-colorpicker-overlay-panel {
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
  }

  .p-dropdown {
    background: #fff;
    border: 1px solid #d4d4d8;
    border-radius: .375rem;
    transition: none;
  }

  .p-dropdown:not(.p-disabled):hover {
    border-color: #d4d4d8;
  }

  .p-dropdown:not(.p-disabled).p-focus {
    outline-offset: 0;
    border-color: #4f46e5;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 1.75rem;
  }

  .p-dropdown .p-dropdown-label {
    background: none;
    border: 0;
  }

  .p-dropdown .p-dropdown-label.p-placeholder {
    color: #71717a;
  }

  .p-dropdown .p-dropdown-label:enabled:focus {
    box-shadow: none;
    outline: 0;
  }

  .p-dropdown .p-dropdown-trigger {
    color: #71717a;
    background: none;
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem;
    width: 3rem;
  }

  .p-dropdown .p-dropdown-clear-icon {
    color: #71717a;
    right: 3rem;
  }

  .p-dropdown.p-invalid.p-component {
    border-color: #f0a9a7;
  }

  .p-dropdown-panel {
    color: #3f3f46;
    background: #fff;
    border: 0;
    border-radius: .375rem;
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
  }

  .p-dropdown-panel .p-dropdown-header {
    color: #3f3f46;
    background: #fafafa;
    border-bottom: 0;
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
    margin: 0;
    padding: .5rem .75rem;
  }

  .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
    margin-right: -1.75rem;
    padding-right: 1.75rem;
  }

  .p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
    color: #71717a;
    right: .75rem;
  }

  .p-dropdown-panel .p-dropdown-header .p-dropdown-clearable-filter .p-dropdown-filter {
    margin-right: -3.5rem;
    padding-right: 3.5rem;
  }

  .p-dropdown-panel .p-dropdown-header .p-dropdown-clearable-filter .p-dropdown-filter-clear-icon {
    right: 2.5rem;
  }

  .p-dropdown-panel .p-dropdown-items {
    padding: .25rem 0;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    color: #3f3f46;
    background: none;
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: .75rem 1rem;
    transition: none;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #312e81;
    background: #eef2ff;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    color: #18181b;
    background: #f4f4f5;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
    color: #3f3f46;
    background: #fff;
    margin: 0;
    padding: .75rem 1rem;
    font-weight: 600;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
    color: #3f3f46;
    background: none;
    padding: .75rem 1rem;
  }

  .p-input-filled .p-dropdown {
    background: #fafafa;
  }

  .p-input-filled .p-dropdown:not(.p-disabled):hover {
    background-color: #fafafa;
  }

  .p-input-filled .p-dropdown:not(.p-disabled).p-focus {
    background-color: #fff;
  }

  .p-input-filled .p-dropdown:not(.p-disabled).p-focus .p-inputtext {
    background-color: rgba(0, 0, 0, 0);
  }

  .p-inputgroup-addon {
    color: #71717a;
    background: #fafafa;
    border-top: 1px solid #d4d4d8;
    border-bottom: 1px solid #d4d4d8;
    border-left: 1px solid #d4d4d8;
    min-width: 3rem;
    padding: .75rem;
  }

  .p-inputgroup-addon:last-child {
    border-right: 1px solid #d4d4d8;
  }

  .p-inputgroup > .p-component, .p-inputgroup > .p-inputwrapper > .p-inputtext, .p-inputgroup > .p-float-label > .p-component {
    border-radius: 0;
    margin: 0;
  }

  .p-inputgroup > .p-component + .p-inputgroup-addon, .p-inputgroup > .p-inputwrapper > .p-inputtext + .p-inputgroup-addon, .p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
    border-left: 0;
  }

  .p-inputgroup > .p-component:focus, .p-inputgroup > .p-inputwrapper > .p-inputtext:focus, .p-inputgroup > .p-float-label > .p-component:focus, .p-inputgroup > .p-component:focus ~ label, .p-inputgroup > .p-inputwrapper > .p-inputtext:focus ~ label, .p-inputgroup > .p-float-label > .p-component:focus ~ label {
    z-index: 1;
  }

  .p-inputgroup-addon:first-child, .p-inputgroup button:first-child, .p-inputgroup input:first-child, .p-inputgroup > .p-inputwrapper:first-child, .p-inputgroup > .p-inputwrapper:first-child > .p-inputtext, .p-inputgroup .p-float-label:first-child input {
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .p-inputgroup-addon:last-child, .p-inputgroup button:last-child, .p-inputgroup input:last-child, .p-inputgroup > .p-inputwrapper:last-child, .p-inputgroup > .p-inputwrapper:last-child > .p-inputtext, .p-inputgroup .p-float-label:last-child input {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem;
  }

  .p-fluid .p-inputgroup .p-button {
    width: auto;
  }

  .p-fluid .p-inputgroup .p-button.p-button-icon-only {
    width: 3rem;
  }

  .p-inputnumber.p-invalid.p-component > .p-inputtext {
    border-color: #f0a9a7;
  }

  .p-inputswitch {
    width: 3rem;
    height: 1.75rem;
  }

  .p-inputswitch .p-inputswitch-slider {
    background: #d4d4d8;
    border-radius: 30px;
    transition: none;
  }

  .p-inputswitch .p-inputswitch-slider:before {
    background: #fff;
    border-radius: 50%;
    width: 1.25rem;
    height: 1.25rem;
    margin-top: -.625rem;
    transition-duration: .2s;
    left: .25rem;
  }

  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    transform: translateX(1.25rem);
  }

  .p-inputswitch.p-focus .p-inputswitch-slider {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
    background: #bebec4;
  }

  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #4f46e5;
  }

  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background: #fff;
  }

  .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: #4338ca;
  }

  .p-inputswitch.p-invalid .p-inputswitch-slider {
    border-color: #f0a9a7;
  }

  .p-inputtext {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    color: #3f3f46;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    background: #fff;
    border: 1px solid #d4d4d8;
    border-radius: .375rem;
    padding: .75rem;
    font-size: 1rem;
    transition: none;
  }

  .p-inputtext:enabled:hover {
    border-color: #d4d4d8;
  }

  .p-inputtext:enabled:focus {
    outline-offset: 0;
    border-color: #4f46e5;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-inputtext.p-invalid.p-component {
    border-color: #f0a9a7;
  }

  .p-inputtext.p-inputtext-sm {
    padding: .65625rem;
    font-size: .875rem;
  }

  .p-inputtext.p-inputtext-lg {
    padding: .9375rem;
    font-size: 1.25rem;
  }

  .p-float-label > label {
    color: #71717a;
    transition-duration: .2s;
    left: .75rem;
  }

  .p-float-label > label.p-error {
    color: #e24c4c;
  }

  .p-input-icon-left > i:first-of-type, .p-input-icon-left > svg:first-of-type, .p-input-icon-left > .p-input-prefix {
    color: #71717a;
    left: .75rem;
  }

  .p-input-icon-left > .p-inputtext {
    padding-left: 2.5rem;
  }

  .p-input-icon-left.p-float-label > label {
    left: 2.5rem;
  }

  .p-input-icon-right > i:last-of-type, .p-input-icon-right > svg:last-of-type, .p-input-icon-right > .p-input-suffix {
    color: #71717a;
    right: .75rem;
  }

  .p-input-icon-right > .p-inputtext {
    padding-right: 2.5rem;
  }

  ::-webkit-input-placeholder {
    color: #71717a;
  }

  :-moz-placeholder {
    color: #71717a;
  }

  ::-moz-placeholder {
    color: #71717a;
  }

  :-ms-input-placeholder {
    color: #71717a;
  }

  .p-input-filled .p-inputtext, .p-input-filled .p-inputtext:enabled:hover {
    background-color: #fafafa;
  }

  .p-input-filled .p-inputtext:enabled:focus {
    background-color: #fff;
  }

  .p-inputtext-sm .p-inputtext {
    padding: .65625rem;
    font-size: .875rem;
  }

  .p-inputtext-lg .p-inputtext {
    padding: .9375rem;
    font-size: 1.25rem;
  }

  .p-listbox {
    color: #3f3f46;
    background: #fff;
    border: 1px solid #d4d4d8;
    border-radius: .375rem;
  }

  .p-listbox .p-listbox-header {
    color: #3f3f46;
    background: #fafafa;
    border-bottom: 0;
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
    margin: 0;
    padding: .5rem .75rem;
  }

  .p-listbox .p-listbox-header .p-listbox-filter {
    padding-right: 1.75rem;
  }

  .p-listbox .p-listbox-header .p-listbox-filter-icon {
    color: #71717a;
    right: .75rem;
  }

  .p-listbox .p-listbox-list {
    padding: .25rem 0;
  }

  .p-listbox .p-listbox-list .p-listbox-item {
    color: #3f3f46;
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: .75rem 1rem;
    transition: none;
  }

  .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
    color: #312e81;
    background: #eef2ff;
  }

  .p-listbox .p-listbox-list .p-listbox-item:focus {
    outline-offset: 0;
    outline: 0;
    box-shadow: inset 0 0 0 .15rem #6366f1;
  }

  .p-listbox .p-listbox-list .p-listbox-item-group {
    color: #3f3f46;
    background: #fff;
    margin: 0;
    padding: .75rem 1rem;
    font-weight: 600;
  }

  .p-listbox .p-listbox-list .p-listbox-empty-message {
    color: #3f3f46;
    background: none;
    padding: .75rem 1rem;
  }

  .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
    color: #18181b;
    background: #f4f4f5;
  }

  .p-listbox.p-invalid {
    border-color: #f0a9a7;
  }

  .p-mention-panel {
    color: #3f3f46;
    background: #fff;
    border: 0;
    border-radius: .375rem;
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
  }

  .p-mention-panel .p-mention-items {
    padding: .25rem 0;
  }

  .p-mention-panel .p-mention-items .p-mention-item {
    color: #3f3f46;
    background: none;
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: .75rem 1rem;
    transition: none;
  }

  .p-mention-panel .p-mention-items .p-mention-item:hover {
    color: #18181b;
    background: #f4f4f5;
  }

  .p-mention-panel .p-mention-items .p-mention-item.p-highlight {
    color: #312e81;
    background: #eef2ff;
  }

  .p-multiselect {
    background: #fff;
    border: 1px solid #d4d4d8;
    border-radius: .375rem;
    transition: none;
  }

  .p-multiselect:not(.p-disabled):hover {
    border-color: #d4d4d8;
  }

  .p-multiselect:not(.p-disabled).p-focus {
    outline-offset: 0;
    border-color: #4f46e5;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-multiselect.p-multiselect-clearable .p-multiselect-label {
    padding-right: 1.75rem;
  }

  .p-multiselect .p-multiselect-label {
    padding: .75rem;
    transition: none;
  }

  .p-multiselect .p-multiselect-label.p-placeholder {
    color: #71717a;
  }

  .p-multiselect.p-multiselect-chip .p-multiselect-token {
    color: #3f3f46;
    background: #e5e7eb;
    border-radius: 16px;
    margin-right: .5rem;
    padding: .375rem .75rem;
  }

  .p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
    margin-left: .5rem;
  }

  .p-multiselect .p-multiselect-trigger {
    color: #71717a;
    background: none;
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem;
    width: 3rem;
  }

  .p-multiselect .p-multiselect-clear-icon {
    color: #71717a;
    right: 3rem;
  }

  .p-multiselect.p-invalid.p-component {
    border-color: #f0a9a7;
  }

  .p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
    padding: .375rem .75rem;
  }

  .p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label.p-multiselect-items-label {
    padding: .75rem;
  }

  .p-inputwrapper-filled.p-multiselect.p-multiselect-clearable .p-multiselect-label {
    padding-right: 1.75rem;
  }

  .p-multiselect-panel {
    color: #3f3f46;
    background: #fff;
    border: 0;
    border-radius: .375rem;
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
  }

  .p-multiselect-panel .p-multiselect-header {
    color: #3f3f46;
    background: #fafafa;
    border-bottom: 0;
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
    margin: 0;
    padding: .5rem .75rem;
  }

  .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
    margin: 0 .5rem;
  }

  .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
    padding-right: 1.75rem;
  }

  .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
    color: #71717a;
    right: .75rem;
  }

  .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
    color: #71717a;
    background: none;
    border: 0;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    transition: none;
  }

  .p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
    color: #18181b;
    background: #f4f4f5;
    border-color: rgba(0, 0, 0, 0);
  }

  .p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-multiselect-panel .p-multiselect-items {
    padding: .25rem 0;
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    color: #3f3f46;
    background: none;
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: .75rem 1rem;
    transition: none;
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: #312e81;
    background: #eef2ff;
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: #18181b;
    background: #f4f4f5;
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    outline-offset: 0;
    outline: 0;
    box-shadow: inset 0 0 0 .15rem #6366f1;
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
    margin-right: .5rem;
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
    color: #3f3f46;
    background: #fff;
    margin: 0;
    padding: .75rem 1rem;
    font-weight: 600;
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
    color: #3f3f46;
    background: none;
    padding: .75rem 1rem;
  }

  .p-input-filled .p-multiselect {
    background: #fafafa;
  }

  .p-input-filled .p-multiselect:not(.p-disabled):hover {
    background-color: #fafafa;
  }

  .p-input-filled .p-multiselect:not(.p-disabled).p-focus {
    background-color: #fff;
  }

  .p-password.p-invalid.p-component > .p-inputtext {
    border-color: #f0a9a7;
  }

  .p-password-panel {
    color: #3f3f46;
    background: #fff;
    border: 0;
    border-radius: .375rem;
    padding: 1.25rem;
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
  }

  .p-password-panel .p-password-meter {
    background: #e5e7eb;
    margin-bottom: .5rem;
  }

  .p-password-panel .p-password-meter .p-password-strength.weak {
    background: #ef4444;
  }

  .p-password-panel .p-password-meter .p-password-strength.medium {
    background: #f59e0b;
  }

  .p-password-panel .p-password-meter .p-password-strength.strong {
    background: #22c55e;
  }

  .p-radiobutton {
    width: 16px;
    height: 16px;
  }

  .p-radiobutton .p-radiobutton-box {
    color: #3f3f46;
    background: #fff;
    border: 2px solid #d4d4d8;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    transition: none;
  }

  .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: #d4d4d8;
  }

  .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    outline-offset: 0;
    border-color: #4f46e5;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    background-color: #fff;
    width: 6px;
    height: 6px;
    transition-duration: .2s;
  }

  .p-radiobutton .p-radiobutton-box.p-highlight {
    background: #4f46e5;
    border-color: #4f46e5;
  }

  .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    color: #fff;
    background: #4f46e5;
    border-color: #4f46e5;
  }

  .p-radiobutton.p-invalid > .p-radiobutton-box {
    border-color: #f0a9a7;
  }

  .p-radiobutton:focus {
    outline: 0;
  }

  .p-input-filled .p-radiobutton .p-radiobutton-box, .p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
    background-color: #fafafa;
  }

  .p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight, .p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    background: #4f46e5;
  }

  .p-rating {
    gap: .5rem;
  }

  .p-rating .p-rating-item .p-rating-icon {
    color: #3f3f46;
    font-size: 1.143rem;
    transition: none;
  }

  .p-rating .p-rating-item .p-rating-icon.p-icon {
    width: 1.143rem;
    height: 1.143rem;
  }

  .p-rating .p-rating-item .p-rating-icon.p-rating-cancel {
    color: #ef4444;
  }

  .p-rating .p-rating-item:focus {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-rating .p-rating-item.p-rating-item-active .p-rating-icon, .p-rating:not(.p-disabled):not(.p-readonly) .p-rating-item:hover .p-rating-icon {
    color: #4f46e5;
  }

  .p-rating:not(.p-disabled):not(.p-readonly) .p-rating-item:hover .p-rating-icon.p-rating-cancel {
    color: #dc2626;
  }

  .p-selectbutton .p-button {
    color: #3f3f46;
    background: #fff;
    border: 1px solid #d4d4d8;
    transition: none;
  }

  .p-selectbutton .p-button .p-button-icon-left, .p-selectbutton .p-button .p-button-icon-right {
    color: #71717a;
  }

  .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    color: #3f3f46;
    background: #f4f4f5;
    border-color: #d4d4d8;
  }

  .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left, .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
    color: #71717a;
  }

  .p-selectbutton .p-button.p-highlight {
    color: #fff;
    background: #4f46e5;
    border-color: #4f46e5;
  }

  .p-selectbutton .p-button.p-highlight .p-button-icon-left, .p-selectbutton .p-button.p-highlight .p-button-icon-right {
    color: #fff;
  }

  .p-selectbutton .p-button.p-highlight:hover {
    color: #fff;
    background: #4338ca;
    border-color: #4338ca;
  }

  .p-selectbutton .p-button.p-highlight:hover .p-button-icon-left, .p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
    color: #fff;
  }

  .p-selectbutton.p-invalid > .p-button {
    border-color: #f0a9a7;
  }

  .p-slider {
    background: #e5e7eb;
    border: 0;
    border-radius: .375rem;
  }

  .p-slider.p-slider-horizontal {
    height: .286rem;
  }

  .p-slider.p-slider-horizontal .p-slider-handle {
    margin-top: -.5715rem;
    margin-left: -.5715rem;
  }

  .p-slider.p-slider-vertical {
    width: .286rem;
  }

  .p-slider.p-slider-vertical .p-slider-handle {
    margin-bottom: -.5715rem;
    margin-left: -.5715rem;
  }

  .p-slider .p-slider-handle {
    background: #fff;
    border: 2px solid #4f46e5;
    border-radius: 50%;
    width: 1.143rem;
    height: 1.143rem;
    transition: none;
  }

  .p-slider .p-slider-handle:focus {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-slider .p-slider-range {
    background: #4f46e5;
  }

  .p-slider:not(.p-disabled) .p-slider-handle:hover {
    background: #4f46e5;
    border-color: #4f46e5;
  }

  .p-treeselect {
    background: #fff;
    border: 1px solid #d4d4d8;
    border-radius: .375rem;
    transition: none;
  }

  .p-treeselect:not(.p-disabled):hover {
    border-color: #d4d4d8;
  }

  .p-treeselect:not(.p-disabled).p-focus {
    outline-offset: 0;
    border-color: #4f46e5;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-treeselect.p-treeselect-clearable .p-treeselect-label {
    padding-right: 1.75rem;
  }

  .p-treeselect .p-treeselect-label {
    padding: .75rem;
    transition: none;
  }

  .p-treeselect .p-treeselect-label.p-placeholder {
    color: #71717a;
  }

  .p-treeselect.p-treeselect-chip .p-treeselect-token {
    color: #3f3f46;
    background: #e5e7eb;
    border-radius: 16px;
    margin-right: .5rem;
    padding: .375rem .75rem;
  }

  .p-treeselect .p-treeselect-trigger {
    color: #71717a;
    background: none;
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem;
    width: 3rem;
  }

  .p-treeselect .p-treeselect-clear-icon {
    color: #71717a;
    right: 3rem;
  }

  .p-treeselect.p-invalid.p-component {
    border-color: #f0a9a7;
  }

  .p-inputwrapper-filled.p-treeselect.p-treeselect-chip .p-treeselect-label {
    padding: .375rem .75rem;
  }

  .p-treeselect-panel {
    color: #3f3f46;
    background: #fff;
    border: 0;
    border-radius: .375rem;
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
  }

  .p-treeselect-panel .p-treeselect-header {
    color: #3f3f46;
    background: #fafafa;
    border-bottom: 0;
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
    margin: 0;
    padding: .5rem .75rem;
  }

  .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
    margin-right: .5rem;
  }

  .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter {
    padding-right: 1.75rem;
  }

  .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter-icon {
    color: #71717a;
    right: .75rem;
  }

  .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter {
    padding-right: 3.5rem;
  }

  .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter-clear-icon {
    right: 2.5rem;
  }

  .p-treeselect-panel .p-treeselect-header .p-treeselect-close {
    color: #71717a;
    background: none;
    border: 0;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    transition: none;
  }

  .p-treeselect-panel .p-treeselect-header .p-treeselect-close:enabled:hover {
    color: #18181b;
    background: #f4f4f5;
    border-color: rgba(0, 0, 0, 0);
  }

  .p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
    border: 0;
  }

  .p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
    color: #3f3f46;
    background: none;
    padding: .75rem 1rem;
  }

  .p-input-filled .p-treeselect {
    background: #fafafa;
  }

  .p-input-filled .p-treeselect:not(.p-disabled):hover {
    background-color: #fafafa;
  }

  .p-input-filled .p-treeselect:not(.p-disabled).p-focus {
    background-color: #fff;
  }

  .p-togglebutton.p-button {
    color: #3f3f46;
    background: #fff;
    border: 1px solid #d4d4d8;
    transition: none;
  }

  .p-togglebutton.p-button .p-button-icon-left, .p-togglebutton.p-button .p-button-icon-right {
    color: #71717a;
  }

  .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
    color: #3f3f46;
    background: #f4f4f5;
    border-color: #d4d4d8;
  }

  .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left, .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
    color: #71717a;
  }

  .p-togglebutton.p-button.p-highlight {
    color: #fff;
    background: #4f46e5;
    border-color: #4f46e5;
  }

  .p-togglebutton.p-button.p-highlight .p-button-icon-left, .p-togglebutton.p-button.p-highlight .p-button-icon-right {
    color: #fff;
  }

  .p-togglebutton.p-button.p-highlight:hover {
    color: #fff;
    background: #4338ca;
    border-color: #4338ca;
  }

  .p-togglebutton.p-button.p-highlight:hover .p-button-icon-left, .p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
    color: #fff;
  }

  .p-togglebutton.p-button.p-invalid > .p-button {
    border-color: #f0a9a7;
  }

  .p-button {
    color: #fff;
    background: #4f46e5;
    border: 1px solid #4f46e5;
    border-radius: .375rem;
    padding: .75rem 1rem;
    font-size: 1rem;
    transition: none;
  }

  .p-button:not(:disabled):hover, .p-button:not(:disabled):active {
    color: #fff;
    background: #4338ca;
    border-color: #4338ca;
  }

  .p-button.p-button-outlined {
    color: #4f46e5;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid;
  }

  .p-button.p-button-outlined:not(:disabled):hover {
    color: #4f46e5;
    background: rgba(79, 70, 229, .04);
    border: 1px solid;
  }

  .p-button.p-button-outlined:not(:disabled):active {
    color: #4f46e5;
    background: rgba(79, 70, 229, .16);
    border: 1px solid;
  }

  .p-button.p-button-outlined.p-button-plain {
    color: #71717a;
    border-color: #71717a;
  }

  .p-button.p-button-outlined.p-button-plain:not(:disabled):hover {
    color: #71717a;
    background: #f4f4f5;
  }

  .p-button.p-button-outlined.p-button-plain:not(:disabled):active {
    color: #71717a;
    background: #e5e7eb;
  }

  .p-button.p-button-text {
    color: #4f46e5;
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-button.p-button-text:not(:disabled):hover {
    color: #4f46e5;
    background: rgba(79, 70, 229, .04);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-button.p-button-text:not(:disabled):active {
    color: #4f46e5;
    background: rgba(79, 70, 229, .16);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-button.p-button-text.p-button-plain {
    color: #71717a;
  }

  .p-button.p-button-text.p-button-plain:not(:disabled):hover {
    color: #71717a;
    background: #f4f4f5;
  }

  .p-button.p-button-text.p-button-plain:not(:disabled):active {
    color: #71717a;
    background: #e5e7eb;
  }

  .p-button:focus {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-button .p-button-label {
    transition-duration: .2s;
  }

  .p-button .p-button-icon-left {
    margin-right: .5rem;
  }

  .p-button .p-button-icon-right {
    margin-left: .5rem;
  }

  .p-button .p-button-icon-bottom {
    margin-top: .5rem;
  }

  .p-button .p-button-icon-top {
    margin-bottom: .5rem;
  }

  .p-button .p-badge {
    color: #4f46e5;
    background-color: #fff;
    min-width: 1rem;
    height: 1rem;
    margin-left: .5rem;
    line-height: 1rem;
  }

  .p-button.p-button-raised {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -1px rgba(0, 0, 0, .06);
  }

  .p-button.p-button-rounded {
    border-radius: 2rem;
  }

  .p-button.p-button-icon-only {
    width: 3rem;
    padding: .75rem 0;
  }

  .p-button.p-button-icon-only .p-button-icon-left, .p-button.p-button-icon-only .p-button-icon-right {
    margin: 0;
  }

  .p-button.p-button-icon-only.p-button-rounded {
    border-radius: 50%;
    height: 3rem;
  }

  .p-button.p-button-sm {
    padding: .65625rem .875rem;
    font-size: .875rem;
  }

  .p-button.p-button-sm .p-button-icon {
    font-size: .875rem;
  }

  .p-button.p-button-lg {
    padding: .9375rem 1.25rem;
    font-size: 1.25rem;
  }

  .p-button.p-button-lg .p-button-icon {
    font-size: 1.25rem;
  }

  .p-button.p-button-loading-label-only.p-button-loading-left .p-button-label {
    margin-left: .5rem;
  }

  .p-button.p-button-loading-label-only.p-button-loading-right .p-button-label {
    margin-right: .5rem;
  }

  .p-button.p-button-loading-label-only.p-button-loading-top .p-button-label {
    margin-top: .5rem;
  }

  .p-button.p-button-loading-label-only.p-button-loading-bottom .p-button-label {
    margin-bottom: .5rem;
  }

  .p-button.p-button-loading-label-only .p-button-loading-icon {
    margin: 0;
  }

  .p-fluid .p-button {
    width: 100%;
  }

  .p-fluid .p-button-icon-only {
    width: 3rem;
  }

  .p-fluid .p-buttonset {
    display: -ms-flexbox;
    display: flex;
  }

  .p-fluid .p-buttonset .p-button {
    -ms-flex: 1;
    flex: 1;
  }

  .p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button, .p-fileupload-choose.p-button-secondary {
    color: #fff;
    background: #64748b;
    border: 1px solid #64748b;
  }

  .p-button.p-button-secondary:not(:disabled):hover, .p-buttonset.p-button-secondary > .p-button:not(:disabled):hover, .p-splitbutton.p-button-secondary > .p-button:not(:disabled):hover, .p-fileupload-choose.p-button-secondary:not(:disabled):hover {
    color: #fff;
    background: #475569;
    border-color: #475569;
  }

  .p-button.p-button-secondary:not(:disabled):focus, .p-buttonset.p-button-secondary > .p-button:not(:disabled):focus, .p-splitbutton.p-button-secondary > .p-button:not(:disabled):focus, .p-fileupload-choose.p-button-secondary:not(:disabled):focus {
    box-shadow: 0 0 0 .2rem #c0c7d2;
  }

  .p-button.p-button-secondary:not(:disabled):active, .p-buttonset.p-button-secondary > .p-button:not(:disabled):active, .p-splitbutton.p-button-secondary > .p-button:not(:disabled):active, .p-fileupload-choose.p-button-secondary:not(:disabled):active {
    color: #fff;
    background: #475569;
    border-color: #475569;
  }

  .p-button.p-button-secondary.p-button-outlined, .p-buttonset.p-button-secondary > .p-button.p-button-outlined, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined, .p-fileupload-choose.p-button-secondary.p-button-outlined {
    color: #64748b;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid;
  }

  .p-button.p-button-secondary.p-button-outlined:not(:disabled):hover, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(:disabled):hover, .p-fileupload-choose.p-button-secondary.p-button-outlined:not(:disabled):hover {
    color: #64748b;
    background: rgba(100, 116, 139, .04);
    border: 1px solid;
  }

  .p-button.p-button-secondary.p-button-outlined:not(:disabled):active, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(:disabled):active, .p-fileupload-choose.p-button-secondary.p-button-outlined:not(:disabled):active {
    color: #64748b;
    background: rgba(100, 116, 139, .16);
    border: 1px solid;
  }

  .p-button.p-button-secondary.p-button-text, .p-buttonset.p-button-secondary > .p-button.p-button-text, .p-splitbutton.p-button-secondary > .p-button.p-button-text, .p-fileupload-choose.p-button-secondary.p-button-text {
    color: #64748b;
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-button.p-button-secondary.p-button-text:not(:disabled):hover, .p-buttonset.p-button-secondary > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-secondary > .p-button.p-button-text:not(:disabled):hover, .p-fileupload-choose.p-button-secondary.p-button-text:not(:disabled):hover {
    color: #64748b;
    background: rgba(100, 116, 139, .04);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-button.p-button-secondary.p-button-text:not(:disabled):active, .p-buttonset.p-button-secondary > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:not(:disabled):active, .p-fileupload-choose.p-button-secondary.p-button-text:not(:disabled):active {
    color: #64748b;
    background: rgba(100, 116, 139, .16);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-button.p-button-info, .p-buttonset.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button, .p-fileupload-choose.p-button-info {
    color: #fff;
    background: #3b82f6;
    border: 1px solid #3b82f6;
  }

  .p-button.p-button-info:not(:disabled):hover, .p-buttonset.p-button-info > .p-button:not(:disabled):hover, .p-splitbutton.p-button-info > .p-button:not(:disabled):hover, .p-fileupload-choose.p-button-info:not(:disabled):hover {
    color: #fff;
    background: #2563eb;
    border-color: #2563eb;
  }

  .p-button.p-button-info:not(:disabled):focus, .p-buttonset.p-button-info > .p-button:not(:disabled):focus, .p-splitbutton.p-button-info > .p-button:not(:disabled):focus, .p-fileupload-choose.p-button-info:not(:disabled):focus {
    box-shadow: 0 0 0 .2rem #b1cdfb;
  }

  .p-button.p-button-info:not(:disabled):active, .p-buttonset.p-button-info > .p-button:not(:disabled):active, .p-splitbutton.p-button-info > .p-button:not(:disabled):active, .p-fileupload-choose.p-button-info:not(:disabled):active {
    color: #fff;
    background: #2563eb;
    border-color: #2563eb;
  }

  .p-button.p-button-info.p-button-outlined, .p-buttonset.p-button-info > .p-button.p-button-outlined, .p-splitbutton.p-button-info > .p-button.p-button-outlined, .p-fileupload-choose.p-button-info.p-button-outlined {
    color: #3b82f6;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid;
  }

  .p-button.p-button-info.p-button-outlined:not(:disabled):hover, .p-buttonset.p-button-info > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-info > .p-button.p-button-outlined:not(:disabled):hover, .p-fileupload-choose.p-button-info.p-button-outlined:not(:disabled):hover {
    color: #3b82f6;
    background: rgba(59, 130, 246, .04);
    border: 1px solid;
  }

  .p-button.p-button-info.p-button-outlined:not(:disabled):active, .p-buttonset.p-button-info > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:not(:disabled):active, .p-fileupload-choose.p-button-info.p-button-outlined:not(:disabled):active {
    color: #3b82f6;
    background: rgba(59, 130, 246, .16);
    border: 1px solid;
  }

  .p-button.p-button-info.p-button-text, .p-buttonset.p-button-info > .p-button.p-button-text, .p-splitbutton.p-button-info > .p-button.p-button-text, .p-fileupload-choose.p-button-info.p-button-text {
    color: #3b82f6;
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-button.p-button-info.p-button-text:not(:disabled):hover, .p-buttonset.p-button-info > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-info > .p-button.p-button-text:not(:disabled):hover, .p-fileupload-choose.p-button-info.p-button-text:not(:disabled):hover {
    color: #3b82f6;
    background: rgba(59, 130, 246, .04);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-button.p-button-info.p-button-text:not(:disabled):active, .p-buttonset.p-button-info > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-info > .p-button.p-button-text:not(:disabled):active, .p-fileupload-choose.p-button-info.p-button-text:not(:disabled):active {
    color: #3b82f6;
    background: rgba(59, 130, 246, .16);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button, .p-fileupload-choose.p-button-success {
    color: #fff;
    background: #22c55e;
    border: 1px solid #22c55e;
  }

  .p-button.p-button-success:not(:disabled):hover, .p-buttonset.p-button-success > .p-button:not(:disabled):hover, .p-splitbutton.p-button-success > .p-button:not(:disabled):hover, .p-fileupload-choose.p-button-success:not(:disabled):hover {
    color: #fff;
    background: #16a34a;
    border-color: #16a34a;
  }

  .p-button.p-button-success:not(:disabled):focus, .p-buttonset.p-button-success > .p-button:not(:disabled):focus, .p-splitbutton.p-button-success > .p-button:not(:disabled):focus, .p-fileupload-choose.p-button-success:not(:disabled):focus {
    box-shadow: 0 0 0 .2rem #a0efbd;
  }

  .p-button.p-button-success:not(:disabled):active, .p-buttonset.p-button-success > .p-button:not(:disabled):active, .p-splitbutton.p-button-success > .p-button:not(:disabled):active, .p-fileupload-choose.p-button-success:not(:disabled):active {
    color: #fff;
    background: #16a34a;
    border-color: #16a34a;
  }

  .p-button.p-button-success.p-button-outlined, .p-buttonset.p-button-success > .p-button.p-button-outlined, .p-splitbutton.p-button-success > .p-button.p-button-outlined, .p-fileupload-choose.p-button-success.p-button-outlined {
    color: #22c55e;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid;
  }

  .p-button.p-button-success.p-button-outlined:not(:disabled):hover, .p-buttonset.p-button-success > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-success > .p-button.p-button-outlined:not(:disabled):hover, .p-fileupload-choose.p-button-success.p-button-outlined:not(:disabled):hover {
    color: #22c55e;
    background: rgba(34, 197, 94, .04);
    border: 1px solid;
  }

  .p-button.p-button-success.p-button-outlined:not(:disabled):active, .p-buttonset.p-button-success > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:not(:disabled):active, .p-fileupload-choose.p-button-success.p-button-outlined:not(:disabled):active {
    color: #22c55e;
    background: rgba(34, 197, 94, .16);
    border: 1px solid;
  }

  .p-button.p-button-success.p-button-text, .p-buttonset.p-button-success > .p-button.p-button-text, .p-splitbutton.p-button-success > .p-button.p-button-text, .p-fileupload-choose.p-button-success.p-button-text {
    color: #22c55e;
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-button.p-button-success.p-button-text:not(:disabled):hover, .p-buttonset.p-button-success > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-success > .p-button.p-button-text:not(:disabled):hover, .p-fileupload-choose.p-button-success.p-button-text:not(:disabled):hover {
    color: #22c55e;
    background: rgba(34, 197, 94, .04);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-button.p-button-success.p-button-text:not(:disabled):active, .p-buttonset.p-button-success > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-success > .p-button.p-button-text:not(:disabled):active, .p-fileupload-choose.p-button-success.p-button-text:not(:disabled):active {
    color: #22c55e;
    background: rgba(34, 197, 94, .16);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-button.p-button-warning, .p-buttonset.p-button-warning > .p-button, .p-splitbutton.p-button-warning > .p-button, .p-fileupload-choose.p-button-warning {
    color: #fff;
    background: #f59e0b;
    border: 1px solid #f59e0b;
  }

  .p-button.p-button-warning:not(:disabled):hover, .p-buttonset.p-button-warning > .p-button:not(:disabled):hover, .p-splitbutton.p-button-warning > .p-button:not(:disabled):hover, .p-fileupload-choose.p-button-warning:not(:disabled):hover {
    color: #fff;
    background: #d97706;
    border-color: #d97706;
  }

  .p-button.p-button-warning:not(:disabled):focus, .p-buttonset.p-button-warning > .p-button:not(:disabled):focus, .p-splitbutton.p-button-warning > .p-button:not(:disabled):focus, .p-fileupload-choose.p-button-warning:not(:disabled):focus {
    box-shadow: 0 0 0 .2rem #fbd89d;
  }

  .p-button.p-button-warning:not(:disabled):active, .p-buttonset.p-button-warning > .p-button:not(:disabled):active, .p-splitbutton.p-button-warning > .p-button:not(:disabled):active, .p-fileupload-choose.p-button-warning:not(:disabled):active {
    color: #fff;
    background: #d97706;
    border-color: #d97706;
  }

  .p-button.p-button-warning.p-button-outlined, .p-buttonset.p-button-warning > .p-button.p-button-outlined, .p-splitbutton.p-button-warning > .p-button.p-button-outlined, .p-fileupload-choose.p-button-warning.p-button-outlined {
    color: #f59e0b;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid;
  }

  .p-button.p-button-warning.p-button-outlined:not(:disabled):hover, .p-buttonset.p-button-warning > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:not(:disabled):hover, .p-fileupload-choose.p-button-warning.p-button-outlined:not(:disabled):hover {
    color: #f59e0b;
    background: rgba(245, 158, 11, .04);
    border: 1px solid;
  }

  .p-button.p-button-warning.p-button-outlined:not(:disabled):active, .p-buttonset.p-button-warning > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:not(:disabled):active, .p-fileupload-choose.p-button-warning.p-button-outlined:not(:disabled):active {
    color: #f59e0b;
    background: rgba(245, 158, 11, .16);
    border: 1px solid;
  }

  .p-button.p-button-warning.p-button-text, .p-buttonset.p-button-warning > .p-button.p-button-text, .p-splitbutton.p-button-warning > .p-button.p-button-text, .p-fileupload-choose.p-button-warning.p-button-text {
    color: #f59e0b;
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-button.p-button-warning.p-button-text:not(:disabled):hover, .p-buttonset.p-button-warning > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-warning > .p-button.p-button-text:not(:disabled):hover, .p-fileupload-choose.p-button-warning.p-button-text:not(:disabled):hover {
    color: #f59e0b;
    background: rgba(245, 158, 11, .04);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-button.p-button-warning.p-button-text:not(:disabled):active, .p-buttonset.p-button-warning > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-warning > .p-button.p-button-text:not(:disabled):active, .p-fileupload-choose.p-button-warning.p-button-text:not(:disabled):active {
    color: #f59e0b;
    background: rgba(245, 158, 11, .16);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-button.p-button-help, .p-buttonset.p-button-help > .p-button, .p-splitbutton.p-button-help > .p-button, .p-fileupload-choose.p-button-help {
    color: #fff;
    background: #a855f7;
    border: 1px solid #a855f7;
  }

  .p-button.p-button-help:not(:disabled):hover, .p-buttonset.p-button-help > .p-button:not(:disabled):hover, .p-splitbutton.p-button-help > .p-button:not(:disabled):hover, .p-fileupload-choose.p-button-help:not(:disabled):hover {
    color: #fff;
    background: #9333ea;
    border-color: #9333ea;
  }

  .p-button.p-button-help:not(:disabled):focus, .p-buttonset.p-button-help > .p-button:not(:disabled):focus, .p-splitbutton.p-button-help > .p-button:not(:disabled):focus, .p-fileupload-choose.p-button-help:not(:disabled):focus {
    box-shadow: 0 0 0 .2rem #dcbbfc;
  }

  .p-button.p-button-help:not(:disabled):active, .p-buttonset.p-button-help > .p-button:not(:disabled):active, .p-splitbutton.p-button-help > .p-button:not(:disabled):active, .p-fileupload-choose.p-button-help:not(:disabled):active {
    color: #fff;
    background: #9333ea;
    border-color: #9333ea;
  }

  .p-button.p-button-help.p-button-outlined, .p-buttonset.p-button-help > .p-button.p-button-outlined, .p-splitbutton.p-button-help > .p-button.p-button-outlined, .p-fileupload-choose.p-button-help.p-button-outlined {
    color: #a855f7;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid;
  }

  .p-button.p-button-help.p-button-outlined:not(:disabled):hover, .p-buttonset.p-button-help > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-help > .p-button.p-button-outlined:not(:disabled):hover, .p-fileupload-choose.p-button-help.p-button-outlined:not(:disabled):hover {
    color: #a855f7;
    background: rgba(168, 85, 247, .04);
    border: 1px solid;
  }

  .p-button.p-button-help.p-button-outlined:not(:disabled):active, .p-buttonset.p-button-help > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-help > .p-button.p-button-outlined:not(:disabled):active, .p-fileupload-choose.p-button-help.p-button-outlined:not(:disabled):active {
    color: #a855f7;
    background: rgba(168, 85, 247, .16);
    border: 1px solid;
  }

  .p-button.p-button-help.p-button-text, .p-buttonset.p-button-help > .p-button.p-button-text, .p-splitbutton.p-button-help > .p-button.p-button-text, .p-fileupload-choose.p-button-help.p-button-text {
    color: #a855f7;
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-button.p-button-help.p-button-text:not(:disabled):hover, .p-buttonset.p-button-help > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-help > .p-button.p-button-text:not(:disabled):hover, .p-fileupload-choose.p-button-help.p-button-text:not(:disabled):hover {
    color: #a855f7;
    background: rgba(168, 85, 247, .04);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-button.p-button-help.p-button-text:not(:disabled):active, .p-buttonset.p-button-help > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-help > .p-button.p-button-text:not(:disabled):active, .p-fileupload-choose.p-button-help.p-button-text:not(:disabled):active {
    color: #a855f7;
    background: rgba(168, 85, 247, .16);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-button.p-button-danger, .p-buttonset.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button, .p-fileupload-choose.p-button-danger {
    color: #fff;
    background: #ef4444;
    border: 1px solid #ef4444;
  }

  .p-button.p-button-danger:not(:disabled):hover, .p-buttonset.p-button-danger > .p-button:not(:disabled):hover, .p-splitbutton.p-button-danger > .p-button:not(:disabled):hover, .p-fileupload-choose.p-button-danger:not(:disabled):hover {
    color: #fff;
    background: #dc2626;
    border-color: #dc2626;
  }

  .p-button.p-button-danger:not(:disabled):focus, .p-buttonset.p-button-danger > .p-button:not(:disabled):focus, .p-splitbutton.p-button-danger > .p-button:not(:disabled):focus, .p-fileupload-choose.p-button-danger:not(:disabled):focus {
    box-shadow: 0 0 0 .2rem #f9b4b4;
  }

  .p-button.p-button-danger:not(:disabled):active, .p-buttonset.p-button-danger > .p-button:not(:disabled):active, .p-splitbutton.p-button-danger > .p-button:not(:disabled):active, .p-fileupload-choose.p-button-danger:not(:disabled):active {
    color: #fff;
    background: #dc2626;
    border-color: #dc2626;
  }

  .p-button.p-button-danger.p-button-outlined, .p-buttonset.p-button-danger > .p-button.p-button-outlined, .p-splitbutton.p-button-danger > .p-button.p-button-outlined, .p-fileupload-choose.p-button-danger.p-button-outlined {
    color: #ef4444;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid;
  }

  .p-button.p-button-danger.p-button-outlined:not(:disabled):hover, .p-buttonset.p-button-danger > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:not(:disabled):hover, .p-fileupload-choose.p-button-danger.p-button-outlined:not(:disabled):hover {
    color: #ef4444;
    background: rgba(239, 68, 68, .04);
    border: 1px solid;
  }

  .p-button.p-button-danger.p-button-outlined:not(:disabled):active, .p-buttonset.p-button-danger > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:not(:disabled):active, .p-fileupload-choose.p-button-danger.p-button-outlined:not(:disabled):active {
    color: #ef4444;
    background: rgba(239, 68, 68, .16);
    border: 1px solid;
  }

  .p-button.p-button-danger.p-button-text, .p-buttonset.p-button-danger > .p-button.p-button-text, .p-splitbutton.p-button-danger > .p-button.p-button-text, .p-fileupload-choose.p-button-danger.p-button-text {
    color: #ef4444;
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-button.p-button-danger.p-button-text:not(:disabled):hover, .p-buttonset.p-button-danger > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-danger > .p-button.p-button-text:not(:disabled):hover, .p-fileupload-choose.p-button-danger.p-button-text:not(:disabled):hover {
    color: #ef4444;
    background: rgba(239, 68, 68, .04);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-button.p-button-danger.p-button-text:not(:disabled):active, .p-buttonset.p-button-danger > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-danger > .p-button.p-button-text:not(:disabled):active, .p-fileupload-choose.p-button-danger.p-button-text:not(:disabled):active {
    color: #ef4444;
    background: rgba(239, 68, 68, .16);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-button.p-button-link {
    color: #4f46e5;
    background: none;
    border: rgba(0, 0, 0, 0);
  }

  .p-button.p-button-link:not(:disabled):hover {
    color: #4f46e5;
    background: none;
    border-color: rgba(0, 0, 0, 0);
  }

  .p-button.p-button-link:not(:disabled):hover .p-button-label {
    text-decoration: underline;
  }

  .p-button.p-button-link:not(:disabled):focus {
    background: none;
    border-color: rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 .2rem #6366f1;
  }

  .p-button.p-button-link:not(:disabled):active {
    color: #4f46e5;
    background: none;
    border-color: rgba(0, 0, 0, 0);
  }

  .p-splitbutton {
    border-radius: .375rem;
  }

  .p-splitbutton.p-button-outlined > .p-button {
    color: #4f46e5;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid;
  }

  .p-splitbutton.p-button-outlined > .p-button:not(:disabled):hover {
    color: #4f46e5;
    background: rgba(79, 70, 229, .04);
  }

  .p-splitbutton.p-button-outlined > .p-button:not(:disabled):active {
    color: #4f46e5;
    background: rgba(79, 70, 229, .16);
  }

  .p-splitbutton.p-button-outlined.p-button-plain > .p-button {
    color: #71717a;
    border-color: #71717a;
  }

  .p-splitbutton.p-button-outlined.p-button-plain > .p-button:not(:disabled):hover {
    color: #71717a;
    background: #f4f4f5;
  }

  .p-splitbutton.p-button-outlined.p-button-plain > .p-button:not(:disabled):active {
    color: #71717a;
    background: #e5e7eb;
  }

  .p-splitbutton.p-button-text > .p-button {
    color: #4f46e5;
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-splitbutton.p-button-text > .p-button:not(:disabled):hover {
    color: #4f46e5;
    background: rgba(79, 70, 229, .04);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-splitbutton.p-button-text > .p-button:not(:disabled):active {
    color: #4f46e5;
    background: rgba(79, 70, 229, .16);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-splitbutton.p-button-text.p-button-plain > .p-button {
    color: #71717a;
  }

  .p-splitbutton.p-button-text.p-button-plain > .p-button:not(:disabled):hover {
    color: #71717a;
    background: #f4f4f5;
  }

  .p-splitbutton.p-button-text.p-button-plain > .p-button:not(:disabled):active {
    color: #71717a;
    background: #e5e7eb;
  }

  .p-splitbutton.p-button-raised {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -1px rgba(0, 0, 0, .06);
  }

  .p-splitbutton.p-button-rounded, .p-splitbutton.p-button-rounded > .p-button {
    border-radius: 2rem;
  }

  .p-splitbutton.p-button-sm > .p-button {
    padding: .65625rem .875rem;
    font-size: .875rem;
  }

  .p-splitbutton.p-button-sm > .p-button .p-button-icon {
    font-size: .875rem;
  }

  .p-splitbutton.p-button-lg > .p-button {
    padding: .9375rem 1.25rem;
    font-size: 1.25rem;
  }

  .p-splitbutton.p-button-lg > .p-button.p-button-icon-only {
    width: auto;
  }

  .p-splitbutton.p-button-lg > .p-button .p-button-icon {
    font-size: 1.25rem;
  }

  .p-splitbutton .p-splitbutton-menubutton, .p-splitbutton .p-splitbutton.p-button-rounded > .p-splitbutton-menubutton.p-button, .p-splitbutton .p-splitbutton.p-button-outlined > .p-splitbutton-menubutton.p-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .p-splitbutton.p-button-secondary.p-button-outlined > .p-button {
    color: #64748b;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid;
  }

  .p-splitbutton.p-button-secondary.p-button-outlined > .p-button:not(:disabled):hover {
    color: #64748b;
    background: rgba(100, 116, 139, .04);
  }

  .p-splitbutton.p-button-secondary.p-button-outlined > .p-button:not(:disabled):active {
    color: #64748b;
    background: rgba(100, 116, 139, .16);
  }

  .p-splitbutton.p-button-secondary.p-button-text > .p-button {
    color: #64748b;
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-splitbutton.p-button-secondary.p-button-text > .p-button:not(:disabled):hover {
    color: #64748b;
    background: rgba(100, 116, 139, .04);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-splitbutton.p-button-secondary.p-button-text > .p-button:not(:disabled):active {
    color: #64748b;
    background: rgba(100, 116, 139, .16);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-splitbutton.p-button-info.p-button-outlined > .p-button {
    color: #3b82f6;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid;
  }

  .p-splitbutton.p-button-info.p-button-outlined > .p-button:not(:disabled):hover {
    color: #3b82f6;
    background: rgba(59, 130, 246, .04);
  }

  .p-splitbutton.p-button-info.p-button-outlined > .p-button:not(:disabled):active {
    color: #3b82f6;
    background: rgba(59, 130, 246, .16);
  }

  .p-splitbutton.p-button-info.p-button-text > .p-button {
    color: #3b82f6;
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-splitbutton.p-button-info.p-button-text > .p-button:not(:disabled):hover {
    color: #3b82f6;
    background: rgba(59, 130, 246, .04);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-splitbutton.p-button-info.p-button-text > .p-button:not(:disabled):active {
    color: #3b82f6;
    background: rgba(59, 130, 246, .16);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-splitbutton.p-button-success.p-button-outlined > .p-button {
    color: #22c55e;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid;
  }

  .p-splitbutton.p-button-success.p-button-outlined > .p-button:not(:disabled):hover {
    color: #22c55e;
    background: rgba(34, 197, 94, .04);
  }

  .p-splitbutton.p-button-success.p-button-outlined > .p-button:not(:disabled):active {
    color: #22c55e;
    background: rgba(34, 197, 94, .16);
  }

  .p-splitbutton.p-button-success.p-button-text > .p-button {
    color: #22c55e;
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-splitbutton.p-button-success.p-button-text > .p-button:not(:disabled):hover {
    color: #22c55e;
    background: rgba(34, 197, 94, .04);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-splitbutton.p-button-success.p-button-text > .p-button:not(:disabled):active {
    color: #22c55e;
    background: rgba(34, 197, 94, .16);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-splitbutton.p-button-warning.p-button-outlined > .p-button {
    color: #f59e0b;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid;
  }

  .p-splitbutton.p-button-warning.p-button-outlined > .p-button:not(:disabled):hover {
    color: #f59e0b;
    background: rgba(245, 158, 11, .04);
  }

  .p-splitbutton.p-button-warning.p-button-outlined > .p-button:not(:disabled):active {
    color: #f59e0b;
    background: rgba(245, 158, 11, .16);
  }

  .p-splitbutton.p-button-warning.p-button-text > .p-button {
    color: #f59e0b;
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-splitbutton.p-button-warning.p-button-text > .p-button:not(:disabled):hover {
    color: #f59e0b;
    background: rgba(245, 158, 11, .04);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-splitbutton.p-button-warning.p-button-text > .p-button:not(:disabled):active {
    color: #f59e0b;
    background: rgba(245, 158, 11, .16);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-splitbutton.p-button-help.p-button-outlined > .p-button {
    color: #a855f7;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid;
  }

  .p-splitbutton.p-button-help.p-button-outlined > .p-button:not(:disabled):hover {
    color: #a855f7;
    background: rgba(168, 85, 247, .04);
  }

  .p-splitbutton.p-button-help.p-button-outlined > .p-button:not(:disabled):active {
    color: #a855f7;
    background: rgba(168, 85, 247, .16);
  }

  .p-splitbutton.p-button-help.p-button-text > .p-button {
    color: #a855f7;
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-splitbutton.p-button-help.p-button-text > .p-button:not(:disabled):hover {
    color: #a855f7;
    background: rgba(168, 85, 247, .04);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-splitbutton.p-button-help.p-button-text > .p-button:not(:disabled):active {
    color: #a855f7;
    background: rgba(168, 85, 247, .16);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-splitbutton.p-button-danger.p-button-outlined > .p-button {
    color: #ef4444;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid;
  }

  .p-splitbutton.p-button-danger.p-button-outlined > .p-button:not(:disabled):hover {
    color: #ef4444;
    background: rgba(239, 68, 68, .04);
  }

  .p-splitbutton.p-button-danger.p-button-outlined > .p-button:not(:disabled):active {
    color: #ef4444;
    background: rgba(239, 68, 68, .16);
  }

  .p-splitbutton.p-button-danger.p-button-text > .p-button {
    color: #ef4444;
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-splitbutton.p-button-danger.p-button-text > .p-button:not(:disabled):hover {
    color: #ef4444;
    background: rgba(239, 68, 68, .04);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-splitbutton.p-button-danger.p-button-text > .p-button:not(:disabled):active {
    color: #ef4444;
    background: rgba(239, 68, 68, .16);
    border-color: rgba(0, 0, 0, 0);
  }

  .p-speeddial-button.p-button.p-button-icon-only {
    width: 4rem;
    height: 4rem;
  }

  .p-speeddial-button.p-button.p-button-icon-only .p-button-icon {
    font-size: 1.3rem;
  }

  .p-speeddial-button.p-button.p-button-icon-only .p-button-icon.p-icon {
    width: 1.3rem;
    height: 1.3rem;
  }

  .p-speeddial-list {
    outline: 0;
  }

  .p-speeddial-action {
    color: #fff;
    background: #3f3f46;
    width: 3rem;
    height: 3rem;
  }

  .p-speeddial-action:hover {
    color: #fff;
    background: #27272a;
  }

  .p-speeddial-direction-up .p-speeddial-item {
    margin: .25rem;
  }

  .p-speeddial-direction-up .p-speeddial-item:first-child {
    margin-bottom: .5rem;
  }

  .p-speeddial-direction-down .p-speeddial-item {
    margin: .25rem;
  }

  .p-speeddial-direction-down .p-speeddial-item:first-child {
    margin-top: .5rem;
  }

  .p-speeddial-direction-left .p-speeddial-item {
    margin: 0 .25rem;
  }

  .p-speeddial-direction-left .p-speeddial-item:first-child {
    margin-right: .5rem;
  }

  .p-speeddial-direction-right .p-speeddial-item {
    margin: 0 .25rem;
  }

  .p-speeddial-direction-right .p-speeddial-item:first-child {
    margin-left: .5rem;
  }

  .p-speeddial-circle .p-speeddial-item, .p-speeddial-semi-circle .p-speeddial-item, .p-speeddial-quarter-circle .p-speeddial-item, .p-speeddial-circle .p-speeddial-item:first-child, .p-speeddial-circle .p-speeddial-item:last-child, .p-speeddial-semi-circle .p-speeddial-item:first-child, .p-speeddial-semi-circle .p-speeddial-item:last-child, .p-speeddial-quarter-circle .p-speeddial-item:first-child, .p-speeddial-quarter-circle .p-speeddial-item:last-child {
    margin: 0;
  }

  .p-speeddial-mask {
    background-color: rgba(0, 0, 0, .4);
    border-radius: .375rem;
  }

  .p-carousel .p-carousel-content .p-carousel-prev, .p-carousel .p-carousel-content .p-carousel-next {
    color: #71717a;
    background: none;
    border: 0;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    margin: .5rem;
    transition: none;
  }

  .p-carousel .p-carousel-content .p-carousel-prev:enabled:hover, .p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
    color: #18181b;
    background: #f4f4f5;
    border-color: rgba(0, 0, 0, 0);
  }

  .p-carousel .p-carousel-content .p-carousel-prev:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-carousel .p-carousel-content .p-carousel-next:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-carousel .p-carousel-indicators {
    padding: 1rem;
  }

  .p-carousel .p-carousel-indicators .p-carousel-indicator {
    margin-bottom: .5rem;
    margin-right: .5rem;
  }

  .p-carousel .p-carousel-indicators .p-carousel-indicator button {
    background-color: #f4f4f5;
    border-radius: 0;
    width: 2rem;
    height: .5rem;
    transition: none;
  }

  .p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
    background: #e5e7eb;
  }

  .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    color: #312e81;
    background: #eef2ff;
  }

  .p-datatable .p-paginator-top, .p-datatable .p-paginator-bottom {
    border-width: 0 0 1px;
    border-radius: 0;
  }

  .p-datatable .p-datatable-header {
    color: #3f3f46;
    background: #fafafa;
    border: 1px solid #f4f4f5;
    border-width: 1px 0;
    padding: 1.25rem;
    font-weight: 600;
  }

  .p-datatable .p-datatable-footer {
    color: #3f3f46;
    background: #fafafa;
    border: 0 solid #f4f4f5;
    border-bottom-width: 1px;
    padding: .75rem 1.5rem;
    font-weight: 600;
  }

  .p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    color: #6b7280;
    background: #fafafa;
    border: 0 solid #f4f4f5;
    border-bottom-width: 1px;
    padding: .75rem 1.5rem;
    font-weight: 500;
    transition: none;
  }

  .p-datatable .p-datatable-tfoot > tr > td {
    text-align: left;
    color: #3f3f46;
    background: #fafafa;
    border: 0 solid #f4f4f5;
    border-bottom-width: 1px;
    padding: 1rem;
    font-weight: 600;
  }

  .p-datatable .p-sortable-column .p-sortable-column-icon {
    color: #71717a;
    margin-left: .5rem;
  }

  .p-datatable .p-sortable-column .p-sortable-column-badge {
    color: #312e81;
    background: #eef2ff;
    border-radius: 50%;
    min-width: 1.143rem;
    height: 1.143rem;
    margin-left: .5rem;
    line-height: 1.143rem;
  }

  .p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
    color: #3f3f46;
    background: #f4f4f5;
  }

  .p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover .p-sortable-column-icon {
    color: #71717a;
  }

  .p-datatable .p-sortable-column.p-highlight {
    color: #4f46e5;
    background: #fafafa;
  }

  .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #4f46e5;
  }

  .p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
    color: #4f46e5;
    background: #f4f4f5;
  }

  .p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
    color: #4f46e5;
  }

  .p-datatable .p-sortable-column:focus-visible {
    outline: 0;
    box-shadow: inset 0 0 0 .15rem #6366f1;
  }

  .p-datatable .p-datatable-tbody > tr {
    color: #3f3f46;
    background: #fff;
    transition: none;
  }

  .p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 0 solid #f4f4f5;
    border-bottom-width: 1px;
    padding: 1rem 1.5rem;
  }

  .p-datatable .p-datatable-tbody > tr > td .p-row-toggler, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
    color: #71717a;
    background: none;
    border: 0;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    transition: none;
  }

  .p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
    color: #18181b;
    background: #f4f4f5;
    border-color: rgba(0, 0, 0, 0);
  }

  .p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
    margin-right: .5rem;
  }

  .p-datatable .p-datatable-tbody > tr > td > .p-column-title {
    font-weight: 500;
  }

  .p-datatable .p-datatable-tbody > tr > td.p-highlight, .p-datatable .p-datatable-tbody > tr.p-highlight {
    color: #312e81;
    background: #eef2ff;
  }

  .p-datatable .p-datatable-tbody > tr.p-highlight-contextmenu {
    outline-offset: -.15rem;
    outline: .15rem solid #6366f1;
  }

  .p-datatable.p-datatable-selectable .p-datatable-tbody > tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover {
    color: #3f3f46;
    background: #f4f4f5;
  }

  .p-datatable.p-datatable-selectable .p-datatable-tbody > tr.p-selectable-row:focus-visible {
    outline-offset: -.15rem;
    outline: .15rem solid #6366f1;
  }

  .p-datatable.p-datatable-selectable-cell .p-datatable-tbody > tr.p-selectable-row > td.p-selectable-cell:not(.p-highlight):hover {
    color: #3f3f46;
    background: #f4f4f5;
  }

  .p-datatable.p-datatable-selectable-cell .p-datatable-tbody > tr.p-selectable-row > td.p-selectable-cell:focus-visible {
    outline-offset: -.15rem;
    outline: .15rem solid #6366f1;
  }

  .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):not(.p-datatable-emptymessage):hover {
    color: #3f3f46;
    background: #f4f4f5;
  }

  .p-datatable .p-column-resizer-helper {
    background: #4f46e5;
  }

  .p-datatable .p-datatable-scrollable-header, .p-datatable .p-datatable-scrollable-footer {
    background: #fafafa;
  }

  .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead, .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-tfoot, .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-virtualscroller > .p-datatable-table > .p-datatable-thead, .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-virtualscroller > .p-datatable-table > .p-datatable-tfoot {
    background-color: #fafafa;
  }

  .p-datatable .p-datatable-loading-icon {
    font-size: 2rem;
  }

  .p-datatable .p-datatable-loading-icon.p-icon {
    width: 2rem;
    height: 2rem;
  }

  .p-datatable.p-datatable-gridlines .p-datatable-header {
    border-width: 1px 1px 0;
  }

  .p-datatable.p-datatable-gridlines .p-datatable-footer {
    border-width: 0 1px 1px;
  }

  .p-datatable.p-datatable-gridlines .p-paginator-top {
    border-width: 0 1px;
  }

  .p-datatable.p-datatable-gridlines .p-paginator-bottom {
    border-width: 0 1px 1px;
  }

  .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
    border-width: 1px 0 1px 1px;
  }

  .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th:last-child {
    border-width: 1px;
  }

  .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
    border-width: 1px 0 0 1px;
  }

  .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td:last-child {
    border-width: 1px 1px 0;
  }

  .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td {
    border-width: 1px 0 1px 1px;
  }

  .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td:last-child {
    border-width: 1px;
  }

  .p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
    border-width: 1px 0 1px 1px;
  }

  .p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td:last-child {
    border-width: 1px;
  }

  .p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td {
    border-width: 0 0 1px 1px;
  }

  .p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td:last-child {
    border-width: 0 1px 1px;
  }

  .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td {
    border-width: 0 0 1px 1px;
  }

  .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td:last-child {
    border-width: 0 1px 1px;
  }

  .p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td {
    border-width: 0 0 0 1px;
  }

  .p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td:last-child {
    border-width: 0 1px;
  }

  .p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
    background: #fcfcfc;
  }

  .p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight {
    color: #312e81;
    background: #eef2ff;
  }

  .p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight .p-row-toggler, .p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight .p-row-toggler:hover {
    color: #312e81;
  }

  .p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd + .p-row-expanded {
    background: #fcfcfc;
  }

  .p-datatable.p-datatable-sm .p-datatable-header {
    padding: .625rem;
  }

  .p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
    padding: .375rem .75rem;
  }

  .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
    padding: .5rem .75rem;
  }

  .p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td, .p-datatable.p-datatable-sm .p-datatable-footer {
    padding: .375rem .75rem;
  }

  .p-datatable.p-datatable-lg .p-datatable-header {
    padding: 1.5625rem;
  }

  .p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
    padding: .9375rem 1.875rem;
  }

  .p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
    padding: 1.25rem 1.875rem;
  }

  .p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td, .p-datatable.p-datatable-lg .p-datatable-footer {
    padding: .9375rem 1.875rem;
  }

  .p-datatable-drag-selection-helper {
    background: rgba(79, 70, 229, .16);
  }

  .p-dataview .p-paginator-top, .p-dataview .p-paginator-bottom {
    border-width: 0 0 1px;
    border-radius: 0;
  }

  .p-dataview .p-dataview-header {
    color: #3f3f46;
    background: #fafafa;
    border: 1px solid #f4f4f5;
    border-width: 1px 0;
    padding: 1.25rem;
    font-weight: 600;
  }

  .p-dataview .p-dataview-content {
    color: #3f3f46;
    background: #fff;
    border: 0;
    padding: 0;
  }

  .p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
    border: 0 solid #f4f4f5;
    border-bottom-width: 1px;
  }

  .p-dataview .p-dataview-footer {
    color: #3f3f46;
    background: #fafafa;
    border: 0 solid #f4f4f5;
    border-bottom-width: 1px;
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem;
    padding: .75rem 1.5rem;
    font-weight: 600;
  }

  .p-dataview .p-dataview-loading-icon {
    font-size: 2rem;
  }

  .p-dataview .p-dataview-loading-icon.p-icon {
    width: 2rem;
    height: 2rem;
  }

  .p-datascroller .p-paginator-top, .p-datascroller .p-paginator-bottom {
    border-width: 0 0 1px;
    border-radius: 0;
  }

  .p-datascroller .p-datascroller-header {
    color: #3f3f46;
    background: #fafafa;
    border: 1px solid #f4f4f5;
    border-width: 1px 0;
    padding: 1.25rem;
    font-weight: 600;
  }

  .p-datascroller .p-datascroller-content {
    color: #3f3f46;
    background: #fff;
    border: 0;
    padding: 0;
  }

  .p-datascroller.p-datascroller-inline .p-datascroller-list > li {
    border: 0 solid #f4f4f5;
    border-bottom-width: 1px;
  }

  .p-datascroller .p-datascroller-footer {
    color: #3f3f46;
    background: #fafafa;
    border: 0 solid #f4f4f5;
    border-bottom-width: 1px;
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem;
    padding: .75rem 1.5rem;
    font-weight: 600;
  }

  .p-column-filter-row .p-column-filter-menu-button, .p-column-filter-row .p-column-filter-clear-button {
    margin-left: .5rem;
  }

  .p-column-filter-menu-button {
    color: #71717a;
    background: none;
    border: 0;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    transition: none;
  }

  .p-column-filter-menu-button:hover {
    color: #18181b;
    background: #f4f4f5;
    border-color: rgba(0, 0, 0, 0);
  }

  .p-column-filter-menu-button.p-column-filter-menu-button-open, .p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
    color: #18181b;
    background: #f4f4f5;
  }

  .p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
    color: #312e81;
    background: #eef2ff;
  }

  .p-column-filter-menu-button:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-column-filter-clear-button {
    color: #71717a;
    background: none;
    border: 0;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    transition: none;
  }

  .p-column-filter-clear-button:hover {
    color: #18181b;
    background: #f4f4f5;
    border-color: rgba(0, 0, 0, 0);
  }

  .p-column-filter-clear-button:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-column-filter-overlay {
    color: #3f3f46;
    background: #fff;
    border: 0;
    border-radius: .375rem;
    min-width: 12.5rem;
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
  }

  .p-column-filter-overlay .p-column-filter-row-items {
    padding: .25rem 0;
  }

  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
    color: #3f3f46;
    background: none;
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: .75rem 1rem;
    transition: none;
  }

  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
    color: #312e81;
    background: #eef2ff;
  }

  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
    color: #18181b;
    background: #f4f4f5;
  }

  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: inset 0 0 0 .15rem #6366f1;
  }

  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
    border-top: 1px solid #f3f4f6;
    margin: .25rem 0;
  }

  .p-column-filter-overlay-menu .p-column-filter-operator {
    color: #3f3f46;
    background: #fafafa;
    border-bottom: 0;
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
    margin: 0;
    padding: .5rem .75rem;
  }

  .p-column-filter-overlay-menu .p-column-filter-constraint {
    border-bottom: 1px solid #f3f4f6;
    padding: 1.25rem;
  }

  .p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
    margin-bottom: .5rem;
  }

  .p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
    margin-top: .5rem;
  }

  .p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
    border-bottom: 0;
  }

  .p-column-filter-overlay-menu .p-column-filter-add-rule {
    padding: .75rem 1.25rem;
  }

  .p-column-filter-overlay-menu .p-column-filter-buttonbar, .p-orderlist .p-orderlist-controls {
    padding: 1.25rem;
  }

  .p-orderlist .p-orderlist-controls .p-button {
    margin-bottom: .5rem;
  }

  .p-orderlist .p-orderlist-header {
    color: #3f3f46;
    background: #fafafa;
    border: 1px solid #e5e7eb;
    border-bottom: 0;
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
    padding: 1.25rem;
    font-weight: 700;
  }

  .p-orderlist .p-orderlist-filter-container {
    background: #fff;
    border: 1px solid #e5e7eb;
    border-bottom: 0;
    padding: 1.25rem;
  }

  .p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
    padding-right: 1.75rem;
  }

  .p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
    color: #71717a;
    right: .75rem;
  }

  .p-orderlist .p-orderlist-list {
    color: #3f3f46;
    background: #fff;
    border: 1px solid #e5e7eb;
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem;
    outline: 0;
    padding: .25rem 0;
  }

  .p-orderlist .p-orderlist-list .p-orderlist-item {
    color: #3f3f46;
    background: none;
    border: 0;
    margin: 0;
    padding: .75rem 1rem;
    transition: transform .2s, none;
  }

  .p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
    color: #18181b;
    background: #f4f4f5;
  }

  .p-orderlist .p-orderlist-list .p-orderlist-item.p-focus {
    color: #18181b;
    background: #e5e7eb;
  }

  .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
    color: #312e81;
    background: #eef2ff;
  }

  .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight.p-focus {
    background: #e0e7ff;
  }

  .p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(2n) {
    background: #fcfcfc;
  }

  .p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(2n):hover {
    background: #f4f4f5;
  }

  .p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
    color: #18181b;
    background: #f4f4f5;
  }

  .p-organizationchart .p-organizationchart-node-content.p-highlight {
    color: #312e81;
    background: #eef2ff;
  }

  .p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
    color: #6f91ff;
  }

  .p-organizationchart .p-organizationchart-line-down {
    background: #e5e7eb;
  }

  .p-organizationchart .p-organizationchart-line-left {
    border-color: #e5e7eb;
    border-right-style: solid;
    border-right-width: 1px;
  }

  .p-organizationchart .p-organizationchart-line-top {
    border-color: #e5e7eb;
    border-top-style: solid;
    border-top-width: 1px;
  }

  .p-organizationchart .p-organizationchart-node-content {
    color: #3f3f46;
    background: #fff;
    border: 1px solid #e5e7eb;
    padding: 1.25rem;
  }

  .p-organizationchart .p-organizationchart-node-content .p-node-toggler {
    background: inherit;
    color: inherit;
    border-radius: 50%;
  }

  .p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-paginator {
    color: #71717a;
    background: #fff;
    border: 0 solid #f4f4f5;
    border-radius: .375rem;
    padding: .75rem 1.5rem;
  }

  .p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
    color: #71717a;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #d4d4d8;
    border-radius: 0;
    min-width: 3rem;
    height: 3rem;
    margin: 0 0 0 -1px;
    transition: none;
  }

  .p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
    color: #3f3f46;
    background: #f4f4f5;
    border-color: #d4d4d8;
  }

  .p-paginator .p-paginator-first {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .p-paginator .p-paginator-last {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .p-paginator .p-dropdown {
    height: 3rem;
    margin-left: .5rem;
  }

  .p-paginator .p-dropdown .p-dropdown-label {
    padding-right: 0;
  }

  .p-paginator .p-paginator-page-input {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .p-paginator .p-paginator-page-input .p-inputtext {
    max-width: 3rem;
  }

  .p-paginator .p-paginator-current {
    color: #71717a;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #d4d4d8;
    min-width: 3rem;
    height: 3rem;
    margin: 0 0 0 -1px;
    padding: 0 .5rem;
  }

  .p-paginator .p-paginator-pages .p-paginator-page {
    color: #71717a;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #d4d4d8;
    border-radius: 0;
    min-width: 3rem;
    height: 3rem;
    margin: 0 0 0 -1px;
    transition: none;
  }

  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    color: #312e81;
    background: #eef2ff;
    border-color: #eef2ff;
  }

  .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
    color: #3f3f46;
    background: #f4f4f5;
    border-color: #d4d4d8;
  }

  .p-picklist .p-picklist-buttons {
    padding: 1.25rem;
  }

  .p-picklist .p-picklist-buttons .p-button {
    margin-bottom: .5rem;
  }

  .p-picklist .p-picklist-header {
    color: #3f3f46;
    background: #fafafa;
    border: 1px solid #e5e7eb;
    border-bottom: 0;
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
    padding: 1.25rem;
    font-weight: 700;
  }

  .p-picklist .p-picklist-filter-container {
    background: #fff;
    border: 1px solid #e5e7eb;
    border-bottom: 0;
    padding: 1.25rem;
  }

  .p-picklist .p-picklist-filter-container .p-picklist-filter-input {
    padding-right: 1.75rem;
  }

  .p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
    color: #71717a;
    right: .75rem;
  }

  .p-picklist .p-picklist-list {
    color: #3f3f46;
    background: #fff;
    border: 1px solid #e5e7eb;
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem;
    outline: 0;
    padding: .25rem 0;
  }

  .p-picklist .p-picklist-list .p-picklist-item {
    color: #3f3f46;
    background: none;
    border: 0;
    margin: 0;
    padding: .75rem 1rem;
    transition: transform .2s, none;
  }

  .p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
    color: #18181b;
    background: #f4f4f5;
  }

  .p-picklist .p-picklist-list .p-picklist-item.p-focus {
    color: #18181b;
    background: #e5e7eb;
  }

  .p-picklist .p-picklist-list .p-picklist-item.p-highlight {
    color: #312e81;
    background: #eef2ff;
  }

  .p-picklist .p-picklist-list .p-picklist-item.p-highlight.p-focus {
    background: #e0e7ff;
  }

  .p-tree {
    color: #3f3f46;
    background: #fff;
    border: 1px solid #e5e7eb;
    border-radius: .375rem;
    padding: 1.25rem;
  }

  .p-tree .p-tree-container .p-treenode {
    outline: 0;
    padding: .143rem;
  }

  .p-tree .p-tree-container .p-treenode:focus > .p-treenode-content {
    outline-offset: 0;
    outline: 0;
    box-shadow: inset 0 0 0 .15rem #6366f1;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content {
    border-radius: .375rem;
    padding: 0;
    transition: none;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
    color: #71717a;
    background: none;
    border: 0;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    margin-right: .5rem;
    transition: none;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
    color: #18181b;
    background: #f4f4f5;
    border-color: rgba(0, 0, 0, 0);
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
    color: #71717a;
    margin-right: .5rem;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
    margin-right: .5rem;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
    color: #3f3f46;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    color: #312e81;
    background: #eef2ff;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler, .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon, .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover, .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
    color: #312e81;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover, .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
    color: #18181b;
    background: #f4f4f5;
  }

  .p-tree .p-tree-filter-container {
    margin-bottom: .5rem;
  }

  .p-tree .p-tree-filter-container .p-tree-filter {
    width: 100%;
    padding-right: 1.75rem;
  }

  .p-tree .p-tree-filter-container .p-tree-filter-icon {
    color: #71717a;
    right: .75rem;
  }

  .p-tree .p-treenode-children {
    padding: 0 0 0 1rem;
  }

  .p-tree .p-tree-loading-icon {
    font-size: 2rem;
  }

  .p-tree .p-tree-loading-icon.p-icon {
    width: 2rem;
    height: 2rem;
  }

  .p-tree .p-treenode-droppoint {
    height: .5rem;
  }

  .p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
    background: #8ba7ff;
  }

  .p-treetable .p-paginator-top, .p-treetable .p-paginator-bottom {
    border-width: 0 0 1px;
    border-radius: 0;
  }

  .p-treetable .p-treetable-header {
    color: #3f3f46;
    background: #fafafa;
    border: 1px solid #f4f4f5;
    border-width: 1px 0;
    padding: 1.25rem;
    font-weight: 600;
  }

  .p-treetable .p-treetable-footer {
    color: #3f3f46;
    background: #fafafa;
    border: 0 solid #f4f4f5;
    border-bottom-width: 1px;
    padding: .75rem 1.5rem;
    font-weight: 600;
  }

  .p-treetable .p-treetable-thead > tr > th {
    text-align: left;
    color: #6b7280;
    background: #fafafa;
    border: 0 solid #f4f4f5;
    border-bottom-width: 1px;
    padding: .75rem 1.5rem;
    font-weight: 500;
    transition: none;
  }

  .p-treetable .p-treetable-tfoot > tr > td {
    text-align: left;
    color: #3f3f46;
    background: #fafafa;
    border: 0 solid #f4f4f5;
    border-bottom-width: 1px;
    padding: 1rem;
    font-weight: 600;
  }

  .p-treetable .p-sortable-column {
    outline-color: #6366f1;
  }

  .p-treetable .p-sortable-column .p-sortable-column-icon {
    color: #71717a;
    margin-left: .5rem;
  }

  .p-treetable .p-sortable-column .p-sortable-column-badge {
    color: #312e81;
    background: #eef2ff;
    border-radius: 50%;
    min-width: 1.143rem;
    height: 1.143rem;
    margin-left: .5rem;
    line-height: 1.143rem;
  }

  .p-treetable .p-sortable-column:not(.p-highlight):hover {
    color: #3f3f46;
    background: #f4f4f5;
  }

  .p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
    color: #71717a;
  }

  .p-treetable .p-sortable-column.p-highlight {
    color: #4f46e5;
    background: #fafafa;
  }

  .p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #4f46e5;
  }

  .p-treetable .p-treetable-tbody > tr {
    color: #3f3f46;
    background: #fff;
    transition: none;
  }

  .p-treetable .p-treetable-tbody > tr > td {
    text-align: left;
    border: 0 solid #f4f4f5;
    border-bottom-width: 1px;
    padding: 1rem 1.5rem;
  }

  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
    color: #71717a;
    background: none;
    border: 0;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    margin-right: .5rem;
    transition: none;
  }

  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
    color: #18181b;
    background: #f4f4f5;
    border-color: rgba(0, 0, 0, 0);
  }

  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler + .p-checkbox {
    margin-right: .5rem;
  }

  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler + .p-checkbox .p-indeterminate .p-checkbox-icon {
    color: #3f3f46;
  }

  .p-treetable .p-treetable-tbody > tr:focus-visible {
    outline-offset: -.15rem;
    outline: .15rem solid #6366f1;
  }

  .p-treetable .p-treetable-tbody > tr.p-highlight {
    color: #312e81;
    background: #eef2ff;
  }

  .p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler, .p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
    color: #312e81;
  }

  .p-treetable.p-treetable-selectable .p-treetable-tbody > tr:not(.p-highlight):hover, .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
    color: #3f3f46;
    background: #f4f4f5;
  }

  .p-treetable.p-treetable-selectable .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler, .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler {
    color: #3f3f46;
  }

  .p-treetable .p-column-resizer-helper {
    background: #4f46e5;
  }

  .p-treetable .p-treetable-scrollable-header, .p-treetable .p-treetable-scrollable-footer {
    background: #fafafa;
  }

  .p-treetable .p-treetable-loading-icon {
    font-size: 2rem;
  }

  .p-treetable .p-treetable-loading-icon.p-icon {
    width: 2rem;
    height: 2rem;
  }

  .p-treetable.p-treetable-gridlines .p-treetable-header {
    border-width: 1px 1px 0;
  }

  .p-treetable.p-treetable-gridlines .p-treetable-footer {
    border-width: 0 1px 1px;
  }

  .p-treetable.p-treetable-gridlines .p-treetable-top {
    border-width: 0 1px;
  }

  .p-treetable.p-treetable-gridlines .p-treetable-bottom {
    border-width: 0 1px 1px;
  }

  .p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th, .p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td, .p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
    border-width: 1px;
  }

  .p-treetable.p-treetable-striped .p-treetable-tbody > tr.p-row-odd {
    background: #fcfcfc;
  }

  .p-treetable.p-treetable-striped .p-treetable-tbody > tr.p-row-odd.p-highlight {
    color: #312e81;
    background: #eef2ff;
  }

  .p-treetable.p-treetable-striped .p-treetable-tbody > tr.p-row-odd.p-highlight .p-row-toggler, .p-treetable.p-treetable-striped .p-treetable-tbody > tr.p-row-odd.p-highlight .p-row-toggler:hover {
    color: #312e81;
  }

  .p-treetable.p-treetable-striped .p-treetable-tbody > tr.p-row-odd + .p-row-expanded {
    background: #fcfcfc;
  }

  .p-treetable.p-treetable-sm .p-treetable-header {
    padding: 1.09375rem;
  }

  .p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
    padding: .375rem .75rem;
  }

  .p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
    padding: .5rem .75rem;
  }

  .p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td, .p-treetable.p-treetable-sm .p-treetable-footer {
    padding: .375rem .75rem;
  }

  .p-treetable.p-treetable-lg .p-treetable-header {
    padding: 1.5625rem;
  }

  .p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
    padding: .9375rem 1.875rem;
  }

  .p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
    padding: 1.25rem 1.875rem;
  }

  .p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td, .p-treetable.p-treetable-lg .p-treetable-footer {
    padding: .9375rem 1.875rem;
  }

  .p-timeline .p-timeline-event-marker {
    background-color: #fff;
    border: 2px solid #4f46e5;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
  }

  .p-timeline .p-timeline-event-connector {
    background-color: #e5e7eb;
  }

  .p-timeline.p-timeline-vertical .p-timeline-event-opposite, .p-timeline.p-timeline-vertical .p-timeline-event-content {
    padding: 0 1rem;
  }

  .p-timeline.p-timeline-vertical .p-timeline-event-connector {
    width: 2px;
  }

  .p-timeline.p-timeline-horizontal .p-timeline-event-opposite, .p-timeline.p-timeline-horizontal .p-timeline-event-content {
    padding: 1rem 0;
  }

  .p-timeline.p-timeline-horizontal .p-timeline-event-connector {
    height: 2px;
  }

  .p-accordion .p-accordion-header .p-accordion-header-link {
    color: #3f3f46;
    background: #fafafa;
    border: 1px solid #e5e7eb;
    border-radius: .375rem;
    padding: 1.25rem;
    font-weight: 700;
    transition: none;
  }

  .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
    margin-right: .5rem;
  }

  .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
    color: #3f3f46;
    background: #f4f4f5;
    border-color: #e5e7eb;
  }

  .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    color: #3f3f46;
    background: #fafafa;
    border-color: #e5e7eb;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    color: #3f3f46;
    background: #f4f4f5;
    border-color: #e5e7eb;
  }

  .p-accordion .p-accordion-content {
    color: #3f3f46;
    background: #fff;
    border: 1px solid #e5e7eb;
    border-top: 0;
    border-radius: 0 0 .375rem .375rem;
    padding: 1.25rem;
  }

  .p-accordion .p-accordion-tab {
    margin-bottom: 0;
  }

  .p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link {
    border-radius: 0;
  }

  .p-accordion .p-accordion-tab .p-accordion-content {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header .p-accordion-header-link, .p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link, .p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    border-top: 0;
  }

  .p-accordion .p-accordion-tab:first-child .p-accordion-header .p-accordion-header-link {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
  }

  .p-accordion .p-accordion-tab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link, .p-accordion .p-accordion-tab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-content {
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .p-card {
    color: #3f3f46;
    background: #fff;
    border-radius: .375rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .1), 0 1px 2px rgba(0, 0, 0, .06);
  }

  .p-card .p-card-body {
    padding: 1.25rem;
  }

  .p-card .p-card-title {
    margin-bottom: .5rem;
    font-size: 1.5rem;
    font-weight: 700;
  }

  .p-card .p-card-subtitle {
    color: #71717a;
    margin-bottom: .5rem;
    font-weight: 400;
  }

  .p-card .p-card-content {
    padding: 1.25rem 0;
  }

  .p-card .p-card-footer {
    padding: 1.25rem 0 0;
  }

  .p-fieldset {
    color: #3f3f46;
    background: #fff;
    border: 1px solid #e5e7eb;
    border-radius: .375rem;
  }

  .p-fieldset .p-fieldset-legend {
    color: #3f3f46;
    background: #fafafa;
    border: 1px solid #e5e7eb;
    border-radius: .375rem;
    padding: 1.25rem;
    font-weight: 700;
  }

  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
    padding: 0;
    transition: none;
  }

  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
    color: #3f3f46;
    border-radius: .375rem;
    padding: 1.25rem;
    transition: none;
  }

  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
    margin-right: .5rem;
  }

  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
    color: #18181b;
    background: #f4f4f5;
    border-color: #e5e7eb;
  }

  .p-fieldset .p-fieldset-content {
    padding: 1.25rem;
  }

  .p-divider .p-divider-content {
    background-color: #fff;
  }

  .p-divider.p-divider-horizontal {
    margin: 1rem 0;
    padding: 0 1rem;
  }

  .p-divider.p-divider-horizontal:before {
    border-top: 1px #e5e7eb;
  }

  .p-divider.p-divider-horizontal .p-divider-content {
    padding: 0 .5rem;
  }

  .p-divider.p-divider-vertical {
    margin: 0 1rem;
    padding: 1rem 0;
  }

  .p-divider.p-divider-vertical:before {
    border-left: 1px #e5e7eb;
  }

  .p-divider.p-divider-vertical .p-divider-content {
    padding: .5rem 0;
  }

  .p-panel .p-panel-header {
    color: #3f3f46;
    background: #fafafa;
    border: 1px solid #e5e7eb;
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
    padding: 1.25rem;
  }

  .p-panel .p-panel-header .p-panel-title {
    font-weight: 700;
  }

  .p-panel .p-panel-header .p-panel-header-icon {
    color: #71717a;
    background: none;
    border: 0;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    transition: none;
  }

  .p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
    color: #18181b;
    background: #f4f4f5;
    border-color: rgba(0, 0, 0, 0);
  }

  .p-panel .p-panel-header .p-panel-header-icon:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-panel.p-panel-toggleable .p-panel-header {
    padding: .75rem 1.25rem;
  }

  .p-panel .p-panel-content {
    color: #3f3f46;
    background: #fff;
    border: 1px solid #e5e7eb;
    border-top: 0;
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem;
    padding: 1.25rem;
  }

  .p-panel .p-panel-footer {
    color: #3f3f46;
    background: #fff;
    border: 1px solid #e5e7eb;
    border-top: 0;
    padding: .75rem 1.25rem;
  }

  .p-splitter {
    color: #3f3f46;
    background: #fff;
    border: 1px solid #e5e7eb;
    border-radius: .375rem;
  }

  .p-splitter .p-splitter-gutter {
    background: #fafafa;
    transition: none;
  }

  .p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
    background: #e5e7eb;
  }

  .p-splitter .p-splitter-gutter .p-splitter-gutter-handle:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-splitter .p-splitter-gutter-resizing {
    background: #e5e7eb;
  }

  .p-scrollpanel .p-scrollpanel-bar {
    background: #fafafa;
    border: 0;
  }

  .p-scrollpanel .p-scrollpanel-bar:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-tabview .p-tabview-nav {
    background: #fff;
    border: 0 solid #e5e7eb;
    border-bottom-width: 2px;
  }

  .p-tabview .p-tabview-nav li {
    margin-right: 0;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    color: #71717a;
    background: #fff;
    border: 0 solid rgba(0, 0, 0, 0);
    border-bottom: 2px solid #e5e7eb;
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
    margin: 0 0 -2px;
    padding: 1.25rem;
    font-weight: 700;
    transition: none;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: inset 0 0 0 1px #6366f1;
  }

  .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
    color: #71717a;
    background: #fff;
    border-color: #71717a;
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    color: #4f46e5;
    background: #fff;
    border-color: #4f46e5;
  }

  .p-tabview .p-tabview-close {
    margin-left: .5rem;
  }

  .p-tabview .p-tabview-nav-btn.p-link {
    color: #4f46e5;
    background: #fff;
    border-radius: 0;
    width: 3rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -1px rgba(0, 0, 0, .06);
  }

  .p-tabview .p-tabview-nav-btn.p-link:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: inset 0 0 0 1px #6366f1;
  }

  .p-tabview .p-tabview-panels {
    color: #3f3f46;
    background: #fff;
    border: 0;
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem;
    padding: 1.25rem;
  }

  .p-toolbar {
    background: #fafafa;
    border: 1px solid #e5e7eb;
    border-radius: .375rem;
    gap: .5rem;
    padding: 1.25rem;
  }

  .p-toolbar .p-toolbar-separator {
    margin: 0 .5rem;
  }

  .p-confirm-popup {
    color: #3f3f46;
    background: #fff;
    border: 0;
    border-radius: .375rem;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .1), 0 10px 10px -5px rgba(0, 0, 0, .04);
  }

  .p-confirm-popup .p-confirm-popup-content {
    padding: 1.25rem;
  }

  .p-confirm-popup .p-confirm-popup-footer {
    text-align: right;
    padding: 0 1rem 1rem;
  }

  .p-confirm-popup .p-confirm-popup-footer button {
    width: auto;
    margin: 0 .5rem 0 0;
  }

  .p-confirm-popup .p-confirm-popup-footer button:last-child {
    margin: 0;
  }

  .p-confirm-popup:after {
    border: solid rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
  }

  .p-confirm-popup:before {
    border: solid rgba(255, 255, 255, 0);
    border-bottom-color: #f2f2f2;
  }

  .p-confirm-popup.p-confirm-popup-flipped:after, .p-confirm-popup.p-confirm-popup-flipped:before {
    border-top-color: #fff;
  }

  .p-confirm-popup .p-confirm-popup-icon {
    font-size: 1.5rem;
  }

  .p-confirm-popup .p-confirm-popup-icon.p-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .p-confirm-popup .p-confirm-popup-message {
    margin-left: 1rem;
  }

  .p-dialog {
    border: 0;
    border-radius: .375rem;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .1), 0 10px 10px -5px rgba(0, 0, 0, .04);
  }

  .p-dialog .p-dialog-header {
    color: #3f3f46;
    background: #fff;
    border-bottom: 0;
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
    padding: 1.5rem;
  }

  .p-dialog .p-dialog-header .p-dialog-title {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .p-dialog .p-dialog-header .p-dialog-header-icon {
    color: #71717a;
    background: none;
    border: 0;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    margin-right: .5rem;
    transition: none;
  }

  .p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    color: #18181b;
    background: #f4f4f5;
    border-color: rgba(0, 0, 0, 0);
  }

  .p-dialog .p-dialog-header .p-dialog-header-icon:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
    margin-right: 0;
  }

  .p-dialog .p-dialog-content {
    color: #3f3f46;
    background: #fff;
    padding: 0 1.5rem 1.5rem;
  }

  .p-dialog .p-dialog-content:last-of-type {
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .p-dialog .p-dialog-footer {
    color: #3f3f46;
    text-align: right;
    background: #fff;
    border-top: 0;
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem;
    padding: 0 1.5rem 1.5rem;
  }

  .p-dialog .p-dialog-footer button {
    width: auto;
    margin: 0 .5rem 0 0;
  }

  .p-dialog.p-dialog-maximized .p-dialog-header, .p-dialog.p-dialog-maximized .p-dialog-content:last-of-type {
    border-radius: 0;
  }

  .p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
    font-size: 2rem;
  }

  .p-dialog.p-confirm-dialog .p-confirm-dialog-icon.p-icon {
    width: 2rem;
    height: 2rem;
  }

  .p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    margin-left: 1rem;
  }

  .p-overlaypanel {
    color: #3f3f46;
    background: #fff;
    border: 0;
    border-radius: .375rem;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .1), 0 10px 10px -5px rgba(0, 0, 0, .04);
  }

  .p-overlaypanel .p-overlaypanel-content {
    padding: 1.25rem;
  }

  .p-overlaypanel .p-overlaypanel-close {
    color: #fff;
    background: #4f46e5;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    transition: none;
    position: absolute;
    top: -1rem;
    right: -1rem;
  }

  .p-overlaypanel .p-overlaypanel-close:enabled:hover {
    color: #fff;
    background: #4338ca;
  }

  .p-overlaypanel:after {
    border: solid rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
  }

  .p-overlaypanel:before {
    border: solid rgba(255, 255, 255, 0);
    border-bottom-color: #f2f2f2;
  }

  .p-overlaypanel.p-overlaypanel-flipped:after, .p-overlaypanel.p-overlaypanel-flipped:before {
    border-top-color: #fff;
  }

  .p-sidebar {
    color: #3f3f46;
    background: #fff;
    border: 0;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .1), 0 10px 10px -5px rgba(0, 0, 0, .04);
  }

  .p-sidebar .p-sidebar-header {
    padding: 1.25rem;
  }

  .p-sidebar .p-sidebar-header .p-sidebar-close, .p-sidebar .p-sidebar-header .p-sidebar-icon {
    color: #71717a;
    background: none;
    border: 0;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    transition: none;
  }

  .p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover, .p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
    color: #18181b;
    background: #f4f4f5;
    border-color: rgba(0, 0, 0, 0);
  }

  .p-sidebar .p-sidebar-header .p-sidebar-close:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-sidebar .p-sidebar-header .p-sidebar-icon:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-sidebar .p-sidebar-header + .p-sidebar-content {
    padding-top: 0;
  }

  .p-sidebar .p-sidebar-content {
    padding: 1.25rem;
  }

  .p-tooltip .p-tooltip-text {
    color: #fff;
    background: #3f3f46;
    border-radius: .375rem;
    padding: .75rem;
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
  }

  .p-tooltip.p-tooltip-right .p-tooltip-arrow {
    border-right-color: #3f3f46;
  }

  .p-tooltip.p-tooltip-left .p-tooltip-arrow {
    border-left-color: #3f3f46;
  }

  .p-tooltip.p-tooltip-top .p-tooltip-arrow {
    border-top-color: #3f3f46;
  }

  .p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: #3f3f46;
  }

  .p-fileupload .p-fileupload-buttonbar {
    color: #3f3f46;
    background: #fafafa;
    border: 1px solid #e5e7eb;
    border-bottom: 0;
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
    gap: .5rem;
    padding: 1.25rem;
  }

  .p-fileupload .p-fileupload-buttonbar .p-button {
    margin-right: .5rem;
  }

  .p-fileupload .p-fileupload-content {
    color: #3f3f46;
    background: #fff;
    border: 1px solid #e5e7eb;
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem;
    padding: 2rem 1rem;
  }

  .p-fileupload .p-progressbar {
    height: .25rem;
  }

  .p-fileupload .p-fileupload-row > div {
    padding: 1rem 1.5rem;
  }

  .p-fileupload.p-fileupload-advanced .p-message {
    margin-top: 0;
  }

  .p-breadcrumb {
    background: #fff;
    border: 1px solid #e5e7eb;
    border-radius: .375rem;
    padding: 1rem;
  }

  .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link {
    border-radius: .375rem;
    transition: none;
  }

  .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-text {
    color: #3f3f46;
  }

  .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-icon {
    color: #71717a;
  }

  .p-breadcrumb .p-breadcrumb-list li.p-menuitem-separator {
    color: #3f3f46;
    margin: 0 .5rem;
  }

  .p-breadcrumb .p-breadcrumb-list li:last-child .p-menuitem-text {
    color: #3f3f46;
  }

  .p-breadcrumb .p-breadcrumb-list li:last-child .p-menuitem-icon {
    color: #71717a;
  }

  .p-contextmenu {
    color: #3f3f46;
    background: #fff;
    border: 0;
    border-radius: .375rem;
    width: 12.5rem;
    padding: .25rem 0;
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
  }

  .p-contextmenu .p-contextmenu-root-list {
    outline: 0;
  }

  .p-contextmenu .p-submenu-list {
    background: #fff;
    border: 0;
    border-radius: .375rem;
    padding: .25rem 0;
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
  }

  .p-contextmenu .p-menuitem > .p-menuitem-content {
    color: #3f3f46;
    border-radius: 0;
    transition: none;
  }

  .p-contextmenu .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #3f3f46;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: .75rem 1rem;
  }

  .p-contextmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #3f3f46;
  }

  .p-contextmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #71717a;
    margin-right: .5rem;
  }

  .p-contextmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }

  .p-contextmenu .p-menuitem.p-highlight > .p-menuitem-content {
    color: #3f3f46;
    background: #f4f4f5;
  }

  .p-contextmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #3f3f46;
  }

  .p-contextmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-contextmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }

  .p-contextmenu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: #f4f4f5;
  }

  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #18181b;
    background: #e5e7eb;
  }

  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text, .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #18181b;
  }

  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #18181b;
    background: #f4f4f5;
  }

  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #18181b;
  }

  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }

  .p-contextmenu .p-menuitem-separator {
    border-top: 1px solid #f3f4f6;
    margin: .25rem 0;
  }

  .p-contextmenu .p-submenu-icon {
    font-size: .875rem;
  }

  .p-contextmenu .p-submenu-icon.p-icon {
    width: .875rem;
    height: .875rem;
  }

  .p-dock .p-dock-list-container {
    background: rgba(255, 255, 255, .1);
    border: 1px solid rgba(255, 255, 255, .2);
    border-radius: .5rem;
    padding: .5rem;
  }

  .p-dock .p-dock-list-container .p-dock-list {
    outline: 0;
  }

  .p-dock .p-dock-item {
    border-radius: .375rem;
    padding: .5rem;
  }

  .p-dock .p-dock-item.p-focus {
    outline-offset: 0;
    outline: 0;
    box-shadow: inset 0 0 0 .15rem #6366f1;
  }

  .p-dock .p-dock-action {
    width: 4rem;
    height: 4rem;
  }

  .p-dock.p-dock-top .p-dock-item-second-prev, .p-dock.p-dock-top .p-dock-item-second-next, .p-dock.p-dock-bottom .p-dock-item-second-prev, .p-dock.p-dock-bottom .p-dock-item-second-next {
    margin: 0 .9rem;
  }

  .p-dock.p-dock-top .p-dock-item-prev, .p-dock.p-dock-top .p-dock-item-next, .p-dock.p-dock-bottom .p-dock-item-prev, .p-dock.p-dock-bottom .p-dock-item-next {
    margin: 0 1.3rem;
  }

  .p-dock.p-dock-top .p-dock-item-current, .p-dock.p-dock-bottom .p-dock-item-current {
    margin: 0 1.5rem;
  }

  .p-dock.p-dock-left .p-dock-item-second-prev, .p-dock.p-dock-left .p-dock-item-second-next, .p-dock.p-dock-right .p-dock-item-second-prev, .p-dock.p-dock-right .p-dock-item-second-next {
    margin: .9rem 0;
  }

  .p-dock.p-dock-left .p-dock-item-prev, .p-dock.p-dock-left .p-dock-item-next, .p-dock.p-dock-right .p-dock-item-prev, .p-dock.p-dock-right .p-dock-item-next {
    margin: 1.3rem 0;
  }

  .p-dock.p-dock-left .p-dock-item-current, .p-dock.p-dock-right .p-dock-item-current {
    margin: 1.5rem 0;
  }

  .p-dock.p-dock-mobile.p-dock-top .p-dock-list-container, .p-dock.p-dock-mobile.p-dock-bottom .p-dock-list-container {
    width: 100%;
    overflow-x: auto;
  }

  .p-dock.p-dock-mobile.p-dock-top .p-dock-list-container .p-dock-list, .p-dock.p-dock-mobile.p-dock-bottom .p-dock-list-container .p-dock-list {
    margin: 0 auto;
  }

  .p-dock.p-dock-mobile.p-dock-left .p-dock-list-container, .p-dock.p-dock-mobile.p-dock-right .p-dock-list-container {
    height: 100%;
    overflow-y: auto;
  }

  .p-dock.p-dock-mobile.p-dock-left .p-dock-list-container .p-dock-list, .p-dock.p-dock-mobile.p-dock-right .p-dock-list-container .p-dock-list {
    margin: auto 0;
  }

  .p-dock.p-dock-mobile .p-dock-list .p-dock-item, .p-dock.p-dock-mobile.p-dock-magnification.p-dock-top .p-dock-item-second-prev, .p-dock.p-dock-mobile.p-dock-magnification.p-dock-top .p-dock-item-second-next, .p-dock.p-dock-mobile.p-dock-magnification.p-dock-top .p-dock-item-prev, .p-dock.p-dock-mobile.p-dock-magnification.p-dock-top .p-dock-item-next, .p-dock.p-dock-mobile.p-dock-magnification.p-dock-top .p-dock-item-current, .p-dock.p-dock-mobile.p-dock-magnification.p-dock-bottom .p-dock-item-second-prev, .p-dock.p-dock-mobile.p-dock-magnification.p-dock-bottom .p-dock-item-second-next, .p-dock.p-dock-mobile.p-dock-magnification.p-dock-bottom .p-dock-item-prev, .p-dock.p-dock-mobile.p-dock-magnification.p-dock-bottom .p-dock-item-next, .p-dock.p-dock-mobile.p-dock-magnification.p-dock-bottom .p-dock-item-current, .p-dock.p-dock-mobile.p-dock-magnification.p-dock-left .p-dock-item-second-prev, .p-dock.p-dock-mobile.p-dock-magnification.p-dock-left .p-dock-item-second-next, .p-dock.p-dock-mobile.p-dock-magnification.p-dock-left .p-dock-item-prev, .p-dock.p-dock-mobile.p-dock-magnification.p-dock-left .p-dock-item-next, .p-dock.p-dock-mobile.p-dock-magnification.p-dock-left .p-dock-item-current, .p-dock.p-dock-mobile.p-dock-magnification.p-dock-right .p-dock-item-second-prev, .p-dock.p-dock-mobile.p-dock-magnification.p-dock-right .p-dock-item-second-next, .p-dock.p-dock-mobile.p-dock-magnification.p-dock-right .p-dock-item-prev, .p-dock.p-dock-mobile.p-dock-magnification.p-dock-right .p-dock-item-next, .p-dock.p-dock-mobile.p-dock-magnification.p-dock-right .p-dock-item-current {
    margin: 0;
    transform: none;
  }

  .p-megamenu {
    color: #3f3f46;
    background: #fafafa;
    border: 1px solid #e5e7eb;
    border-radius: .375rem;
    padding: 1rem;
  }

  .p-megamenu .p-megamenu-root-list {
    outline: 0;
  }

  .p-megamenu .p-menuitem > .p-menuitem-content {
    color: #3f3f46;
    border-radius: 0;
    transition: none;
  }

  .p-megamenu .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #3f3f46;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: .75rem 1rem;
  }

  .p-megamenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #3f3f46;
  }

  .p-megamenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #71717a;
    margin-right: .5rem;
  }

  .p-megamenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }

  .p-megamenu .p-menuitem.p-highlight > .p-menuitem-content {
    color: #3f3f46;
    background: #f4f4f5;
  }

  .p-megamenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #3f3f46;
  }

  .p-megamenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-megamenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }

  .p-megamenu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: #f4f4f5;
  }

  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #18181b;
    background: #e5e7eb;
  }

  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text, .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #18181b;
  }

  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #18181b;
    background: #f4f4f5;
  }

  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #18181b;
  }

  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }

  .p-megamenu .p-megamenu-panel {
    color: #3f3f46;
    background: #fff;
    border: 0;
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
  }

  .p-megamenu .p-submenu-header {
    color: #3f3f46;
    background: #fff;
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
    margin: 0;
    padding: .75rem 1rem;
    font-weight: 600;
  }

  .p-megamenu .p-submenu-list {
    width: 12.5rem;
    padding: .25rem 0;
  }

  .p-megamenu .p-submenu-list .p-menuitem-separator {
    border-top: 1px solid #f3f4f6;
    margin: .25rem 0;
  }

  .p-megamenu.p-megamenu-vertical {
    width: 12.5rem;
    padding: .25rem 0;
  }

  .p-megamenu .p-megamenu-button {
    color: #71717a;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    transition: none;
  }

  .p-megamenu .p-megamenu-button:hover {
    color: #71717a;
    background: #f4f4f5;
  }

  .p-megamenu .p-megamenu-button:focus {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-content {
    color: #3f3f46;
    border-radius: .375rem;
    transition: none;
  }

  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: .75rem 1rem;
  }

  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #3f3f46;
  }

  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #71717a;
    margin-right: .5rem;
  }

  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #71717a;
    margin-left: .5rem;
  }

  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #18181b;
    background: #f4f4f5;
  }

  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #18181b;
  }

  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }

  .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list {
    background: #fff;
    border: 0;
    padding: .25rem 0;
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
  }

  .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-menu-separator {
    border-top: 1px solid #f3f4f6;
    margin: .25rem 0;
  }

  .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-submenu-icon {
    font-size: .875rem;
  }

  .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-submenu-icon.p-icon {
    width: .875rem;
    height: .875rem;
  }

  .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem {
    width: 100%;
    position: static;
  }

  .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link > .p-menuitem-content {
    color: #3f3f46;
    border-radius: 0;
    transition: none;
  }

  .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link > .p-menuitem-content .p-menuitem-link {
    color: #3f3f46;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: .75rem 1rem;
  }

  .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #3f3f46;
  }

  .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #71717a;
    margin-right: .5rem;
  }

  .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }

  .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link.p-highlight > .p-menuitem-content {
    color: #3f3f46;
    background: #f4f4f5;
  }

  .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #3f3f46;
  }

  .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }

  .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link.p-highlight.p-focus > .p-menuitem-content {
    background: #f4f4f5;
  }

  .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #18181b;
    background: #e5e7eb;
  }

  .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text, .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #18181b;
  }

  .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #18181b;
    background: #f4f4f5;
  }

  .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #18181b;
  }

  .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }

  .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
    margin-left: auto;
  }

  .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-180deg);
  }

  .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-submenu-list {
    box-shadow: none;
    border: 0;
    width: 100%;
    position: static;
  }

  .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-submenu-list .p-submenu-icon {
    transition: transform .2s;
    transform: rotate(90deg);
  }

  .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-90deg);
  }

  .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-menuitem {
    width: 100%;
    position: static;
  }

  .p-menu {
    color: #3f3f46;
    background: #fff;
    border: 1px solid #e5e7eb;
    border-radius: .375rem;
    width: 12.5rem;
    padding: .25rem 0;
  }

  .p-menu .p-menuitem > .p-menuitem-content {
    color: #3f3f46;
    border-radius: 0;
    transition: none;
  }

  .p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #3f3f46;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: .75rem 1rem;
  }

  .p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #3f3f46;
  }

  .p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #71717a;
    margin-right: .5rem;
  }

  .p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }

  .p-menu .p-menuitem.p-highlight > .p-menuitem-content {
    color: #3f3f46;
    background: #f4f4f5;
  }

  .p-menu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #3f3f46;
  }

  .p-menu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-menu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }

  .p-menu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: #f4f4f5;
  }

  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #18181b;
    background: #e5e7eb;
  }

  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text, .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #18181b;
  }

  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #18181b;
    background: #f4f4f5;
  }

  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #18181b;
  }

  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }

  .p-menu.p-menu-overlay {
    background: #fff;
    border: 0;
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
  }

  .p-menu .p-submenu-header {
    color: #3f3f46;
    background: #fff;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin: 0;
    padding: .75rem 1rem;
    font-weight: 600;
  }

  .p-menu .p-menu-separator {
    border-top: 1px solid #f3f4f6;
    margin: .25rem 0;
  }

  .p-menubar {
    color: #3f3f46;
    background: #fafafa;
    border: 1px solid #e5e7eb;
    border-radius: .375rem;
    padding: 1rem;
  }

  .p-menubar .p-menubar-root-list {
    outline: 0;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content {
    color: #3f3f46;
    border-radius: .375rem;
    transition: none;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: .75rem 1rem;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #3f3f46;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #71717a;
    margin-right: .5rem;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #71717a;
    margin-left: .5rem;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #18181b;
    background: #f4f4f5;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #18181b;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menubar-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }

  .p-menubar .p-menuitem > .p-menuitem-content {
    color: #3f3f46;
    border-radius: 0;
    transition: none;
  }

  .p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #3f3f46;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: .75rem 1rem;
  }

  .p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #3f3f46;
  }

  .p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #71717a;
    margin-right: .5rem;
  }

  .p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }

  .p-menubar .p-menuitem.p-highlight > .p-menuitem-content {
    color: #3f3f46;
    background: #f4f4f5;
  }

  .p-menubar .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #3f3f46;
  }

  .p-menubar .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }

  .p-menubar .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: #f4f4f5;
  }

  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #18181b;
    background: #e5e7eb;
  }

  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text, .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #18181b;
  }

  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #18181b;
    background: #f4f4f5;
  }

  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #18181b;
  }

  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }

  .p-menubar .p-submenu-list {
    background: #fff;
    border: 0;
    width: 12.5rem;
    padding: .25rem 0;
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
  }

  .p-menubar .p-submenu-list .p-menuitem-separator {
    border-top: 1px solid #f3f4f6;
    margin: .25rem 0;
  }

  .p-menubar .p-submenu-list .p-submenu-icon {
    font-size: .875rem;
  }

  .p-menubar.p-menubar-mobile .p-menubar-button {
    color: #71717a;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    transition: none;
  }

  .p-menubar.p-menubar-mobile .p-menubar-button:hover {
    color: #71717a;
    background: #f4f4f5;
  }

  .p-menubar.p-menubar-mobile .p-menubar-button:focus {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-menubar.p-menubar-mobile .p-menubar-root-list {
    background: #fff;
    border: 0;
    padding: .25rem 0;
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
  }

  .p-menubar.p-menubar-mobile .p-menubar-root-list .p-menuitem-separator {
    border-top: 1px solid #f3f4f6;
    margin: .25rem 0;
  }

  .p-menubar.p-menubar-mobile .p-menubar-root-list .p-submenu-icon {
    font-size: .875rem;
  }

  .p-menubar.p-menubar-mobile .p-menubar-root-list .p-menuitem .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    margin-left: auto;
    transition: transform .2s;
  }

  .p-menubar.p-menubar-mobile .p-menubar-root-list .p-menuitem.p-menuitem-active > .p-menuitem-content > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-180deg);
  }

  .p-menubar.p-menubar-mobile .p-menubar-root-list .p-submenu-list .p-submenu-icon {
    transition: transform .2s;
    transform: rotate(90deg);
  }

  .p-menubar.p-menubar-mobile .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-content > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-90deg);
  }

  .p-menubar.p-menubar-mobile .p-menubar-root-list ul li a {
    padding-left: 2.25rem;
  }

  .p-menubar.p-menubar-mobile .p-menubar-root-list ul li ul li a {
    padding-left: 3.75rem;
  }

  .p-menubar.p-menubar-mobile .p-menubar-root-list ul li ul li ul li a {
    padding-left: 5.25rem;
  }

  .p-menubar.p-menubar-mobile .p-menubar-root-list ul li ul li ul li ul li a {
    padding-left: 6.75rem;
  }

  .p-menubar.p-menubar-mobile .p-menubar-root-list ul li ul li ul li ul li ul li a {
    padding-left: 8.25rem;
  }

  @media screen and (max-width: 960px) {
    .p-menubar {
      position: relative;
    }

    .p-menubar .p-menubar-button {
      color: #71717a;
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
      transition: none;
      display: -ms-flexbox;
      display: flex;
    }

    .p-menubar .p-menubar-button:hover {
      color: #71717a;
      background: #f4f4f5;
    }

    .p-menubar .p-menubar-button:focus {
      outline-offset: 0;
      outline: 0;
      box-shadow: 0 0 0 1px #6366f1;
    }

    .p-menubar .p-menubar-root-list {
      background: #fff;
      border: 0;
      width: 100%;
      padding: .25rem 0;
      display: none;
      position: absolute;
      box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
    }

    .p-menubar .p-menubar-root-list .p-menu-separator {
      border-top: 1px solid #f3f4f6;
      margin: .25rem 0;
    }

    .p-menubar .p-menubar-root-list .p-submenu-icon {
      font-size: .875rem;
    }

    .p-menubar .p-menubar-root-list .p-submenu-icon.p-icon {
      width: .875rem;
      height: .875rem;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem {
      width: 100%;
      position: static;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-menuitem-content {
      color: #3f3f46;
      border-radius: 0;
      transition: none;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-menuitem-content .p-menuitem-link {
      color: #3f3f46;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      padding: .75rem 1rem;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
      color: #3f3f46;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
      color: #71717a;
      margin-right: .5rem;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
      color: #71717a;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link.p-highlight > .p-menuitem-content {
      color: #3f3f46;
      background: #f4f4f5;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
      color: #3f3f46;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
      color: #71717a;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link.p-highlight.p-focus > .p-menuitem-content {
      background: #f4f4f5;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
      color: #18181b;
      background: #e5e7eb;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text, .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
      color: #18181b;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
      color: #18181b;
      background: #f4f4f5;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
      color: #18181b;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
      color: #71717a;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
      margin-left: auto;
      transition: transform .2s;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
      transform: rotate(-180deg);
    }

    .p-menubar .p-menubar-root-list .p-submenu-list {
      box-shadow: none;
      border: 0;
      width: 100%;
      position: static;
    }

    .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
      transition: transform .2s;
      transform: rotate(90deg);
    }

    .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
      transform: rotate(-90deg);
    }

    .p-menubar .p-menubar-root-list .p-menuitem {
      width: 100%;
      position: static;
    }

    .p-menubar .p-menubar-root-list ul li a {
      padding-left: 2.25rem;
    }

    .p-menubar .p-menubar-root-list ul li ul li a {
      padding-left: 3.75rem;
    }

    .p-menubar .p-menubar-root-list ul li ul li ul li a {
      padding-left: 5.25rem;
    }

    .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
      padding-left: 6.75rem;
    }

    .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
      padding-left: 8.25rem;
    }

    .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
      z-index: 1;
      -ms-flex-direction: column;
      flex-direction: column;
      display: -ms-flexbox;
      display: flex;
      top: 100%;
      left: 0;
    }
  }

  .p-panelmenu .p-panelmenu-header {
    outline: 0;
  }

  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content {
    color: #3f3f46;
    background: #fafafa;
    border: 1px solid #e5e7eb;
    border-radius: .375rem;
    transition: none;
  }

  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-link {
    color: #3f3f46;
    padding: 1.25rem;
    font-weight: 700;
  }

  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-link .p-submenu-icon, .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-link .p-menuitem-icon {
    margin-right: .5rem;
  }

  .p-panelmenu .p-panelmenu-header:not(.p-disabled):focus-visible .p-panelmenu-header-content {
    outline-offset: 0;
    outline: 0;
    box-shadow: inset 0 0 0 1px #6366f1;
  }

  .p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover .p-panelmenu-header-content {
    color: #3f3f46;
    background: #f4f4f5;
    border-color: #e5e7eb;
  }

  .p-panelmenu .p-panelmenu-header:not(.p-disabled).p-highlight .p-panelmenu-header-content {
    color: #3f3f46;
    background: #fafafa;
    border-color: #e5e7eb;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    margin-bottom: 0;
  }

  .p-panelmenu .p-panelmenu-header:not(.p-disabled).p-highlight:hover .p-panelmenu-header-content {
    color: #3f3f46;
    background: #f4f4f5;
    border-color: #e5e7eb;
  }

  .p-panelmenu .p-panelmenu-content {
    color: #3f3f46;
    background: #fff;
    border: 1px solid #e5e7eb;
    border-top: 0;
    border-radius: 0 0 .375rem .375rem;
    padding: .25rem 0;
  }

  .p-panelmenu .p-panelmenu-content .p-panelmenu-root-list {
    outline: 0;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content {
    color: #3f3f46;
    border-radius: 0;
    transition: none;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #3f3f46;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: .75rem 1rem;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #3f3f46;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #71717a;
    margin-right: .5rem;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight > .p-menuitem-content {
    color: #3f3f46;
    background: #f4f4f5;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #3f3f46;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: #f4f4f5;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #18181b;
    background: #e5e7eb;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text, .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #18181b;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #18181b;
    background: #f4f4f5;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #18181b;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    margin-right: .5rem;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem-separator {
    border-top: 1px solid #f3f4f6;
    margin: .25rem 0;
  }

  .p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-list) {
    padding: 0 0 0 1rem;
  }

  .p-panelmenu .p-panelmenu-panel {
    margin-bottom: 0;
  }

  .p-panelmenu .p-panelmenu-panel .p-panelmenu-header .p-panelmenu-header-content, .p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
    border-radius: 0;
  }

  .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header .p-panelmenu-header-content, .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover .p-panelmenu-header-content, .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-disabled).p-highlight:hover .p-panelmenu-header-content {
    border-top: 0;
  }

  .p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header .p-panelmenu-header-content {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
  }

  .p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header:not(.p-highlight) .p-panelmenu-header-content, .p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .p-slidemenu {
    color: #3f3f46;
    background: #fff;
    border: 1px solid #e5e7eb;
    border-radius: .375rem;
    width: 12.5rem;
    padding: .25rem 0;
  }

  .p-slidemenu .p-menuitem-link > .p-menuitem-content {
    color: #3f3f46;
    border-radius: 0;
    transition: none;
  }

  .p-slidemenu .p-menuitem-link > .p-menuitem-content .p-menuitem-link {
    color: #3f3f46;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: .75rem 1rem;
  }

  .p-slidemenu .p-menuitem-link > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #3f3f46;
  }

  .p-slidemenu .p-menuitem-link > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #71717a;
    margin-right: .5rem;
  }

  .p-slidemenu .p-menuitem-link > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }

  .p-slidemenu .p-menuitem-link.p-highlight > .p-menuitem-content {
    color: #3f3f46;
    background: #f4f4f5;
  }

  .p-slidemenu .p-menuitem-link.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #3f3f46;
  }

  .p-slidemenu .p-menuitem-link.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-slidemenu .p-menuitem-link.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }

  .p-slidemenu .p-menuitem-link.p-highlight.p-focus > .p-menuitem-content {
    background: #f4f4f5;
  }

  .p-slidemenu .p-menuitem-link:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #18181b;
    background: #e5e7eb;
  }

  .p-slidemenu .p-menuitem-link:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text, .p-slidemenu .p-menuitem-link:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-slidemenu .p-menuitem-link:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #18181b;
  }

  .p-slidemenu .p-menuitem-link:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #18181b;
    background: #f4f4f5;
  }

  .p-slidemenu .p-menuitem-link:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #18181b;
  }

  .p-slidemenu .p-menuitem-link:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-slidemenu .p-menuitem-link:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }

  .p-slidemenu.p-slidemenu-overlay {
    background: #fff;
    border: 0;
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
  }

  .p-slidemenu .p-slidemenu-list {
    background: #fff;
    border: 0;
    padding: .25rem 0;
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
  }

  .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
    background: #f4f4f5;
  }

  .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-text {
    color: #3f3f46;
  }

  .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon, .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon {
    color: #71717a;
  }

  .p-slidemenu .p-slidemenu-separator {
    border-top: 1px solid #f3f4f6;
    margin: .25rem 0;
  }

  .p-slidemenu .p-slidemenu-icon {
    font-size: .875rem;
  }

  .p-slidemenu .p-slidemenu-icon.p-icon {
    width: .875rem;
    height: .875rem;
  }

  .p-slidemenu .p-slidemenu-backward {
    color: #3f3f46;
    padding: .75rem 1rem;
  }

  .p-steps .p-steps-item .p-menuitem-link {
    background: #fff;
    border-radius: .375rem;
    transition: none;
  }

  .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    color: #3f3f46;
    z-index: 1;
    background: #fff;
    border: 1px solid #f4f4f5;
    border-radius: 50%;
    min-width: 2rem;
    height: 2rem;
    font-size: 1.143rem;
    line-height: 2rem;
  }

  .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
    color: #71717a;
    margin-top: .5rem;
  }

  .p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-steps .p-steps-item.p-highlight .p-steps-number {
    color: #312e81;
    background: #eef2ff;
  }

  .p-steps .p-steps-item.p-highlight .p-steps-title {
    color: #3f3f46;
    font-weight: 600;
  }

  .p-steps .p-steps-item:before {
    content: " ";
    border-top: 1px solid #f3f4f6;
    width: 100%;
    margin-top: -1rem;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
  }

  .p-tabmenu .p-tabmenu-nav {
    background: #fff;
    border: 0 solid #e5e7eb;
    border-bottom-width: 2px;
  }

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    margin-right: 0;
  }

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    color: #71717a;
    background: #fff;
    border: 0 solid rgba(0, 0, 0, 0);
    border-bottom: 2px solid #e5e7eb;
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
    height: calc(100% + 2px);
    margin: 0 0 -2px;
    padding: 1.25rem;
    font-weight: 700;
    transition: none;
  }

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
    margin-right: .5rem;
  }

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: inset 0 0 0 1px #6366f1;
  }

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
    color: #71717a;
    background: #fff;
    border-color: #71717a;
  }

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    color: #4f46e5;
    background: #fff;
    border-color: #4f46e5;
  }

  .p-tieredmenu {
    color: #3f3f46;
    background: #fff;
    border: 1px solid #e5e7eb;
    border-radius: .375rem;
    width: 12.5rem;
    padding: .25rem 0;
  }

  .p-tieredmenu.p-tieredmenu-overlay {
    background: #fff;
    border: 0;
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
  }

  .p-tieredmenu .p-tieredmenu-root-list {
    outline: 0;
  }

  .p-tieredmenu .p-submenu-list {
    background: #fff;
    border: 0;
    padding: .25rem 0;
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
  }

  .p-tieredmenu .p-menuitem > .p-menuitem-content {
    color: #3f3f46;
    border-radius: 0;
    transition: none;
  }

  .p-tieredmenu .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #3f3f46;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: .75rem 1rem;
  }

  .p-tieredmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #3f3f46;
  }

  .p-tieredmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #71717a;
    margin-right: .5rem;
  }

  .p-tieredmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }

  .p-tieredmenu .p-menuitem.p-highlight > .p-menuitem-content {
    color: #3f3f46;
    background: #f4f4f5;
  }

  .p-tieredmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #3f3f46;
  }

  .p-tieredmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }

  .p-tieredmenu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: #f4f4f5;
  }

  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #18181b;
    background: #e5e7eb;
  }

  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text, .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #18181b;
  }

  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #18181b;
    background: #f4f4f5;
  }

  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #18181b;
  }

  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }

  .p-tieredmenu .p-menuitem-separator {
    border-top: 1px solid #f3f4f6;
    margin: .25rem 0;
  }

  .p-tieredmenu .p-submenu-icon {
    font-size: .875rem;
  }

  .p-tieredmenu .p-submenu-icon.p-icon {
    width: .875rem;
    height: .875rem;
  }

  .p-inline-message {
    border-radius: .375rem;
    margin: 0;
    padding: .75rem;
  }

  .p-inline-message.p-inline-message-info {
    color: #2563eb;
    background: #eff6ff;
    border: 0 solid #2563eb;
  }

  .p-inline-message.p-inline-message-info .p-inline-message-icon {
    color: #2563eb;
  }

  .p-inline-message.p-inline-message-success {
    color: #059669;
    background: #ecfdf5;
    border: 0 solid #059669;
  }

  .p-inline-message.p-inline-message-success .p-inline-message-icon {
    color: #059669;
  }

  .p-inline-message.p-inline-message-warn {
    color: #d97706;
    background: #fef3c7;
    border: 0 solid #d97706;
  }

  .p-inline-message.p-inline-message-warn .p-inline-message-icon {
    color: #d97706;
  }

  .p-inline-message.p-inline-message-error {
    color: #dc2626;
    background: #fef3c7;
    border: 0 solid #dc2626;
  }

  .p-inline-message.p-inline-message-error .p-inline-message-icon {
    color: #dc2626;
  }

  .p-inline-message .p-inline-message-icon {
    margin-right: .5rem;
    font-size: 1rem;
  }

  .p-inline-message .p-inline-message-icon.p-icon {
    width: 1rem;
    height: 1rem;
  }

  .p-inline-message .p-inline-message-text {
    font-size: 1rem;
  }

  .p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
    margin-right: 0;
  }

  .p-message {
    border-radius: .375rem;
    margin: 1rem 0;
  }

  .p-message .p-message-wrapper {
    padding: 1.25rem 1.75rem;
  }

  .p-message .p-message-close {
    background: none;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    transition: none;
  }

  .p-message .p-message-close:hover {
    background: rgba(255, 255, 255, .5);
  }

  .p-message .p-message-close:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-message.p-message-info {
    color: #2563eb;
    background: #eff6ff;
    border: 0 solid #2563eb;
    border-left-width: 6px;
  }

  .p-message.p-message-info .p-message-icon, .p-message.p-message-info .p-message-close {
    color: #2563eb;
  }

  .p-message.p-message-success {
    color: #059669;
    background: #ecfdf5;
    border: 0 solid #059669;
    border-left-width: 6px;
  }

  .p-message.p-message-success .p-message-icon, .p-message.p-message-success .p-message-close {
    color: #059669;
  }

  .p-message.p-message-warn {
    color: #d97706;
    background: #fef3c7;
    border: 0 solid #d97706;
    border-left-width: 6px;
  }

  .p-message.p-message-warn .p-message-icon, .p-message.p-message-warn .p-message-close {
    color: #d97706;
  }

  .p-message.p-message-error {
    color: #dc2626;
    background: #fef3c7;
    border: 0 solid #dc2626;
    border-left-width: 6px;
  }

  .p-message.p-message-error .p-message-icon, .p-message.p-message-error .p-message-close {
    color: #dc2626;
  }

  .p-message .p-message-text {
    font-size: 1rem;
    font-weight: 500;
  }

  .p-message .p-message-icon {
    margin-right: .5rem;
    font-size: 1.5rem;
  }

  .p-message .p-message-icon.p-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .p-message .p-message-summary {
    font-weight: 700;
  }

  .p-message .p-message-detail {
    margin-left: .5rem;
  }

  .p-toast {
    opacity: .9;
  }

  .p-toast .p-toast-message {
    border-radius: .375rem;
    margin: 0 0 1rem;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .1), 0 10px 10px -5px rgba(0, 0, 0, .04);
  }

  .p-toast .p-toast-message .p-toast-message-content {
    border-width: 0 0 0 6px;
    padding: 1rem;
  }

  .p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
    margin: 0 0 0 1rem;
  }

  .p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
    font-size: 2rem;
  }

  .p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon.p-icon {
    width: 2rem;
    height: 2rem;
  }

  .p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
    font-weight: 700;
  }

  .p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
    margin: .5rem 0 0;
  }

  .p-toast .p-toast-message .p-toast-icon-close {
    background: none;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    transition: none;
  }

  .p-toast .p-toast-message .p-toast-icon-close:hover {
    background: rgba(255, 255, 255, .5);
  }

  .p-toast .p-toast-message .p-toast-icon-close:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-toast .p-toast-message.p-toast-message-info {
    color: #2563eb;
    background: #eff6ff;
    border: 0 solid #2563eb;
    border-left-width: 6px;
  }

  .p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
    color: #2563eb;
  }

  .p-toast .p-toast-message.p-toast-message-success {
    color: #059669;
    background: #ecfdf5;
    border: 0 solid #059669;
    border-left-width: 6px;
  }

  .p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
    color: #059669;
  }

  .p-toast .p-toast-message.p-toast-message-warn {
    color: #d97706;
    background: #fef3c7;
    border: 0 solid #d97706;
    border-left-width: 6px;
  }

  .p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
    color: #d97706;
  }

  .p-toast .p-toast-message.p-toast-message-error {
    color: #dc2626;
    background: #fef3c7;
    border: 0 solid #dc2626;
    border-left-width: 6px;
  }

  .p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
    color: #dc2626;
  }

  .p-galleria .p-galleria-close {
    color: #fafafa;
    background: none;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    margin: .5rem;
    transition: none;
  }

  .p-galleria .p-galleria-close .p-galleria-close-icon {
    font-size: 2rem;
  }

  .p-galleria .p-galleria-close .p-galleria-close-icon.p-icon {
    width: 2rem;
    height: 2rem;
  }

  .p-galleria .p-galleria-close:hover {
    color: #fafafa;
    background: rgba(255, 255, 255, .1);
  }

  .p-galleria .p-galleria-item-nav {
    color: #fafafa;
    background: none;
    border-radius: .375rem;
    width: 4rem;
    height: 4rem;
    margin: 0 .5rem;
    transition: none;
  }

  .p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon, .p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
    font-size: 2rem;
  }

  .p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon.p-icon, .p-galleria .p-galleria-item-nav .p-galleria-item-next-icon.p-icon {
    width: 2rem;
    height: 2rem;
  }

  .p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
    color: #fafafa;
    background: rgba(255, 255, 255, .1);
  }

  .p-galleria .p-galleria-caption {
    color: #fafafa;
    background: rgba(0, 0, 0, .5);
    padding: 1rem;
  }

  .p-galleria .p-galleria-indicators {
    padding: 1rem;
  }

  .p-galleria .p-galleria-indicators .p-galleria-indicator button {
    background-color: #f4f4f5;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    transition: none;
  }

  .p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
    background: #e5e7eb;
  }

  .p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    color: #312e81;
    background: #eef2ff;
  }

  .p-galleria.p-galleria-indicators-bottom .p-galleria-indicator, .p-galleria.p-galleria-indicators-top .p-galleria-indicator {
    margin-right: .5rem;
  }

  .p-galleria.p-galleria-indicators-left .p-galleria-indicator, .p-galleria.p-galleria-indicators-right .p-galleria-indicator {
    margin-bottom: .5rem;
  }

  .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
    background: rgba(0, 0, 0, .5);
  }

  .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
    background: rgba(255, 255, 255, .4);
  }

  .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
    background: rgba(255, 255, 255, .6);
  }

  .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    color: #312e81;
    background: #eef2ff;
  }

  .p-galleria .p-galleria-thumbnail-container {
    background: rgba(0, 0, 0, .9);
    padding: 1rem .25rem;
  }

  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev, .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
    color: #fafafa;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    margin: .5rem;
    transition: none;
  }

  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover, .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
    color: #fafafa;
    background: rgba(255, 255, 255, .1);
  }

  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content {
    transition: none;
  }

  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-galleria-mask, .p-image-mask {
    --maskbg: rgba(0, 0, 0, .9);
  }

  .p-image-preview-indicator {
    color: #f8f9fa;
    background-color: rgba(0, 0, 0, 0);
    transition: none;
  }

  .p-image-preview-indicator .p-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .p-image-preview-container:hover > .p-image-preview-indicator {
    background-color: rgba(0, 0, 0, .5);
  }

  .p-image-toolbar {
    padding: 1rem;
  }

  .p-image-action.p-link {
    color: #f8f9fa;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    margin-right: .5rem;
    transition: none;
  }

  .p-image-action.p-link:last-child {
    margin-right: 0;
  }

  .p-image-action.p-link:hover {
    color: #f8f9fa;
    background-color: rgba(255, 255, 255, .1);
  }

  .p-image-action.p-link span {
    font-size: 1.5rem;
  }

  .p-image-action.p-link .p-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .p-avatar {
    background-color: #e5e7eb;
    border-radius: .375rem;
  }

  .p-avatar.p-avatar-lg {
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
  }

  .p-avatar.p-avatar-lg .p-avatar-icon {
    font-size: 1.5rem;
  }

  .p-avatar.p-avatar-xl {
    width: 4rem;
    height: 4rem;
    font-size: 2rem;
  }

  .p-avatar.p-avatar-xl .p-avatar-icon {
    font-size: 2rem;
  }

  .p-avatar-circle {
    border-radius: 50%;
  }

  .p-avatar-group .p-avatar {
    border: 2px solid #fff;
  }

  .p-chip {
    color: #3f3f46;
    background-color: #e5e7eb;
    border-radius: 16px;
    padding: 0 .75rem;
  }

  .p-chip .p-chip-text {
    margin-top: .375rem;
    margin-bottom: .375rem;
    line-height: 1.5;
  }

  .p-chip .p-chip-icon {
    margin-right: .5rem;
  }

  .p-chip img {
    width: 2.25rem;
    height: 2.25rem;
    margin-left: -.75rem;
    margin-right: .5rem;
  }

  .p-chip .p-chip-remove-icon {
    border-radius: .375rem;
    margin-left: .5rem;
    transition: none;
  }

  .p-chip .p-chip-remove-icon:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-chip .p-chip-remove-icon:focus {
    outline: 0;
  }

  .p-scrolltop {
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    transition: none;
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
  }

  .p-scrolltop.p-link {
    background: rgba(0, 0, 0, .7);
  }

  .p-scrolltop.p-link:hover {
    background: rgba(0, 0, 0, .8);
  }

  .p-scrolltop .p-scrolltop-icon {
    color: #fafafa;
    font-size: 1.5rem;
  }

  .p-scrolltop .p-scrolltop-icon.p-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .p-skeleton {
    background-color: #f4f4f5;
    border-radius: .375rem;
  }

  .p-skeleton:after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, .4), rgba(255, 255, 255, 0));
  }

  .p-tag .p-tag-icon {
    margin-right: .25rem;
    font-size: .75rem;
  }

  .p-tag .p-tag-icon.p-icon {
    width: .75rem;
    height: .75rem;
  }

  .p-inplace .p-inplace-display {
    border-radius: .375rem;
    padding: .75rem;
    transition: none;
  }

  .p-inplace .p-inplace-display:not(.p-disabled):hover {
    color: #18181b;
    background: #f4f4f5;
  }

  .p-inplace .p-inplace-display:focus {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 1px #6366f1;
  }

  .p-progressbar {
    background: #e5e7eb;
    border: 0;
    border-radius: .375rem;
    height: 1.5rem;
  }

  .p-progressbar .p-progressbar-value {
    background: #4f46e5;
    border: 0;
    margin: 0;
  }

  .p-progressbar .p-progressbar-label {
    color: #fff;
    line-height: 1.5rem;
  }

  .p-terminal {
    color: #3f3f46;
    background: #fff;
    border: 1px solid #e5e7eb;
    padding: 1.25rem;
  }

  .p-terminal .p-terminal-input {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
  }

  .p-badge {
    color: #fff;
    background: #4f46e5;
    min-width: 1.5rem;
    height: 1.5rem;
    font-size: .75rem;
    font-weight: 700;
    line-height: 1.5rem;
  }

  .p-badge.p-badge-secondary {
    color: #fff;
    background-color: #64748b;
  }

  .p-badge.p-badge-success {
    color: #fff;
    background-color: #22c55e;
  }

  .p-badge.p-badge-info {
    color: #fff;
    background-color: #3b82f6;
  }

  .p-badge.p-badge-warning {
    color: #fff;
    background-color: #f59e0b;
  }

  .p-badge.p-badge-danger {
    color: #fff;
    background-color: #ef4444;
  }

  .p-badge.p-badge-lg {
    min-width: 2.25rem;
    height: 2.25rem;
    font-size: 1.125rem;
    line-height: 2.25rem;
  }

  .p-badge.p-badge-xl {
    min-width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
    line-height: 3rem;
  }

  .p-tag {
    color: #fff;
    background: #4f46e5;
    border-radius: .375rem;
    padding: .25rem .4rem;
    font-size: .75rem;
    font-weight: 700;
  }

  .p-tag.p-tag-success {
    color: #fff;
    background-color: #22c55e;
  }

  .p-tag.p-tag-info {
    color: #fff;
    background-color: #3b82f6;
  }

  .p-tag.p-tag-warning {
    color: #fff;
    background-color: #f59e0b;
  }

  .p-tag.p-tag-danger {
    color: #fff;
    background-color: #ef4444;
  }

  .p-inputtext, .p-togglebutton, .p-selectbutton, .p-inputgroup {
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 1px 2px rgba(0, 0, 0, .05);
  }

  .p-inputgroup .p-inputtext, .p-inputgroup .p-togglebutton, .p-inputgroup .p-selectbutton {
    box-shadow: none;
  }

  .p-inputtext.p-invalid.p-component:enabled:focus {
    box-shadow: 0 0 0 1px #f0a9a7;
  }

  .p-highlight {
    font-weight: 600;
  }

  .p-button-label {
    font-weight: 500;
  }

  .p-inputswitch.p-focus .p-inputswitch-slider {
    box-shadow: 0 0 0 2px #6366f1;
  }

  .p-paginator .p-paginator-pages .p-paginator-page {
    margin-left: -1px;
  }

  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    border-color: #4f46e5;
    margin-right: 1px;
  }

  .p-paginator .p-paginator-current {
    border: 0;
  }

  .p-button:focus {
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #6366f1, 0 1px 2px rgba(0, 0, 0, 0);
  }

  .p-button.p-button-secondary:enabled:focus {
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #64748b, 0 1px 2px rgba(0, 0, 0, 0);
  }

  .p-button.p-button-success:enabled:focus {
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #22c55e, 0 1px 2px rgba(0, 0, 0, 0);
  }

  .p-button.p-button-info:enabled:focus {
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #3b82f6, 0 1px 2px rgba(0, 0, 0, 0);
  }

  .p-button.p-button-warning:enabled:focus {
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #f59e0b, 0 1px 2px rgba(0, 0, 0, 0);
  }

  .p-button.p-button-help:enabled:focus {
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #a855f7, 0 1px 2px rgba(0, 0, 0, 0);
  }

  .p-button.p-button-danger:enabled:focus {
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #ef4444, 0 1px 2px rgba(0, 0, 0, 0);
  }

  .p-checkbox .p-checkbox-box {
    border-radius: .25rem;
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus, .p-radiobutton:not(.p-radiobutton-disabled) .p-radiobutton-box.p-focus {
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #6366f1, 0 1px 2px rgba(0, 0, 0, 0);
  }

  .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
    box-shadow: inset 0 2px #4f46e5;
  }

  .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
    box-shadow: inset 0 -2px #4f46e5;
  }
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, .7);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
  padding: 4px;
  position: fixed;
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    margin: 0;
    padding: 0;
    left: 0;
  }

  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}

.Toastify__toast {
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: default;
  direction: ltr;
  z-index: 0;
  border-radius: 4px;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 8px;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 10px rgba(0, 0, 0, .1), 0 2px 15px rgba(0, 0, 0, .05);
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast--close-on-click {
  cursor: pointer;
}

.Toastify__toast-body {
  -ms-flex: auto;
  flex: auto;
  -ms-flex-align: center;
  align-items: center;
  margin: auto 0;
  padding: 6px;
  display: -ms-flexbox;
  display: flex;
}

.Toastify__toast-body > div:last-child {
  word-break: break-word;
  -ms-flex: 1;
  flex: 1;
}

.Toastify__toast-icon {
  -webkit-margin-end: 10px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 20px;
  display: -ms-flexbox;
  display: flex;
}

.Toastify__toast-icon:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-right: 10px;
}

.Toastify__toast-icon:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: 10px;
}

.Toastify--animate {
  animation-duration: .7s;
  animation-fill-mode: both;
}

.Toastify--animate-icon {
  animation-duration: .3s;
  animation-fill-mode: both;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    border-radius: 0;
    margin-bottom: 0;
  }
}

.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}

.Toastify__toast-theme--light, .Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}

.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}

.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}

.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}

.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}

.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  cursor: pointer;
  opacity: .7;
  background: none;
  border: none;
  outline: none;
  -ms-flex-item-align: start;
  align-self: flex-start;
  padding: 0;
  transition: all .3s;
}

.Toastify__close-button--light {
  color: #000;
  opacity: .3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  width: 14px;
  height: 16px;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  z-index: var(--toastify-z-index);
  opacity: .7;
  transform-origin: 0;
  width: 100%;
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--animated {
  animation: linear forwards Toastify__trackProgress;
}

.Toastify__progress-bar--controlled {
  transition: transform .2s;
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: 100%;
}

.Toastify__spinner {
  box-sizing: border-box;
  border: 2px solid;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  border-radius: 100%;
  width: 20px;
  height: 20px;
  animation: .65s linear infinite Toastify__spin;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    visibility: visible;
    transform: translate3d(110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

:root {
  --scrollbar-width: 8px;
  --scrollbar-color: #ccc;
  --primary-color: #0046d5;
  --primary: #0046d5;
  --custom-font-family: "Nunito", "system-ui", sans-serif;
  --focus-ring: 0 0 0 1px #0046d5;
}

:focus {
  outline-offset: 0 !important;
  box-shadow: none !important;
  outline: none !important;
}

a:focus {
  box-shadow: none !important;
}

html, body, #root {
  height: 100%;
  font-family: var(--custom-font-family) !important;
}

::-webkit-scrollbar {
  width: var(--scrollbar-width);
  height: var(--scrollbar-width);
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color);
  border-radius: var(--scrollbar-width);
}

.remove-x-scroll::-webkit-scrollbar {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
  display: none !important;
}

.remove-x-scroll {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.p-dialog, .p-button, .p-component, .p-inputtext {
  font-family: var(--custom-font-family) !important;
}

.p-inputtext, .p-button {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.p-dialog-header-close {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
}

.p-dialog-footer button {
  padding: .375rem 1rem;
}

.p-datepicker-trigger {
  padding: 0 !important;
}

.p-chips-multiple-container {
  width: 100% !important;
  padding: .325rem .5rem !important;
}

.p-selectbutton > .p-highlight {
  border-style: inherit !important;
}

.p-selectbutton > .p-highlight > .p-button-label {
  font-weight: 600;
}

.p-column-filter-add-rule > button {
  --tw-text-opacity: 1 !important;
  color: rgba(0, 70, 213, var(--tw-text-opacity)) !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border-style: none !important;
}

.p-column-filter-buttonbar > button:first-child {
  --tw-text-opacity: 1 !important;
  color: rgba(0, 70, 213, var(--tw-text-opacity)) !important;
  background-color: rgba(0, 0, 0, 0) !important;
}

.p-column-filter-remove-button {
  background-color: rgba(0, 0, 0, 0) !important;
  border-color: rgba(0, 0, 0, 0) !important;
}

.p-highlight {
  border-style: none !important;
}

.p-button-icon-only:hover {
  --tw-scale-x: 1.1 !important;
  --tw-scale-y: 1.1 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.p-button.p-button-text:enabled:hover, .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover {
  background-color: rgba(0, 0, 0, 0) !important;
}

.p-datatable-header {
  border-radius: .75rem;
  border-style: none !important;
}

.p-column-header-content {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.p-column-header-content > .p-column-title {
  font-weight: 500;
}

.p-datatable-thead {
  border-style: none;
  border-radius: .75rem;
  overflow: hidden;
}

.p-datatable-tbody > tr:hover, .p-datatable-tbody > tr.p-highlight {
  border-radius: .5rem;
}

.p-datatable-row-expansion > td:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
}

.p-paginator {
  border-style: none !important;
}

.p-paginator-bottom {
  border-bottom-right-radius: .75rem;
  border-bottom-left-radius: .75rem;
  border-style: none !important;
}

.p-paginator-element {
  border-style: none !important;
  border-radius: 9999px !important;
}

.p-badge {
  border-radius: .375rem;
}

.stacked-image-wrap {
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 70px;
  height: 70px;
  margin: 0 auto;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.stacked-image-wrap > img {
  z-index: 1;
  position: absolute;
}

.stacked-image-wrap > img:first-of-type {
  z-index: 0;
  left: -40px;
  transform: rotate(-15deg)scale(.8);
}

.stacked-image-wrap > img:nth-of-type(3) {
  z-index: 0;
  right: -40px;
  transform: rotate(15deg)scale(.8);
}

.PhoneInput > input {
  border-style: none;
  padding: 0;
}

.p-fileupload-buttonbar .p-button, .p-fileupload-content button {
  padding: .5rem .75rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

:root {
  --PhoneInput-color--focus: #03b2cb;
  --PhoneInputInternationalIconPhone-opacity: .8;
  --PhoneInputInternationalIconGlobe-opacity: .65;
  --PhoneInputCountrySelect-marginRight: .35em;
  --PhoneInputCountrySelectArrow-width: .3em;
  --PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-opacity: .45;
  --PhoneInputCountrySelectArrow-color: currentColor;
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1em;
  --PhoneInputCountryFlag-borderWidth: 1px;
  --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, .5);
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, .1);
}

.PhoneInput {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.PhoneInputInput {
  -ms-flex: 1;
  flex: 1;
  min-width: 0;
}

.PhoneInputCountryIcon {
  width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
  height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
  width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
  background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor), inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
  width: 100%;
  height: 100%;
  display: block;
}

.PhoneInputInternationalIconPhone {
  opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
  opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

.PhoneInputCountry {
  margin-right: var(--PhoneInputCountrySelect-marginRight);
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.PhoneInputCountrySelect {
  z-index: 1;
  opacity: 0;
  cursor: pointer;
  border: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.PhoneInputCountrySelect[disabled], .PhoneInputCountrySelect[readonly] {
  cursor: default;
}

.PhoneInputCountrySelectArrow {
  content: "";
  width: var(--PhoneInputCountrySelectArrow-width);
  height: var(--PhoneInputCountrySelectArrow-width);
  margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
  border-style: solid;
  border-color: var(--PhoneInputCountrySelectArrow-color);
  border-top-width: 0;
  border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  border-left-width: 0;
  border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  transform: var(--PhoneInputCountrySelectArrow-transform);
  opacity: var(--PhoneInputCountrySelectArrow-opacity);
  display: block;
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus), inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

/*# sourceMappingURL=index.548ccb14.css.map */
